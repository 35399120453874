import * as React from 'react';
import { ISliderGalleryProps } from '../../../SliderGallery.types';
import SliderGallery from '../../SliderGallery';
import skinsItemStyle from '../../../../SliderGalleryItem/viewer/style/SliderGalleryScotchTapeSkinItem.scss';
import SliderGalleryDefaultButtons from '../../skinButtons/SliderGalleryDefaultButtons';
import skinsStyle from './style/SliderGalleryScotchTapeSkin.scss';

const SliderGalleryScotchTapeSkin: React.FC<
  Omit<ISliderGalleryProps, 'skin'>
> = props => {
  return (
    <SliderGallery
      {...props}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      SkinNavButton={SliderGalleryDefaultButtons}
    />
  );
};

export default SliderGalleryScotchTapeSkin;
