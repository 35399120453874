import { useState } from 'react';
import { throttle, isBrowser } from '@wix/editor-elements-common-utils';
import { useDidMount } from '../../../../providers/useDidMount';

const paramsForSpecificAndroidDevices = (userAgent: string) => {
  switch (true) {
    case /(GT-S5300B|GT-S5360|GT-S5367|GT-S5570I|GT-S6102B|LG-E400f|LG-E400g|LG-E405f|LG-L38C|LGL35G)/i.test(
      userAgent,
    ):
      return { width: 240, height: 320 };
    case /(Ls 670|GT-S5830|GT-S5839i|GT-S6500D|GT-S6802B|GT-S7500L|H866C|Huawei-U8665|LG-C800|LG-MS695|LG-VM696|LGL55C|M865|Prism|SCH-R720|SCH-R820|SCH-S720C|SPH-M820-BST|SPH-M930BST|U8667|X501_USA_Cricket|ZTE-Z990G)/i.test(
      userAgent,
    ):
      return { width: 320, height: 480 };
    case /(5860E|ADR6300|ADR6330VW|ADR8995|APA9292KT|C771|GT-I8160|GT-I9070|GT-I9100|HTC-A9192|myTouch4G|N860|PantechP9070|PC36100|pcdadr6350|SAMSUNG-SGH-I727|SAMSUNG-SGH-I777|SAMSUNG-SGH-I997|SC-03D|SCH-I405|SCH-I500|SCH-I510|SCH-R760|SGH-S959G|SGH-T679|SGH-T769|SGH-T959V|SGH-T989|SPH-D700)/i.test(
      userAgent,
    ):
      return { width: 480, height: 800 };
    case /(DROIDX|SonyEricssonSO-02C|SonyEricssonST25i)/i.test(userAgent):
      return { width: 480, height: 854 };
    case /(DROID3|MB855)/i.test(userAgent):
      return { width: 540, height: 960 };
    case /F-05D/i.test(userAgent):
      return { width: 720, height: 1280 };
    default:
      return null;
  }
};

const isPortrait = () => {
  if (!window.screen || window.self !== window.top) {
    const orientation = window.screen.orientation;
    if (orientation) {
      if (orientation.type) {
        return orientation.type.startsWith('portrait');
      }
      return orientation.angle === 0 || orientation.angle === 180;
    }
  }
  return window.innerHeight > window.innerWidth;
};

const getDeviceWidthByUserAgent = () => {
  if (!window.navigator || !window.navigator.userAgent) {
    return null;
  }

  const userAgent = window.navigator.userAgent.toLowerCase();
  const screenWidth = window.screen.width;
  if (/iemobile/i.test(userAgent)) {
    return screenWidth || window.document.documentElement.clientWidth;
  }

  const specificAndroidParams = paramsForSpecificAndroidDevices(userAgent);
  if (specificAndroidParams) {
    return specificAndroidParams.width;
  }

  const screenHeight = window.screen.height;
  if (isPortrait()) {
    return Math.min(screenWidth, screenHeight);
  }
  return Math.max(screenWidth, screenHeight);
};

const getInvertedZoomRatio = () => {
  const width = getDeviceWidthByUserAgent();
  if (width === null || width === 0 || window.innerWidth === 0) {
    return 1;
  }
  return 1 / (width / window.innerWidth);
};

export const useZoomRatio = () => {
  const [zoomRatio, setZoomRatio] = useState(1);

  function updateZoomRatio() {
    setZoomRatio(getInvertedZoomRatio());
  }

  useDidMount(() => {
    if (isBrowser()) {
      const throttledFunc = throttle(updateZoomRatio, 1000);
      throttledFunc();
      window.addEventListener('resize', throttledFunc);
      return () => {
        window.removeEventListener('resize', throttledFunc);
      };
    }
    return () => {};
  });

  return zoomRatio;
};
