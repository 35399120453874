import React, { useEffect } from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import semanticClassNames from '../HamburgerOverlay.semanticClassNames';
import { useHamburgerMenuContext } from '../../HamburgerMenuContext';
import type { IHamburgerOverlayProps } from '../HamburgerOverlay.props';
import { dataHooks } from './constants';
import { st, classes } from './HamburgerOverlay.st.css';

const HamburgerOverlay: React.FC<IHamburgerOverlayProps> = props => {
  const {
    id,
    customClassNames = [],
    children,
    hasResponsiveLayout,
    containerProps,
    containerRootClassName,
    meshProps,
    stylableClassName,
    tapOutsideToClose,
    showBackgroundOverlay,
    onOpenStateChange,
    ariaLabel,
  } = props;

  const { isMenuOpen, setIsMenuOpen } = useHamburgerMenuContext();
  useEffect(() => {
    onOpenStateChange(Boolean(isMenuOpen));
  }, [isMenuOpen, onOpenStateChange]);

  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <div
      id={id}
      className={st(
        containerRootClassName,
        { showBackgroundOverlay, isMenuOpen },
        classes.root,
      )}
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
      data-visible={isMenuOpen}
      onKeyDown={e => e.key === 'Escape' && setIsMenuOpen(false)}
      onClick={() => tapOutsideToClose && setIsMenuOpen(false)}
    >
      <div
        data-hook={dataHooks.dialog}
        aria-hidden
        className={st(
          classes.overlay,
          {},
          showBackgroundOverlay ? stylableClassName : undefined,
          showBackgroundOverlay
            ? customCssClasses(semanticClassNames.root, ...customClassNames)
            : undefined,
        )}
      />
      {hasResponsiveLayout ? (
        <ResponsiveContainer {...containerProps}>
          {childrenToRender}
        </ResponsiveContainer>
      ) : (
        <MeshContainer id={id} {...meshProps}>
          {childrenToRender}
        </MeshContainer>
      )}
    </div>
  );
};

export default HamburgerOverlay;
