import * as React from 'react';
import classNamesFn from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { FreemiumBannerMobileProps } from '../FreemiumBannerMobile.types';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import { AppsNames } from './constants';
import style from './style/FreemiumBannerMobile.scss';
import PreferredAppContent from './PreferredAppContent';
import GenericContent from './GenericContent';

const defaultDirection = 'ltr';

const FreemiumBannerMobile: React.FC<FreemiumBannerMobileProps> = props => {
  const {
    id = 'WIX_ADS',
    translations,
    useOverlay = false,
    href = '',
    className,
    classNames = [defaultDirection],
    onBannerLoad = () => {},
    onBannerClicked = () => {},
    shouldReportBi = false,
    getPreferredApp,
    shouldUsePreferredApp,
  } = props;

  const [preferredApp, setPreferredApp] = React.useState<string | undefined>(
    undefined,
  );

  const anchorClassNames = classNamesFn(
    ...classNames.map(name => style[name]),
    style.mobileTop,
    'has-custom-focus',
  );

  React.useEffect(() => {
    if (shouldUsePreferredApp) {
      getPreferredApp()
        .then(preferredAppRes =>
          setPreferredApp(
            preferredAppRes.ownerPreferredApp || AppsNames.Default,
          ),
        )
        .catch(() => setPreferredApp(AppsNames.Default));
    }
  }, [getPreferredApp, shouldUsePreferredApp]);

  React.useEffect(() => {
    if (shouldReportBi) {
      onBannerLoad();
    }
  }, [onBannerLoad, shouldReportBi]);

  return !shouldUsePreferredApp || preferredApp !== undefined ? (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNamesFn(className, style.mobile, style.freemiumBanner)}
    >
      {useOverlay ? (
        <div data-testid={TestIds.overlay} className={anchorClassNames} />
      ) : (
        <Link
          className={anchorClassNames}
          href={href}
          target="_blank"
          rel="nofollow"
          onClick={() => shouldReportBi && onBannerClicked()}
        >
          <span className={style.content}>
            {shouldUsePreferredApp ? (
              <PreferredAppContent
                translations={translations}
                preferredApp={preferredApp}
              />
            ) : (
              <GenericContent bannerText={translations.genericAppBannerText!} />
            )}
          </span>
        </Link>
      )}
    </div>
  ) : null;
};

export default FreemiumBannerMobile;
