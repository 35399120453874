import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  SlideShowContainerDefinition,
  SlideShowContainerCSSVars,
  SlideShowCarmiData,
  SlideShowContainerProperties,
  SlideShowContainerSdkData,
} from '@wix/thunderbolt-components-native';
import { ISlideShowContainerMapperProps } from '../SlideShowContainer.types';
import { getSlideShowContainerTranslations } from './utils';

const skinToArrowWidthToHeightRatio = {
  thinArrowsLargeSelectedCircleSkin: 1,
  thinArrowsSkin: 1,
  squareButtonsSkin: 0.5,
  longArrowsLargeSelectedCircleSkin: 0.3,
};

const skinToSelectedDotSizeRatio = {
  thinArrowsLargeSelectedCircleSkin: 1.66,
  thinArrowsSkin: 1,
  squareButtonsSkin: 1,
  longArrowsLargeSelectedCircleSkin: 1.66,
};

const isBoxSlideShowComponent = ({ type }: SlideShowContainerProperties) =>
  type === 'BoxSlideShowProperties';

export const props = withCompInfo<
  ISlideShowContainerMapperProps,
  SlideShowContainerDefinition,
  SlideShowCarmiData
>()(
  ['skin', 'compProps', 'translate'],
  ({ skin, compProps, translate }, carmiData) => {
    return {
      skin,
      hasShadowLayer: isBoxSlideShowComponent(compProps),
      currentSlideIndex: 0,
      autoPlay: compProps.autoPlay,
      showNavigationButton: compProps.showNavigationButton,
      showNavigationDots: compProps.showNavigationDots,
      autoPlayInterval: compProps.autoPlayInterval * 1000,
      pauseAutoPlayOnMouseOver: compProps.pauseAutoPlayOnMouseOver,
      transition: compProps.transition,
      transitionDuration: compProps.transDuration * 1000,
      transitionReverse: compProps.direction === 'LTR',
      slidesProps: carmiData.slidesProps,
      ...getSlideShowContainerTranslations(translate),
    };
  },
);

export const css = withCompInfo<
  SlideShowContainerCSSVars,
  SlideShowContainerDefinition
>()(['skin', 'compLayout', 'compProps'], ({ skin, compLayout, compProps }) => {
  const {
    shouldHideOverflowContent,
    navigationButtonSize,
    navigationButtonMargin,
    navigationDotsMargin,
    showNavigationButton,
    showNavigationDots,
    navigationDotsSize,
    navigationDotsGap,
    transDuration,
  } = compProps;
  const isBoxSlideShow = isBoxSlideShowComponent(compProps);

  const arrowWidthToHeightRatio = skinToArrowWidthToHeightRatio[skin];
  const arrowWidth = navigationButtonSize / arrowWidthToHeightRatio;
  const navBtnOffset = isBoxSlideShow
    ? navigationButtonMargin - 0.5 * arrowWidth
    : navigationButtonMargin;

  const navDotSizeSelected = Math.floor(
    navigationDotsSize * skinToSelectedDotSizeRatio[skin],
  );

  return {
    height: `${compLayout.height}px`,
    '--nav-dot-section-display': showNavigationDots ? 'block' : 'none',
    '--nav-dot-section-bottom-margin': `${navigationDotsMargin}px`,
    '--nav-dot-margin': `${navigationDotsGap / 2}px`,
    '--nav-dot-size': `${navigationDotsSize}px`,
    '--nav-dot-size-selected': `${navDotSizeSelected}px`,
    '--nav-button-width': `${arrowWidth}px`,
    '--nav-button-offset': `${navBtnOffset}px`,
    '--nav-button-display': showNavigationButton ? 'block' : 'none',
    '--slides-overflow': shouldHideOverflowContent ? 'hidden' : 'visible',
    '--transition-duration': `${transDuration * 1000}ms`,
  };
});

export const sdkData = withCompInfo<
  SlideShowContainerSdkData,
  SlideShowContainerDefinition
>()(['compProps'], ({ compProps }) => ({
  showSlideButtons: compProps.showNavigationDots,
  showNavigationButtons: compProps.showNavigationButton,
}));

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, css, sdkData, stateRefs });
