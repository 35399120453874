import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { MediaContainerPreviewProps } from '@wix/thunderbolt-components-native';
import {
  css,
  getFillLayersPreviewProps,
} from '../../../MediaContainers/MediaContainer/viewer/MediaContainer.previewMapper';
import type {
  ISectionProps,
  SectionDefinition,
  ISectionPreviewMapperProps,
} from '../Section.types';

export const props = withCompPreviewProps<
  PreviewWrapperProps<ISectionProps, ISectionPreviewMapperProps>,
  SectionDefinition,
  ISectionProps
>()(
  ['isPlayingAllowed', 'compDesign', 'componentViewMode'],
  (data, carmiData: ISectionProps) => {
    const { background } = carmiData;
    if (!background) {
      return carmiData;
    }
    const mediaContainerProps = getFillLayersPreviewProps(
      { fillLayers: background } as MediaContainerPreviewProps,
      data,
    );
    return {
      ...carmiData,
      background: { ...mediaContainerProps.fillLayers },
      ...mediaContainerProps,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
