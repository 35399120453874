import React from 'react';
import cn from 'clsx';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { ISlideshowProps, ISlideshowImperativeActions } from '../../types';
import { shouldForceAnimation as getShouldForceAnimation } from '../utils/animations';
import classes from './Slideshow.previewWrapper.scss';

export default (ViewerComponent: React.ComponentType<ISlideshowProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<ISlideshowImperativeActions, ISlideshowProps>(
      (
        {
          previewWrapperProps,
          ...viewerProps
        }: PreviewWrapperProps<
          ISlideshowProps,
          { compPreviewState: string | null }
        >,
        ref,
      ) => {
        const previewState = previewWrapperProps?.compPreviewState;
        const shouldForceAnimation = getShouldForceAnimation(previewState);
        const shouldGoToFirstItem =
          previewState === 'settingsPanelOpened' || shouldForceAnimation;

        return (
          <ViewerComponent
            {...viewerProps}
            {...(shouldForceAnimation && {
              className: cn(viewerProps.className, classes.forceAnimated),
            })}
            {...(shouldGoToFirstItem && { currentIndex: 0 })}
            ref={ref}
          />
        );
      },
    ),
  );
