import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISearchBoxMapperProps,
  ISearchBoxControllerProps,
  ISearchBoxProps,
  ISearchBoxStateRefs,
} from '../SearchBox.types';

export default withCompController<
  ISearchBoxMapperProps,
  ISearchBoxControllerProps,
  ISearchBoxProps,
  ISearchBoxStateRefs
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { compId, isMobileView, ...restProps } = mapperProps;
  const {
    isScrollingBlocked,
    setSiteScrollingBlocked,
    enableCyclicTabbing,
    disableCyclicTabbing,
    getMenuContainerApiByChildId,
    scopedClassName,
  } = stateValues;

  const returnProps: ISearchBoxControllerProps = {
    ...restProps,
    closeSuggestions() {
      controllerUtils.updateProps({
        isSuggestionsOpen: false,
        closeParentContainer: false,
        suggestionsLoading: false,
      });
    },
    clearSuggestions() {
      controllerUtils.updateProps({
        suggestions: null,
      });
    },
    changeValue(value) {
      controllerUtils.updateProps({ value });
    },
  };
  if (!isMobileView) {
    return returnProps;
  }

  const onModalSuggestionsOpen = () => {
    if (!isScrollingBlocked) {
      setSiteScrollingBlocked(true, compId);
    }
    enableCyclicTabbing(compId);
  };

  const onModalSuggestionsClose = ({ closeParentContainer = false } = {}) => {
    if (isScrollingBlocked) {
      setSiteScrollingBlocked(false, compId);
    }
    if (closeParentContainer) {
      const menuContainerApi = getMenuContainerApiByChildId?.(compId);
      menuContainerApi?.close(true);
    }
    disableCyclicTabbing(compId);
  };

  return {
    ...returnProps,
    onModalSuggestionsOpen,
    onModalSuggestionsClose,
    scopedClassName,
  };
});
