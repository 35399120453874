import * as React from 'react';
import classnames from 'clsx';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { ISocialAuthLogin } from '../MemberLoginDialog.types';
import { testIds } from './utils';
import { SocialAuthButtons } from './SocialAuthButtons';

export const SocialAuth: React.FC<ISocialAuthLogin> = ({
  id,
  emailSectionDivider,
  switchToAuthWithEmail = '',
  idps,
  iframeConfig,
  goToEmailAuthScreen,
  isEmailLoginEnabled,
  style,
  mode,
  translations,
  error,
  reportSocialAuthStarted,
  onBackendSocialLogin,
  onSocialError,
}) => {
  return (
    <>
      <div
        data-testid={testIds.socialAuth}
        className={style.socialLoginWrapper}
      >
        <SocialAuthButtons
          id={id}
          idps={idps}
          iframeConfig={iframeConfig}
          style={style}
          mode={mode}
          translations={translations}
          error={error}
          reportSocialAuthStarted={reportSocialAuthStarted}
          onBackendSocialLogin={onBackendSocialLogin}
          onSocialError={onSocialError}
        />
      </div>

      {isEmailLoginEnabled && (
        <>
          <div
            className={classnames(
              style.emailSectionDivider,
              style.sectionDivider,
            )}
          >
            <span className={style.emailSectionDividerText}>
              {emailSectionDivider}
            </span>
          </div>
          <div data-testid="switchToEmailLink" className={style.expandButton}>
            <BasicButton
              label={switchToAuthWithEmail}
              data-testid="switchToEmailLink"
              id={`switchToEmailLink_${id}`}
              isDisabled={false}
              hasPlatformClickHandler={true}
              link={undefined}
              onClick={() => goToEmailAuthScreen(false)}
            />
          </div>
        </>
      )}
    </>
  );
};
