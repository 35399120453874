import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getHeightPixelValue,
  getWidthPixelValue,
} from '@wix/editor-elements-common-utils';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterFollowDefinition,
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
  IWTwitterFollowCSSVars,
} from '../WTwitterFollow.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  translateConsentWrapperKeys,
  shouldShowConsentPolicyWrapper,
  isMobile,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys, COMPONENT_LABEL, TITLE_NAME } from './constants';

export const props = withCompInfo<
  WTwitterFollowMapperProps,
  WTwitterFollowDefinition
>()(
  ['compData', 'compProps', 'language', 'translate', 'uiType', 'deviceType'],
  ({ compData, compProps, language, translate, uiType, deviceType }) => {
    return {
      showCount: compProps.showCount.toString(),
      showScreenName: compProps.showScreenName.toString(),
      accountToFollow: compData.accountToFollow.replace('@', ''),
      lang: getLanguage(compProps.dataLang, language),
      translations: {
        title: translate(COMPONENT_LABEL, TITLE_NAME),
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
    };
  },
);

export const css = withCompInfo<
  IWTwitterFollowCSSVars,
  WTwitterFollowDefinition
>()(['compLayout', 'compSingleLayout'], ({ compLayout, compSingleLayout }) => {
  const widthCSS = getWidthPixelValue(compLayout, compSingleLayout);
  const heightCSS = getHeightPixelValue(compLayout, compSingleLayout);
  return {
    '--width': widthCSS || 0,
    '--height': heightCSS || 0,
    width: 'calc(var(--width) * 1px)',
    height: 'calc(var(--height) * 1px)',
    display: 'var(--display)',
    '--display': 'flex',
  };
});

export const stateRefs = withStateRefsValues<keyof WTwitterFollowStateRefs>([
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
