import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  GoogleMapDefinition,
  GoogleMapMapperProps,
  GoogleMapsPlatformProps,
  GoogleMapStateRefs,
} from '../GoogleMap.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

export const props = withCompInfo<
  GoogleMapMapperProps,
  GoogleMapDefinition,
  GoogleMapsPlatformProps
>()(['compId', 'translate', 'uiType', 'deviceType'], (deps, carmiData) => {
  const { compId, translate, uiType, deviceType } = deps;
  const { urlQueries, mapData, skin } = carmiData;

  return {
    id: compId,
    urlQueries,
    mapData: {
      /** Create new instance of mapData to rerender iFrame on data changes
       * (useGoogleIFrame depends on immutability)
       */
      ...mapData,
    },
    skin,
    translations: {
      title:
        translate('component_label', 'COMPONENT_LABEL_googleMapsTitle') ||
        'Google Map',
      ...translateConsentWrapperKeys(
        consetPolicyKeys.componentNameKey,
        consetPolicyKeys.componentNameDefault,
        translate,
      ),
    },
    isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
    isMobile: isMobile(deviceType),
  };
});

const stateRefs = withStateRefsValues<keyof GoogleMapStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
