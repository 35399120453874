import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinItPinWidgetDefinition,
  PinItPinWidgetMapperProps,
  PinItPinWidgetStateRefs,
} from '../PinItPinWidget.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

const props = withCompInfo<
  PinItPinWidgetMapperProps,
  PinItPinWidgetDefinition
>()(
  ['compData', 'uiType', 'deviceType', 'translate'],
  ({ compData, uiType, deviceType, translate }) => {
    return {
      pinUrl: compData.pinId,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof PinItPinWidgetStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
