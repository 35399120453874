import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getScaledFont,
  isSupportSpExperimentOn,
  LogicalAlignment,
  migrateFields,
  supportSpSpec,
  castFromInheritIfNeeded,
  PhysicalAlignment,
  convertPhysicalInputAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  addUnitToEveryField,
} from '@wix/editor-elements-common-utils';
import type { Spx } from '@wix/editor-elements-types/thunderbolt';
import type { ComboBoxInputMapperDefinition } from '../../ComboBoxInput/ComboBoxInput.types';
import type { IComboBoxInputListModalCSSVars } from '../ComboBoxInputListModal.types';
import { migratedFields } from '../constants';
import { getTextPadding } from '../../ComboBoxInput/utils';

const justifyContent = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
} as const;

export const css = withCompInfo<
  IComboBoxInputListModalCSSVars,
  ComboBoxInputMapperDefinition,
  {}
>()(
  [
    'compData',
    'compProps',
    'styleProperties',
    'compLayout',
    'hasResponsiveLayout',
    'isMobileView',
    'siteFonts',
    'formatCssValue',
    'experiments',
  ],
  ({
    compData,
    compProps,
    styleProperties,
    compLayout,
    hasResponsiveLayout,
    isMobileView,
    siteFonts,
    formatCssValue,
    experiments,
  }) => {
    const { direction, dropdownDirection = 'inherit' } = compData;
    const { spx, align, dropdownAlign, dropdownItemsSpacing, textPadding } =
      styleProperties as Record<string, string> & {
        spx?: Spx;
        align: LogicalAlignment;
        dropdownAlign: LogicalAlignment;
        dropdownItemsSpacing: number;
        textPadding: number;
      };
    const compInfo = { compLayout, styleProperties, siteFonts, isMobileView };

    const { inputMobileFontSize } = compProps;

    const isSupportSpOn = isSupportSpExperimentOn(experiments);

    const calculatedDropdownAlign = castFromInheritIfNeeded(
      dropdownAlign,
      align,
    );

    const dropdownOptionJustifyContent =
      justifyContent[calculatedDropdownAlign as keyof typeof justifyContent];

    const dropdownItemsSpacingValue = dropdownItemsSpacing ?? 16;

    const textPaddingValues = getTextPadding(
      calculatedDropdownAlign,
      textPadding,
    );

    const textPaddingVars = addUnitToEveryField(
      textPaddingValues,
      textPadding,
      {
        formatCssValueWithSpx: formatCssValue,
        spx,
      },
    );

    return {
      height: '100%',
      '--align': align,
      '--direction': direction,
      '--dropdownDirection': dropdownDirection,
      '--fnt': getScaledFont(compInfo, 'fnt', inputMobileFontSize),
      '--dropdownItemsSpacing': isSupportSpOn
        ? formatCssValue(dropdownItemsSpacingValue, spx)
        : `${dropdownItemsSpacingValue}px`,
      '--dropdownOptionJustifyContent': dropdownOptionJustifyContent,
      ...textPaddingVars,
      ...(!hasResponsiveLayout && { width: 'initial' }),
    };
  },
  [
    migrateFields([
      {
        experiment: supportSpSpec,
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: migratedFields,
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: textAlignment =>
          convertPhysicalInputAlignmentToLogical(
            textAlignment as PhysicalAlignment,
          ),
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'direction' }],
        enhancer: textAlignment => {
          return convertPhysicalInputAlignmentToDirection(
            textAlignment as PhysicalAlignment,
          );
        },
      },
    ]),
  ],
);

export default createComponentMapperModel({ css });
