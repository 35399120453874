import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Image from '../../Image/viewer/Image';
import { IGridProps, GridImageData } from '../Grid.types';
import {
  extractImageDataFromSrc,
  getElementHeight,
  getElementWidth,
} from '../utils';
import styles from './styles/Grid.scss';

type IImageCellProps = Pick<IGridProps, 'staticMediaUrl'> & {
  value: any;
  id: string;
  containerId: string;
  cellWidth?: number;
  cellHeight: number;
  widthUnit: string;
  columnLayout: string;
  autoHeight?: boolean;
};

const isExternalUrl = (url: string) =>
  /(^https?)|(^data)|(^blob)|(^\/\/)/.test(url);

const buildMediaImageSrc = (
  { uri }: GridImageData,
  staticMediaUrl: string,
  maxHeight: number,
  maxWidth: number,
) => `//${staticMediaUrl}/${uri}/v1/fit/w_${maxWidth},h_${maxHeight}/${uri}`;

const MIN_IMAGE_HEIGHT = 24;

interface ImageCellBaseProps
  extends Omit<IImageCellProps, 'cellWidth' | 'cellHeight'> {
  imageHeight: number;
  imageWidth?: number;
}

const ImageCellBase: React.FC<ImageCellBaseProps> = ({
  value,
  id,
  containerId,
  staticMediaUrl,
  imageHeight,
  // With equal column layout this value is computed based on Grid component
  // compLayout.width - which is marked as optional in Thunderbolt (for responsive).
  // We default to 999 in this case since we still have to send it as part of URL.
  imageWidth = 999,
}) => {
  const src = React.useMemo(() => {
    let imageData: GridImageData | undefined;

    if (typeof value === 'string') {
      const extractedImageData = extractImageDataFromSrc(value);
      if (extractedImageData) {
        imageData = extractedImageData;
      }
    } else if (typeof value === 'object') {
      imageData = value;
    }

    if (!imageData) {
      return undefined;
    }

    return isExternalUrl(imageData.uri)
      ? imageData.uri
      : buildMediaImageSrc(imageData, staticMediaUrl, imageHeight, imageWidth);
  }, [value, staticMediaUrl, imageHeight, imageWidth]);

  return src ? (
    <Image
      id={id}
      containerId={containerId}
      className={styles.image}
      uri={src}
      width={imageHeight}
      height={imageWidth}
      displayMode="original_size"
      alt=""
      name=""
    />
  ) : null;
};

const useCalculateImageCellDimensions = (
  {
    columnLayout,
    cellHeight,
    autoHeight,
    widthUnit,
    cellWidth,
  }: IImageCellProps,
  cellRef: React.RefObject<HTMLDivElement>,
) => {
  const [imageHeight, setImageHeight] = useState(cellHeight);
  const [imageWidth, setImageWidth] = useState<number | undefined>(cellWidth);

  useEffect(() => {
    const calculatedCellHeight =
      autoHeight && cellRef.current
        ? Math.max(MIN_IMAGE_HEIGHT, getElementHeight(cellRef.current) || 0)
        : cellHeight;

    setImageHeight(calculatedCellHeight);
  }, [cellRef, autoHeight, cellHeight]);

  useEffect(() => {
    if (cellRef.current) {
      setImageWidth(getElementWidth(cellRef.current) || cellWidth || undefined);
    }
  }, [cellRef, columnLayout, widthUnit, cellWidth]);

  return { imageHeight, imageWidth };
};

const ImageCell = (props: IImageCellProps) => {
  const cellRef = useRef<HTMLDivElement>(null);

  const { imageHeight, imageWidth } = useCalculateImageCellDimensions(
    props,
    cellRef,
  );

  const showImage = imageHeight > 0;

  return (
    <div style={{ width: '100%', height: '100%' }} ref={cellRef}>
      {showImage && (
        <ImageCellBase
          {...props}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
        />
      )}
    </div>
  );
};

export default ImageCell;
