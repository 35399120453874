import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  VectorImageDefinition,
  VectorImageProps,
} from '@wix/thunderbolt-components-native';
import { props as viewerPropsMapper } from './VectorImage.mapper';

const props = composeMapper<
  PreviewWrapperProps<VectorImageProps>,
  VectorImageDefinition,
  VectorImageProps
>()(
  viewerPropsMapper,
  {
    remove: [
      'fullNameCompType',
      'language',
      'mainPageId',
      'compId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);
export default createComponentPreviewMapperModel({ props });
