import * as React from 'react';
import {
  IPaginatedGalleryImperativeActions,
  IPaginatedGridGalleryProps,
} from '../../../PaginatedGridGallery.types';
import PaginatedGridGallery from '../../PaginatedGridGallery';
import skinsItemStyle from '../../../../PaginatedGridGalleryItem/viewer/style/PaginatedGridTextBottom.scss';
import skinsStyle from './style/PaginatedGridTextBottom.scss';

const PaginatedGridTextBottom: React.ForwardRefRenderFunction<
  IPaginatedGalleryImperativeActions,
  Omit<IPaginatedGridGalleryProps, 'skin'>
> = (props, ref) => {
  return (
    <PaginatedGridGallery
      {...props}
      ref={ref}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      hasPanel={true}
      buttonPrevContent="<"
      buttonNextContent=">"
    />
  );
};

export default React.forwardRef(PaginatedGridTextBottom);
