import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  IFreemiumBannerRefState,
} from '../FreemiumBannerMobile.types';
import {
  NAMESPACE,
  shouldUsePreferredAppExperiment,
  TranslationFallbacks,
  TranslationKeys,
} from './constants';

export const props = withCompInfo<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition
>()(
  ['direction', 'metaSiteId', 'experiments', 'translate'],
  ({ direction, metaSiteId, experiments, translate }) => {
    const shouldUsePreferredApp =
      experiments[shouldUsePreferredAppExperiment] === true ||
      experiments[shouldUsePreferredAppExperiment] === 'new';
    return {
      useOverlay: false,
      direction,
      href: `https://apps.wix.com/place-invites/join-lp/${metaSiteId}?ref=free_banner`,
      classNames: [direction],
      shouldReportBi: true,
      shouldUsePreferredApp,
      translations: {
        genericAppBannerText:
          translate(NAMESPACE, TranslationKeys.genericApp) ||
          TranslationFallbacks.genericApp,
        oneAppBannerText:
          translate(NAMESPACE, TranslationKeys.spacesApp) ||
          TranslationFallbacks.WixOneApp,
        fitnessAppBannerText:
          translate(NAMESPACE, TranslationKeys.fitnessApp) ||
          TranslationFallbacks.WixFitnessApp,
        restaurantsAppBannerText:
          translate(NAMESPACE, TranslationKeys.dineApp) ||
          TranslationFallbacks.WixRestaurantsApp,
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IFreemiumBannerRefState>([
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs });
