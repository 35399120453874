import * as React from 'react';
import { IShareButtonIconProps } from '../types';

const ShareButtonIcon: React.FC<IShareButtonIconProps> = props => {
  const { svg, className } = props;

  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: svg || '',
    },
    className,
    'aria-hidden': true,
  });
};

export default ShareButtonIcon;
