import { ExternalComponentSettingsPanelData } from './ExternalComponent.panels.types';

const ON_LOAD_COMPONENT_URL = '';

export const isEmptyFileParams = (
  compData: ExternalComponentSettingsPanelData,
) => compData.url === ON_LOAD_COMPONENT_URL || compData.importKey === '';

export const isReadyToBuild = () => {
  return false; // TODO change to real condition
};
