import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  GoogleMapDefinition,
  GoogleMapMapperProps,
  GoogleMapsPlatformProps,
} from '../GoogleMap.types';
import { props as viewerPropsMapper } from './GoogleMap.mapper';

const props = composeMapper<
  PreviewWrapperProps<GoogleMapMapperProps>,
  GoogleMapDefinition,
  GoogleMapsPlatformProps
>()(
  viewerPropsMapper,
  { add: ['shouldResetComponent', 'experiments', 'componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const isDesignerMode = deps.experiments?.se_addDesignerPage === true;
    const isEditorMode = deps.componentViewMode === 'editor';
    return {
      ...viewerProps,
      isDesignerMode,
      mapData: { ...viewerProps.mapData, isDesignerMode },
      isEditorMode,
      previewWrapperProps: {
        shouldResetComponent: deps.shouldResetComponent,
      },
    };
  },
);
export default createComponentPreviewMapperModel({
  props,
});
