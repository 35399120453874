import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { CompInfo } from '@wix/editor-elements-types/src/thunderboltExternal';
import {
  getScaledFont,
  getInputHeight,
  getLabelPadding,
} from '@wix/editor-elements-common-utils';
import {
  IFileUploaderCSSVars,
  IFileUploaderDefinition,
  IFileUploaderMapperProps,
} from '../FileUploader.types';
import {
  GeneralErrorTranslation,
  MediaServerErrorKeysTranslationMapping,
  TRANSLATION_FALLBACKS,
  TRANSLATION_KEYS,
  translationsNamespace,
  MIN_LABEL_FONT_SIZE,
} from '../constants';

const getTranslations = (
  translate: CompInfo<IFileUploaderDefinition>['translate'],
) => {
  const errors = Object.keys(MediaServerErrorKeysTranslationMapping).reduce<{
    [key: string]: string | undefined;
  }>((acc, key) => {
    const fullTranslationKey = `upload-button.${key}`;
    acc[fullTranslationKey] = translate(
      translationsNamespace,
      fullTranslationKey,
    );
    return acc;
  }, {});

  return {
    singleFileSelectedFile:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.SingleSelectedFile'],
      ) || TRANSLATION_FALLBACKS['FileUploader.SingleSelectedFile'],
    numberOfSelectedFiles:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.NumberOfSelectedFiles'],
      ) || TRANSLATION_FALLBACKS['FileUploader.NumberOfSelectedFiles'],
    exceededFilesLimit:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.ExceededFilesLimit'],
      ) ||
      TRANSLATION_FALLBACKS[
        'upload_button.validation_error.exceeded_files_limit'
      ],
    mobileClosePopper:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS.FileUploader_Mobile_ClosePopper,
      ) || TRANSLATION_FALLBACKS['mobile_popper.close'],
    previewFileName:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS.Upload_Button_Filename_Preview_Text_Filename,
      ) || TRANSLATION_FALLBACKS['FileUploader.NumberOfSelectedFiles'],
    multipleErrors:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.MultipleErrors'],
      ) ||
      TRANSLATION_FALLBACKS[
        'upload_button.validation_error.multiple_file_errors'
      ],
    singleError:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.SingleError'],
      ) ||
      TRANSLATION_FALLBACKS['upload_button.validation_error.single_file_error'],
    generalError:
      translate(translationsNamespace, GeneralErrorTranslation.key) ||
      GeneralErrorTranslation.fallback,
    ...errors,
  };
};

export const props = withCompInfo<
  IFileUploaderMapperProps,
  IFileUploaderDefinition,
  IFileUploaderMapperProps
>()(
  ['compId', 'isMobileView', 'translate'],
  ({ compId, isMobileView, translate }, carmiData) => {
    const translations = getTranslations(translate);
    return { ...carmiData, compId, isMobileView, translations };
  },
);

export const css = withCompInfo<
  IFileUploaderCSSVars,
  IFileUploaderDefinition
>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'styleProperties',
    'siteFonts',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  compInfo => {
    const {
      compData: { placeholderLabel, buttonLabel },
      compProps,
      compLayout,
      styleProperties: { txtlblrq },
      isMobileView,
      hasResponsiveLayout,
    } = compInfo;

    const {
      filesAlignment,
      buttonAlignment,
      showPlaceholder,
      showFileName = true,
      labelMargin,
      inputMobileFontSize,
      textBelowButtonMobileFontSize,
      labelMobileFontSize,
    } = compProps;

    const hidePlaceholder = !showPlaceholder || !placeholderLabel;
    const hideTextBelowButton = !showFileName && hidePlaceholder;
    const notTransparentText = txtlblrq && txtlblrq !== 'transparent';

    const buttonFont = getScaledFont(compInfo, 'btn_fnt', inputMobileFontSize);
    const fileFont = getScaledFont(
      compInfo,
      'file_fnt',
      textBelowButtonMobileFontSize,
    );
    const labelFont = getScaledFont(
      compInfo,
      'fntlbl',
      labelMobileFontSize,
      MIN_LABEL_FONT_SIZE,
    );

    const labelMarginBottom = `${labelMargin}px`;
    const labelPadding = getLabelPadding(compProps);

    const varsByFilesAlignment = {
      right: {
        '--xIconMargin': '0 0 0 8px',
        '--tooltipIconMargin': '0 8px 0 0',
        '--filesDirection': 'rtl',
        '--filesJustifyContent': 'flex-start',
      },

      center: {
        '--xIconMargin': '0 8px 0 0',
        '--tooltipIconMargin': '0 0 0 8px',
        '--filesDirection': 'ltr',
        '--filesJustifyContent': 'center',
      },

      left: {
        '--xIconMargin': '0 8px 0 0',
        '--tooltipIconMargin': '0 0 0 8px',
        '--filesDirection': 'ltr',
        '--filesJustifyContent': 'flex-start',
      },
    } as const;

    const buttonPaddingByAlignment = {
      ltr: '0 0 0 8px',
      rtl: '0 8px 0 0',
    };

    const notResponsiveLayoutVars = !hasResponsiveLayout && {
      '--inputHeight': getInputHeight({
        inputHeightProps: compProps,
        compLayout,
        isMobileView,
      }),
      height: 'auto',
    };

    return {
      '--chooseFileButtonDir': buttonAlignment,
      '--buttonPadding': buttonLabel
        ? buttonPaddingByAlignment[buttonAlignment]
        : '0 0 0 0',
      '--filesAlignment': filesAlignment,
      '--filesPlaceholderVisibility': hidePlaceholder ? 'hidden' : 'inherit',
      '--textBelowButtonDisplay': hideTextBelowButton ? 'none' : 'block',
      '--btn_fnt': buttonFont,
      '--file_fnt': fileFont,
      '--labelMarginBottom': labelMarginBottom,
      '--fntlbl': labelFont,
      '--requiredIndicationDisplay': notTransparentText ? 'inline' : 'none',
      '--labelPadding': labelPadding,
      ...varsByFilesAlignment[filesAlignment],
      ...notResponsiveLayoutVars,
    };
  },
);

const stateRefs = withStateRefsValues([
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'setSiteScrollingBlocked',
  'scopedClassName',
]);

export default createComponentMapperModel({ props, css, stateRefs });
