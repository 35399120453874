import * as React from 'react';
import classNames from 'clsx';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import siteButtonContentStyles from '../../SiteButtonContent.scss';
import skinsStyle from './styles/skins.scss';

const ViewerButtonBlueSkinSkin: React.FC<SkinButtonProps> = ({
  wrapperProps,
}) => {
  return (
    <div
      {...wrapperProps}
      className={classNames(
        skinsStyle.ViewerButtonBlueSkin,
        wrapperProps.className,
        siteButtonContentStyles['site-button-content'],
      )}
    >
      <div className={skinsStyle.caption} />
    </div>
  );
};

const ViewerButtonBlueSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ViewerButtonBlueSkinSkin} ref={ref} />
);

export default React.forwardRef(ViewerButtonBlueSkin);
