import * as React from 'react';
import classnames from 'clsx';

import { Loader } from 'wix-style-react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';

import SiteMembersInput, {
  ISiteMembersInputRef,
} from '../../SiteMembersInput/viewer/SiteMembersInput';
import {
  figureFallbackErrorMessage,
  serverErrorsHandler,
} from '../../SiteMembersInput/viewer/utils';

import { IVerificationCodeProps } from '../VerificationCodeDialog.types';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import style from './style/style.scss';

import {
  VerificationCodeDialogTranslationKeys as keys,
  testIds,
  codeInput,
} from './constants';

const VerificationCodeDialog: React.FC<IVerificationCodeProps> = props => {
  const {
    id,
    className,
    onCloseDialogCallback,
    isCloseable,
    displayMode,
    directionByLanguage,
    email,
    onSubmitCallback,
    onResendVerificationCodeEmail,
    error = '',
    translations,
  } = props;
  const headlineId = `VerificationCodeHeadline_${id}`;
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState(
    figureFallbackErrorMessage(error, translations),
  );

  const title = translations[keys.title];
  // TODO: get rid of replace once we GA the initial patch and changed the translations
  const emailParagraph = translations[keys.emailParagraph]
    // eslint-disable-next-line no-template-curly-in-string
    ?.replace('${email}', '');
  const submitButtonLabel = translations[keys.submitButton] ?? '';
  const footerParagraph = translations[keys.footerParagraph];
  const footerParagraphLink = translations[keys.footerParagraphLink];
  const codeRef = React.useRef<ISiteMembersInputRef>(null);
  const submitForm = async () => {
    setLoading(true);
    if (codeRef.current!.validate(translations)) {
      try {
        await onSubmitCallback(code);
      } catch (errorRespond: any | string | number) {
        setLoading(false);
        const errorMsg = serverErrorsHandler(errorRespond);
        const defaultErrorMsg = translations[keys.verificationErr];
        setCodeError(errorMsg);
        codeRef.current!.setError(translations[errorMsg] ?? defaultErrorMsg);
      }
    }
  };
  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      translations={translations}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      displayMode={displayMode}
      dialogPosition="start"
    >
      <div className={classnames(style.memberLoginContent)}>
        <h1 className={classnames(style.title)} data-testid={testIds.headline}>
          {title}
        </h1>

        <div className={style.paragraph}>
          <span>{emailParagraph}</span>
          <span>{email}</span>
        </div>
        <div>
          <div className={style.textFieldWrapper}>
            <SiteMembersInput
              maxLength={6}
              id={codeInput}
              inputType="text"
              data-testid={codeInput}
              value={code}
              label=""
              onValueChanged={setCode}
              ref={codeRef}
              isValid={true}
              autoFocus={true}
              directionByLanguage={directionByLanguage}
              errorMessage={codeError}
              borderInput
              className={style.inputOverrides}
            />
          </div>
          <div className={style.ButtonWrapper}>
            {loading ? (
              <Loader />
            ) : (
              <div data-testid="submit" className={style.actionButton}>
                <BasicButton
                  label={submitButtonLabel}
                  id={`okButton_${id}`}
                  isDisabled={code?.length < 6}
                  hasPlatformClickHandler={true}
                  link={undefined}
                  onClick={submitForm}
                  data-testid={testIds.submit}
                />
              </div>
            )}
          </div>
          <div className={style.paragraph}>
            <span>{footerParagraph}</span>
          </div>
          <div className={style.someInfoTxt}>
            <span
              className={style.resendButton}
              data-testid={testIds.resendButton}
              onClick={onResendVerificationCodeEmail}
              role="button"
              tabIndex={0}
            >
              {footerParagraphLink}
            </span>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default VerificationCodeDialog;
