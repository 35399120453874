import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  DisqusCommentsDefinition,
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
} from '../DisqusComments.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

export const props = withCompInfo<
  DisqusCommentsMapperProps,
  DisqusCommentsDefinition
>()(
  ['compData', 'uiType', 'deviceType', 'translate'],
  ({ compData, uiType, deviceType, translate }) => {
    return {
      disqusId: compData.disqusId,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof DisqusCommentsStateRefs>([
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
