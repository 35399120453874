import { getDataAttributes } from '@wix/editor-elements-common-utils';
import * as React from 'react';

import type { ITestCompProps } from '../TestComp.types';
import { TestIds, labelText } from '../constants';
import { st, classes } from './style/TestComp.component.st.css';

const TestComp: React.FC<ITestCompProps> = props => {
  const {
    id,
    className,
    stylableClassName,
    label = labelText.default,
    onFocus,
    onBlur,
  } = props;

  const inputId = `${id}-input`;

  return (
    // the viewer platform passes the id to the component
    // and uses it to inject css variables scoped for the component
    <div {...getDataAttributes(props)} id={id} className={className}>
      <div className={st(classes.root, stylableClassName)}>
        <label
          htmlFor={inputId}
          data-testid={TestIds.InputLabel}
          className={classes.text}
        >
          {label}
        </label>
        <input id={inputId} onFocus={onFocus} onBlur={onBlur} type="text" />
      </div>
    </div>
  );
};

export default TestComp;
