export const OPEN_BUTTON_DATA_SCHEMA = 'StylableButton';
export const OPEN_BUTTON_TYPE = 'wixui.HamburgerOpenButton';
export const OPEN_BUTTON_PROP_TYPES = 'HamburgerOpenButtonProperties';

export const CLOSE_BUTTON_DATA_SCHEMA = 'StylableButton';
export const CLOSE_BUTTON_TYPE = 'wixui.HamburgerCloseButton';
export const CLOSE_BUTTON_PROP_TYPES = 'HamburgerCloseButtonProperties';

export const OVERLAY_TYPE = 'wixui.HamburgerOverlay';
export const OVERLAY_DATA_SCHEMA = 'wixui.HamburgerOverlay';

export const MENU_CONTAINER_TYPE = 'wixui.HamburgerMenuContainer';
export const MENU_CONTAINER_DATA_SCHEMA = 'wixui.HamburgerMenuContainer';
export const HAMBURGER_MENU_ROOT_TYPE = 'wixui.HamburgerMenuRoot';

export const HAMBURGER_SANTA_VIEWER_NAMESPACE = 'ariaLabels';
