import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getHeightPixelValue,
  getWidthPixelValue,
} from '@wix/editor-elements-common-utils';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterTweetDefinition,
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
  WTwitterTweetCSSVars,
} from '../WTwitterTweet.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { COMPONENT_LABEL, TITLE_NAME, consetPolicyKeys } from './constants';

export const props = withCompInfo<
  WTwitterTweetMapperProps,
  WTwitterTweetDefinition
>()(
  [
    'compData',
    'compProps',
    'language',
    'isMobileView',
    'isMasterPage',
    'externalBaseUrl',
    'translate',
    'uiType',
    'deviceType',
  ],
  ({
    compData: { defaultText, accountToFollow, urlFormat, isHttpsEnabled },
    compProps,
    language,
    isMobileView,
    isMasterPage,
    externalBaseUrl,
    translate,
    uiType,
    deviceType,
  }) => {
    return {
      defaultText,
      accountToFollow,
      isMobileView,
      lang: getLanguage(compProps.dataLang, language),
      urlFormat,
      isMasterPage,
      externalBaseUrl,
      isHttpsEnabled,
      translations: {
        title: translate(COMPONENT_LABEL, TITLE_NAME),
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
    };
  },
);

export const css = withCompInfo<
  WTwitterTweetCSSVars,
  WTwitterTweetDefinition
>()(['compLayout', 'compSingleLayout'], ({ compLayout, compSingleLayout }) => {
  const widthCSS = getWidthPixelValue(compLayout, compSingleLayout);
  const heightCSS = getHeightPixelValue(compLayout, compSingleLayout);
  return {
    '--width': widthCSS || 0,
    '--height': heightCSS || 0,
    width: 'calc(var(--width) * 1px)',
    height: 'calc(var(--height) * 1px)',
    display: 'var(--display)',
    '--display': 'flex',
  };
});

export const stateRefs = withStateRefsValues<keyof WTwitterTweetStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
