import * as React from 'react';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IVideoPlayerHandles,
  IVideoPlayerProps,
} from '../../../VideoPlayer.types';
import { ConsentPolicyWrapper } from '../../../../ConsentPolicyWrapper/viewer/ConsentPolicyWrapper';
import VideoPlayer from '../../VideoPlayer';
import semanticClassNames from '../../../VideoPlayer.semanticClassNames';

import { st, classes } from '../../style/VideoPlayer.st.css';

const VideoPlayerWithConsentWrapper: React.ForwardRefRenderFunction<
  IVideoPlayerHandles,
  IVideoPlayerProps
> = (props: IVideoPlayerProps, forwardRef) => {
  const { customClassNames = [] } = props;

  return (
    <ConsentPolicyWrapper
      componentConsentPolicy={{ advertising: true }}
      rootClassName={st(
        classes.root,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      )}
      {...getDataAttributes(props)}
      {...props}
    >
      <VideoPlayer ref={forwardRef} {...props}></VideoPlayer>
    </ConsentPolicyWrapper>
  );
};

export default React.forwardRef(VideoPlayerWithConsentWrapper);
