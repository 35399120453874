import * as React from 'react';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { TestIds } from '../constants';
import { getSDK, twitchSDK } from '../../../../providers/ScriptLoader';
import useProgress, { IProgress } from './useProgress';
import {
  IPlayer,
  ITwitchPlayer,
  IPlayerProps,
  IPlayerHandles,
} from './players.types';

const VIDEO_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const CHANNEL_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
const getIds = (src: string) => {
  const isChannel = CHANNEL_URL_REGEX.test(src);
  return {
    channelId: isChannel ? src.match(CHANNEL_URL_REGEX)![1] : '',
    videoId: isChannel ? '' : src.match(VIDEO_URL_REGEX)![1],
  };
};

const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  sdkRef: React.MutableRefObject<any>,
  config: any,
): [() => Promise<ITwitchPlayer>, () => ITwitchPlayer | void] => {
  const [waitForPlayer, getPlayer, setPlayer] = useAsyncRef<ITwitchPlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK(twitchSDK);
    waitForSDK.then((Twitch: any) => {
      sdkRef.current = Twitch;
      setPlayer(new Twitch.Player(container.current, config) as ITwitchPlayer);
    });
  });

  return [waitForPlayer, getPlayer];
};

function subscribeToEvents(
  player: ITwitchPlayer,
  TwitchPlayer: any,
  {
    onReady,
    onPlay,
    onPause,
    onEnded,
    onDuration,
    onProgress,
    onFirstPlay,
    onFirstEnded,
  }: Partial<IPlayerProps>,
  setDuration: (duration: number) => void,
  waitForDuration: () => Promise<number>,
  progress: IProgress,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
) {
  const { READY, PLAY, PAUSE, ENDED } = TwitchPlayer;

  progress.subscribe(() => {
    onProgress?.(player.getCurrentTime() || 0);
  });

  if (onDuration) {
    const interval = setInterval(() => {
      const duration = player.getDuration();
      if (duration) {
        setDuration(duration);
        clearInterval(interval);
      }
    }, 1000);
  }

  player.addEventListener(READY, () => {
    if (onDuration) {
      waitForDuration().then((duration: number) => onDuration(duration));
    }
    onReady?.();
  });

  player.addEventListener(PLAY, () => {
    if (!firstPlayStarted.current) {
      firstPlayStarted.current = true;
      onFirstPlay?.();
    }

    isPlayingNow.current = true;
    onPlay?.();

    progress.update();
  });

  player.addEventListener(PAUSE, () => {
    isPlayingNow.current = false;
    onPause?.();

    progress.stop();
  });

  player.addEventListener(ENDED, () => {
    if (!firstPlayEnded.current) {
      firstPlayEnded.current = true;
      onFirstEnded?.();
    }

    isPlayingNow.current = false;
    onEnded?.();

    progress.stop();
  });
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<ITwitchPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => player.setMuted(muted!)),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<ITwitchPlayer>,
  getPlayer: () => ITwitchPlayer | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    getDuration: () => {
      const player = getPlayer();
      return player ? player.getDuration() || 0 : 0;
    },
    getCurrentTime: () => {
      const player = getPlayer();
      return player ? player.getCurrentTime() || 0 : 0;
    },
    seekTo: time => waitForPlayer().then(player => player.seek(time)),
    getVolume: () => {
      const player = getPlayer();
      return player ? player.getVolume() * 100 : 0;
    },
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: () => {
      const player = getPlayer();
      return player ? player.getMuted() : true;
    },
    isPlaying: () => isPlayingNow.current,
    mute: () => waitForPlayer().then(player => player.setMuted(true)),
    unMute: () => waitForPlayer().then(player => player.setMuted(false)),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    src,
    playing,
    muted,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    twitchParentDomains,
  } = props;

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const sdkRef = React.useRef<any>(null);

  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const [waitForDuration, setDuration] = useAsyncRef<number>();
  const progress = useProgress();

  const { channelId, videoId } = getIds(src as string);

  const [waitForPlayer, getPlayer] = usePlayer(containerRef, sdkRef, {
    video: videoId,
    channel: channelId,
    height: '100%',
    width: '100%',
    playsinline: true,
    autoplay: playing,
    muted,
    parent: twitchParentDomains,
  });

  useDidMount(() => {
    waitForPlayer().then(player => {
      subscribeToEvents(
        player,
        sdkRef.current.Player,
        {
          onReady,
          onPlay,
          onPause,
          onEnded,
          onDuration,
          onProgress,
          onFirstPlay,
          onFirstEnded,
        },
        setDuration,
        waitForDuration,
        progress,
        isPlayingNow,
        firstPlayEnded,
        firstPlayEnded,
      );

      onInit?.(player, 'twitch');
    });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  return (
    <div
      ref={containerRef}
      className={classes.playerContainer}
      data-player-name="Twitch"
      data-testid={TestIds.twitch}
    />
  );
};

export default React.forwardRef(Player);
