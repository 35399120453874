import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getScaledFont,
  isSupportSpExperimentOn,
  addUnitToEveryField,
  migrateFields,
  castFromInheritIfNeeded,
  LogicalAlignment,
  convertPhysicalInputAlignmentToLogical,
  PhysicalAlignment,
  convertPhysicalInputAlignmentToDirection,
} from '@wix/editor-elements-common-utils';
import {
  getInputHeight,
  getRequiredIndicationDisplay,
  getLabelPaddingsValues,
  getTextPaddingsValues,
} from '@wix/editor-elements-common-utils/src/commons/inputUtils';
import {
  TextInputDefinition,
  ITextInputMapperProps,
  TextInputCSSVars,
  TextInputType,
} from '../TextInput.types';
import { textTypeToName, migratedSPFields, TranslationKeys } from './constants';

const getName = (
  textType: TextInputType,
  alt: string | undefined,
): string | undefined => {
  if (textTypeToName[textType]) {
    return textTypeToName[textType];
  } else if (alt) {
    return alt.toLowerCase().replace(' ', '-');
  }
  return undefined;
};

export const props = withCompInfo<ITextInputMapperProps, TextInputDefinition>()(
  ['compData', 'compProps', 'skin', 'translate'],
  ({ compData, compProps, skin, translate }) => {
    const translations = {
      phoneFormatLengthValidationError: translate(
        TranslationKeys.NAMESPACE,
        TranslationKeys.PHONE_FORMAT_LENGTH_VALIDATION_ERROR,
      ),
      phoneFormatDefaultValidationError: translate(
        TranslationKeys.NAMESPACE,
        TranslationKeys.PHONE_FORMAT_DEFAULT_VALIDATION_ERROR,
      ),
      phoneFormatComplexPhoneDefaultValidationError: translate(
        TranslationKeys.NAMESPACE,
        TranslationKeys.PHONE_FORMAT_COMPLEX_PHONE_DEFAULT_VALIDATION_ERROR,
      ),
      errorMessage: translate(
        TranslationKeys.NAMESPACE,
        TranslationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
      ),
    };

    return {
      skin:
        skin === 'ResponsiveTextInputSkin'
          ? 'ResponsiveTextInputSkin'
          : 'AppsTextInputSkin',
      value: compData.value || '',
      inputType: compData.textType,
      label: compData.label,
      name: getName(
        compData.textType,
        compData.label || compData.placeholder || compProps.placeholder,
      ),
      placeholder: compData.placeholder || compProps.placeholder,
      readOnly: compProps.readOnly || false,
      required: compProps.required,
      autoComplete: compData.textType !== 'password' && compProps.autoComplete,
      autoComplete_:
        compData.textType !== 'password' && compProps.autoComplete
          ? 'on'
          : null,
      isDisabled: compProps.isDisabled || false,
      pattern: compData.pattern,
      maxLength: compData.maxLength,
      ...(compData.textType === 'number' && {
        min: compData.min,
        max: compData.max,
        step: compData.step,
      }),
      prefix: compData.prefix,
      phoneFormat: compData.phoneFormat,
      errorMessageType: compProps.errorMessageType,
      translations,
    };
  },
);

export const css = withCompInfo<TextInputCSSVars, TextInputDefinition>()(
  [
    'compLayout',
    'compSingleLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
  ],
  ({
    compLayout,
    compSingleLayout,
    compProps,
    styleProperties,
    isMobileView,
    hasResponsiveLayout,
    compData,
    siteFonts,
    formatCssValue: formatCssValueWithSpx,
    siteFontsSpxResolved,
    experiments,
  }) => {
    const isSupportSpOn = isSupportSpExperimentOn(experiments);
    const { inputHeightMobile } = compProps;
    const {
      prefix,
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      errorDirection = 'inherit',
    } = compData;

    const {
      textPadding,
      labelMargin,
      labelPadding,
      inputHeight,
      align,
      labelAlign,
      inputAlign,
    } = styleProperties as Record<string, string> & {
      align: LogicalAlignment;
      labelAlign: LogicalAlignment;
      inputAlign: LogicalAlignment;
    };
    const { spx } = styleProperties;
    const spxProps = { formatCssValueWithSpx, spx };

    const compInfo = {
      styleProperties,
      compLayout,
      compProps,
      isMobileView,
      siteFonts,
      siteFontsSpxResolved: isSupportSpOn ? siteFontsSpxResolved : undefined,
    };

    const calculatedLabelAlign = castFromInheritIfNeeded(labelAlign, align);
    const calculatedInputAlign = castFromInheritIfNeeded(inputAlign, align);

    const textPaddingValues = getTextPaddingsValues({
      textPadding,
      hasPrefix: !!prefix,
      align: calculatedInputAlign,
    });
    const labelPaddingValues = getLabelPaddingsValues({
      labelPadding,
      align: calculatedLabelAlign,
    });

    const sharedProps = {
      '--fnt': getScaledFont(
        compInfo,
        'fnt',
        compProps.inputMobileFontSize,
        13,
      ),
      '--fntlbl': getScaledFont(
        compInfo,
        'fntlbl',
        compProps.labelMobileFontSize,
        16,
      ),
      '--direction': direction,
      '--align': align,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--errorDirection': errorDirection,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      '--labelMarginBottom': isSupportSpOn
        ? formatCssValueWithSpx(labelMargin, spx)
        : `${labelMargin}px`,
      ...addUnitToEveryField(textPaddingValues, textPadding, spxProps),
      ...addUnitToEveryField(labelPaddingValues, labelPadding, spxProps),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeight(
          {
            inputHeightProps: { inputHeightMobile, inputHeight },
            compLayout,
            compSingleLayout,
            isMobileView,
          },
          isSupportSpOn ? formatCssValueWithSpx : undefined,
          spx,
        ),
      };
    }
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [...migratedSPFields, 'textAlignment'],
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: textAlignment =>
          convertPhysicalInputAlignmentToLogical(
            textAlignment as PhysicalAlignment,
          ),
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'direction' }],
        enhancer: (textAlignment, { compData }) => {
          if (compData.phoneFormat) {
            return 'inherit';
          }
          return convertPhysicalInputAlignmentToDirection(
            textAlignment as PhysicalAlignment,
          );
        },
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
