import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { FooterSdkStyles } from '@wix/thunderbolt-symbols';
import { isFixedPosition } from '@wix/editor-elements-common-utils';
import {
  FooterContainerCarmiProps,
  FooterContainerDefinition,
  FooterContainerCSSVars,
} from '../FooterContainer.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
  sdkData as viewerSdkMapper,
} from './FooterContainer.mapper';

const props = composeMapper<
  PreviewWrapperProps<FooterContainerCarmiProps>,
  FooterContainerDefinition,
  FooterContainerCarmiProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'componentViewMode',
      'compLayout',
      'compSingleLayout',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      compPreviewState,
      componentViewMode,
      compLayout,
      compSingleLayout,
    } = deps;

    const fixedPosition = isFixedPosition(compLayout, compSingleLayout);

    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
        componentViewMode,
        fixedPosition,
      },
    };
  },
);

const css = composeMapper<
  FooterContainerCSSVars,
  FooterContainerDefinition,
  FooterContainerCSSVars
>()(
  viewerCssMapper,
  {
    add: ['renderFixedPositionContainers'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const { renderFixedPositionContainers } = dependencies;
    const styles: Partial<FooterContainerCSSVars> = {};
    if (!renderFixedPositionContainers) {
      styles.position = undefined;
    }
    return { ...viewerCss, ...styles };
  },
);

const sdkData = composeMapper<
  FooterSdkStyles,
  FooterContainerDefinition,
  FooterSdkStyles
>()(viewerSdkMapper, {}, (resolver, dependencies, carmiData) => {
  return resolver(dependencies, carmiData);
});

export default createComponentPreviewMapperModel({
  props,
  css,
  sdkData,
});
