import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FiveGridLineProps,
  FiveGridLineDefinition,
  FiveGridLineCSSVars,
} from '../FiveGridLine.types';

/**
 * This mapper makes sure horizontalLine will be affected by the css editing mappers
 */
export const props = withCompInfo<
  FiveGridLineProps,
  FiveGridLineDefinition,
  FiveGridLineProps
>()([], (_, carmiData) => {
  return {
    ...carmiData,
  };
});

export const css = withCompInfo<
  FiveGridLineCSSVars,
  FiveGridLineDefinition,
  FiveGridLineCSSVars
>()(['editorName'], ({ editorName }, carmiCss) => {
  return {
    ...carmiCss,
    ...(editorName === 'Studio'
      ? { height: '0 !important', 'box-sizing': 'content-box' }
      : {}),
  };
});

export default createComponentMapperModel({ props, css });
