import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getHeightPixelValue } from '@wix/editor-elements-common-utils';
import {
  AddressInputMapperProps,
  AddressInputDefinition,
} from '../AddressInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './AddressInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<AddressInputMapperProps>,
  AddressInputDefinition
>()(
  viewerPropsMapper,
  {
    add: [
      'compProps',
      'compLayout',
      'compSingleLayout',
      'compPreviewState',
      'shouldResetComponent',
      'componentViewMode',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const {
      compProps,
      compLayout,
      compSingleLayout,
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
    } = dependencies;

    const heightPixelValue = getHeightPixelValue(compLayout, compSingleLayout);

    return {
      ...viewerProps,
      labelPadding: compProps.labelPadding,
      labelMargin: compProps.labelMargin,
      height: heightPixelValue,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: viewerCssMapper,
});
