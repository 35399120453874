import {
  AnyCompDef,
  CompInfo,
  StyleProperties,
  UnitLength,
} from '@wix/editor-elements-types/thunderbolt';
import { Spx } from '@wix/thunderbolt-becky-types';
import { pick } from './utils';

export const stylableWithSpExperiment =
  'specs.responsive-editor.isStylableWithScaleSupport';

// TODO: add cqw to thunderbolt types
export type CSSUnit = UnitLength['type'] | 'cqw' | 'spx';

export const isUsingStyleProperties = (
  compStyle: Record<string, string | number>,
  keys: Array<string>,
) => {
  return keys.every(key => Boolean(compStyle[key]));
};

type GetStyleObjProps<T extends AnyCompDef> = {
  compProps: T['property'];
  styleProperties: StyleProperties;
  migratedFields: Array<string>;
};

export const getMigratedStyleProps = <T extends AnyCompDef>({
  compProps,
  styleProperties,
  migratedFields,
}: GetStyleObjProps<T>) => {
  return {
    ...pick(compProps, migratedFields),
    ...pick(styleProperties, migratedFields),
  };
};

export type ValueWithUnit = { value: number; unit: CSSUnit };

const isNumber = (value: unknown): value is number => {
  return !Number.isNaN(value);
};

type ParseValueConfig<FallbackValue> = {
  fallbackValue: FallbackValue;
  shouldRoundValue?: boolean;
};

export const parseValueWithUnit = <
  FallbackValue extends ValueWithUnit | undefined = undefined,
>(
  valueWithUnit: string,
  config: ParseValueConfig<FallbackValue> = {
    fallbackValue: undefined as FallbackValue,
  },
): ValueWithUnit | FallbackValue => {
  const { fallbackValue, shouldRoundValue } = config;

  const numericValue = parseFloat(valueWithUnit);

  const unit = extractUnit(valueWithUnit);

  if (!isNumber(numericValue)) {
    return fallbackValue;
  }

  return {
    value: shouldRoundValue ? Math.round(numericValue) : numericValue,
    unit: unit as CSSUnit,
  };
};

const extractUnit = (valueWithUnit: string) => {
  const DECIMALS_REGEX = /\d+(\.\d+)?/;
  const FALLBACK_UNIT = 'px';
  return valueWithUnit.replace(DECIMALS_REGEX, '') || FALLBACK_UNIT;
};

export const ZERO_PIXELS: ValueWithUnit = {
  value: 0,
  unit: 'px',
};

export const supportSpSpec = 'specs.thunderbolt.supportSpxInEEMappers';

export const isSupportSpExperimentOn = (
  experiments: Record<string, string | boolean>,
) =>
  experiments[supportSpSpec] === 'new' || experiments[supportSpSpec] === true;

type Value = number | string;
type SpxAddUnitProps = {
  formatCssValueWithSpx: CompInfo['formatCssValue'];
  spx: Spx | undefined;
};

export const addUnitToEveryField = <T extends Record<string, number>>(
  values: T,
  originalValue?: Value,
  spxProps?: SpxAddUnitProps,
): { [Property in keyof T]: string } => {
  const shouldAddSpx = spxProps && originalValue?.toString().endsWith('spx');
  const addUnit = shouldAddSpx
    ? (value: Value) =>
        spxProps.formatCssValueWithSpx(`${value}spx`, spxProps.spx)
    : (value: Value) => `${value}px`;

  return Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: addUnit(value),
    }),
    {} as { [Property in keyof T]: string },
  );
};
