import * as React from 'react';
import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IAccordionItemClassicProps } from '../../../types';
import { classes } from './Classic.skin.st.css';

const AccordionItemClassic: React.FC<IAccordionItemClassicProps> = props => {
  const { id, children, meshProps, className } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <div id={id} className={className} {...getDataAttributes(props)}>
      <MeshContainer
        {...meshProps}
        id={id}
        extraClassName={classes['forced-width']}
      >
        {childrenToRender}
      </MeshContainer>
    </div>
  );
};

export default AccordionItemClassic;
