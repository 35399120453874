import {
  AbsoluteLayout,
  LayoutSize,
  SingleLayoutData,
  UnitSize,
} from '@wix/thunderbolt-becky-types';

const unitSizeTypes = [
  'px',
  'percentage',
  'fr',
  'vh',
  'vw',
  'rem',
  'em',
  'spx',
  'cqw',
  'cqh',
];

const isUnitSize = (size?: LayoutSize): size is UnitSize => {
  return Boolean(size && unitSizeTypes.includes(size.type));
};

const isHorizontallyDocked = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  const itemLayout = singleLayoutData?.itemLayout;
  if (!itemLayout) {
    return !!(compLayout.docked?.right && compLayout.docked?.left);
  }
  if (itemLayout.type === 'MeshItemLayout') {
    return !!(itemLayout.left && itemLayout.right);
  }
  if (itemLayout.type === 'FixedItemLayout') {
    return !!(itemLayout.margins?.left && itemLayout.margins.right);
  }
  return false;
};

const isFixedPosition = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    (singleLayoutData?.itemLayout &&
      singleLayoutData?.itemLayout?.type === 'FixedItemLayout') ??
    compLayout.fixedPosition
  );
};

const getContainerHeightCSS = (singleLayoutData?: SingleLayoutData) => {
  if (
    singleLayoutData?.componentLayout?.height?.type === 'auto' &&
    singleLayoutData?.componentLayout?.minHeight?.cssValue
  ) {
    return singleLayoutData.componentLayout.minHeight.cssValue;
  }
  return;
};

const getHeightCSS = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    getContainerHeightCSS(singleLayoutData) ??
    singleLayoutData?.componentLayout?.height?.cssValue ??
    `${compLayout.height}px`
  );
};

const getHeightPixelValue = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  if (
    singleLayoutData?.componentLayout?.height?.type === 'auto' &&
    (singleLayoutData?.componentLayout?.minHeight as UnitSize)?.value !==
      undefined
  ) {
    return (singleLayoutData?.componentLayout?.minHeight as UnitSize)?.value;
  }
  return (
    (singleLayoutData?.componentLayout?.height as UnitSize)?.value ??
    compLayout.height
  );
};

const getWidthCSS = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    singleLayoutData?.componentLayout?.width?.cssValue ??
    `${compLayout.width}px`
  );
};

const getWidthPixelValue = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    (singleLayoutData?.componentLayout?.width as UnitSize)?.value ??
    compLayout.width
  );
};

const getTopCSS = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    singleLayoutData?.itemLayout?.margins?.top?.cssValue ?? `${compLayout.y}px`
  );
};

const getLeftCSS = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  if (
    singleLayoutData?.itemLayout?.type === 'MeshItemLayout' &&
    singleLayoutData.itemLayout.left?.cssValue !== undefined
  ) {
    return singleLayoutData.itemLayout.left.cssValue;
  }
  if (
    singleLayoutData?.itemLayout?.type === 'FixedItemLayout' &&
    isUnitSize(singleLayoutData.itemLayout.margins?.left)
  ) {
    return singleLayoutData.itemLayout.margins!.left.cssValue;
  }
  if (isHorizontallyDocked(compLayout, singleLayoutData)) {
    return `${compLayout.docked!.left!.px}px`;
  }
  return `${compLayout.x}px`;
};

const getRotationInDegrees = (
  compLayout: Partial<AbsoluteLayout>,
  singleLayoutData?: SingleLayoutData,
) => {
  return (
    singleLayoutData?.componentLayout?.rotationInDegrees ??
    compLayout.rotationInDegrees
  );
};

export {
  isHorizontallyDocked,
  isFixedPosition,
  getHeightCSS,
  getWidthCSS,
  getTopCSS,
  getWidthPixelValue,
  getHeightPixelValue,
  getRotationInDegrees,
  getLeftCSS,
};
