import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { VerticalMenuDefinition } from '@wix/thunderbolt-components';
import { VerticalMenuProps } from '../VerticalMenu.types';
import { props as viewerMapperProps } from './VerticalMenu.mapper';

const props = composeMapper<
  PreviewWrapperProps<VerticalMenuProps>,
  VerticalMenuDefinition,
  VerticalMenuProps
>()(
  viewerMapperProps,
  {
    add: ['compPreviewState'],
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
