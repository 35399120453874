import React, { KeyboardEvent } from 'react';
import classNames from 'clsx';
import { keyCodes, getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISiteMembersDialogLayoutProps } from '../SiteMembersDialogLayout.types';
import Close from './assets/close.svg';
import style from './style/style.scss';

const SiteMembersDialogLayout: React.FC<
  ISiteMembersDialogLayoutProps
> = props => {
  const {
    className,
    isCloseable,
    children,
    translations,
    onCloseDialogCallback,
    headlineId,
    displayMode = 'fullscreen',
    dialogPosition = 'center',
  } = props;

  const closeOnEscapeClick = (event: KeyboardEvent): void => {
    if (event.keyCode === keyCodes.escape) {
      onCloseDialogCallback();
    }
  };
  return (
    <div
      {...getDataAttributes(props)}
      className={classNames(className, style.dialog)}
      onKeyDown={closeOnEscapeClick}
      data-testid="siteMembersDialogLayout"
      data-layout={displayMode}
    >
      <div
        className={style.blockingLayer}
        onClick={onCloseDialogCallback}
        data-testid="siteMembersDialogBlockingLayer"
      />
      <div
        role="dialog"
        tabIndex={-1}
        aria-labelledby={headlineId}
        className={style.content}
        data-dialogposition={dialogPosition}
      >
        {isCloseable && (
          <button
            className={style.xButton}
            onClick={onCloseDialogCallback}
            data-testid="xButton"
            aria-label={translations.membersCloseDialog}
            type="button"
          >
            <Close />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default SiteMembersDialogLayout;
