import React, { useEffect, useImperativeHandle } from 'react';
import {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../StylableButton/StylableButton.types';
import StylableButton from '../../../StylableButton/viewer/StylableButton';
import { useHamburgerMenuContext } from '../../HamburgerMenuContext';
import {
  st,
  classes as stylableButtonClasses,
} from '../../../StylableButton/viewer/StylableButton.component.st.css';
/**
 * Importing semantic classes from HamburgerMenuRoot component because
 * that is the component that gets selected in the editor and
 * it is used to show available CSS classes in Studio editor
 */
import semanticClassNames from '../../HamburgerMenuRoot/HamburgerMenuRoot.semanticClassNames';

const HamburgerOpenButton: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps & { ariaLabel?: string }
> = ({ id, a11y = {}, className, ariaLabel, ...props }, ref) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { isMenuOpen, setIsMenuOpen } = useHamburgerMenuContext();

  useImperativeHandle(ref, () => ({
    focus: () => buttonRef.current?.focus(),
    blur: () => buttonRef.current?.blur(),
  }));

  useEffect(() => {
    if (isMenuOpen === false) {
      buttonRef.current?.focus();
    }
  }, [isMenuOpen]);

  return (
    <nav aria-label={ariaLabel}>
      <StylableButton
        {...props}
        className={st(stylableButtonClasses.root, className)}
        a11y={{ ...a11y, pressed: `${Boolean(isMenuOpen)}`, haspopup: 'true' }}
        id={id}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        ref={buttonRef}
        semanticClassNames={semanticClassNames}
      />
    </nav>
  );
};

export default React.forwardRef(HamburgerOpenButton);
