import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import React from 'react';
import {
  ScrollableContainerTypes,
  withScrollAPIForResponsiveContainer,
} from '@wix/editor-elements-preview-utils';

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withScrollAPIForResponsiveContainer(ViewerComponent),
  );
