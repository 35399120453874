import * as React from 'react';
import { IFocusPropsImperativeActions } from '@wix/editor-elements-corvid-utils';
import SiteButton from '../../SiteButton';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import ButtonForkedSkin from './ButtonForkedSkin';
import skinsStyle from './styles/ButtonForkedRight.scss';

const ButtonForkedRightSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>((props, ref) => (
  <ButtonForkedSkin
    {...props}
    skinsStyle={skinsStyle}
    ref={ref}
  ></ButtonForkedSkin>
));

const ButtonForkedRight: React.ForwardRefRenderFunction<
  IFocusPropsImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ButtonForkedRightSkin} ref={ref} />
);

export default React.forwardRef(ButtonForkedRight);
