import { parseFontStr } from '@wix/editor-elements-common-utils/src/commons/fontParser';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import { IRichContentViewerProps } from '../RichContentViewer.types';

// We take default font family from corresponding tag site font family.
// It is exposed only as 'font' shorthand currently and family is not
// accessible via css vars, so we expose it manually
const mapFontFamiliesToVars = (
  siteFonts: Record<string, string>,
): Record<string, string> => {
  return Object.keys(siteFonts).reduce<Record<string, string>>(
    (acc, fontKey) => {
      const fontObj = parseFontStr(siteFonts[fontKey]);
      acc[`--${fontKey}-family`] = fontObj.family.join(',');
      acc[`--${fontKey}-size`] = String(fontObj.size);
      acc[`--${fontKey}-style`] = String(fontObj.style);
      acc[`--${fontKey}-weight`] = String(fontObj.weight);
      return acc;
    },
    {},
  );
};

export const css = withCompInfo<any, any>()(['siteFonts'], ({ siteFonts }) => {
  return {
    ...mapFontFamiliesToVars(siteFonts),
  };
});

export const props = withCompInfo<IRichContentViewerProps, any>()(
  ['compData', 'metaSiteId', 'isMobileView', 'compLayout'],
  ({ compData, metaSiteId, isMobileView, compLayout }) => {
    return {
      ...compData,
      metaSiteId,
      isMobileView,
      containerWidth: compLayout?.width,
    };
  },
);

export default createComponentMapperModel({ props, css });
