import { getScaledFont } from '@wix/editor-elements-common-utils';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { Translations } from '../constants';
import {
  LanguageSelectorDefinition,
  ILanguageSelectorMapperProps,
  IconType,
  LanguageSelectorCSSVars,
} from '../LanguageSelector.types';

export const props = withCompInfo<
  ILanguageSelectorMapperProps,
  LanguageSelectorDefinition
>()(
  ['compProps', 'isMobileView', 'styleProperties', 'translate'],
  ({
    compProps: {
      iconSize,
      alignTabs,
      displayMode: displayModeProp,
      hasArrow = false,
      itemFormat,
      iconType: iconTypeProp,
      languagesOrder,
    },
    isMobileView,
    styleProperties: { iconType: iconTypeStyle },
    translate,
  }) => {
    const isLegacyMobileComponent = !iconSize && isMobileView;
    const alignItems = !isLegacyMobileComponent ? alignTabs : 'center';

    let displayMode = displayModeProp;
    if (displayModeProp === 'dropdown' && isMobileView) {
      displayMode = 'nativeDropdown';
    }

    const showArrow = hasArrow || !isLegacyMobileComponent;
    const showHandleText = !(
      isLegacyMobileComponent ||
      (displayMode === 'nativeDropdown' && itemFormat === 'iconOnly')
    );

    let iconType = iconTypeProp;
    if (!iconSize) {
      iconType =
        isMobileView && iconTypeStyle === 'none'
          ? 'circle'
          : (iconTypeStyle as IconType);
    }

    return {
      displayMode,
      alignItems,
      extraOptions: {
        showArrow,
        showHandleText,
      },
      iconType,
      itemFormat,
      displayModeProp,
      isMobile: isMobileView,
      languagesOrder: languagesOrder ?? [],
      translations: {
        defaultAriaLabel:
          translate(
            Translations.ariaLabelNamespace,
            Translations.ariaLabelKey,
          ) || Translations.ariaLabelDefault,
      },
    };
  },
);

export const css = withCompInfo<
  LanguageSelectorCSSVars,
  LanguageSelectorDefinition
>()(
  [
    'compProps',
    'compLayout',
    'styleProperties',
    'siteFonts',
    'isMobileView',
    'compId',
  ],
  ({ compProps, compLayout, styleProperties, siteFonts, isMobileView }) => {
    const compInfo = { compLayout, styleProperties, siteFonts, isMobileView };
    return {
      '--itemFont': getScaledFont(compInfo, 'itemFont'),
      '--height': `${compLayout.height}px`,
      '--iconSize': `${compProps.iconSize || styleProperties.iconSize}px`,
    };
  },
);

export const stateRefs = withStateRefsValues([
  'originalLanguageCode',
  'currentLanguage',
  'siteLanguages',
  'flagsUrl',
  'setCurrentLanguage',
]);

export default createComponentMapperModel({ props, css, stateRefs });
