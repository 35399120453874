import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ISingleTabDefinition } from '../documentManagement/SingleTab.definition';
import type { ISingleTabMapperProps } from '../SingleTab.types';

export const props = withCompInfo<
  ISingleTabMapperProps,
  ISingleTabDefinition,
  ISingleTabMapperProps
>()(
  ['compData', 'compMeshProps', 'hasResponsiveLayout'],
  ({ compData, compMeshProps, hasResponsiveLayout }, carmiData) => {
    return {
      ...carmiData,
      ...compData,
      meshProps: compMeshProps,
      hasResponsiveLayout,
    };
  },
);

const css = withCompInfo()(
  ['hasResponsiveLayout'],
  ({ hasResponsiveLayout }) => ({
    width: '100%',
    // Prevents Tabs display variable from affecting elements inside SingleTab
    '--display': 'initial',
    ...(hasResponsiveLayout ? {} : { height: 'auto' }),
  }),
);

export default createComponentMapperModel({
  props,
  css,
});
