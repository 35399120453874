import React, { CSSProperties } from 'react';
import { TextProps } from '../../TextMask.types';

export const TextElement = (
  props: TextProps & { extraStyle?: CSSProperties },
) => {
  const {
    text,
    extraStyle,
    align,
    direction,
    lineHeight,
    letterSpacing,
    size,
    family,
    weight,
    style,
    decoration,
    transform,
  } = props;

  let x: string;
  switch (align) {
    case 'start':
    default:
      x = direction === 'rtl' ? '100%' : '0';
      break;

    case 'middle':
      x = '50%';
      break;

    case 'end':
      x = direction === 'rtl' ? '0' : '100%';
      break;
  }
  return (
    <>
      {text.split('\n').map((line, index) => (
        <text
          key={`text-line-${index}`}
          x={x}
          y={`${index * lineHeight!}em`}
          style={{
            letterSpacing: `${letterSpacing?.value}${letterSpacing?.type}`,
            direction,
            fill: '#000',
            fontSize: `${size}px`,
            fontFamily: family,
            fontWeight: weight,
            fontStyle: style,
            textAnchor: align,
            textDecoration: decoration,
            textTransform: transform,
            ...extraStyle,
          }}
        >
          {line.trim()}
        </text>
      ))}
    </>
  );
};
