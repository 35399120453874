
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "MusicPlayer2802357603",
      {"classes":{"root":"MusicPlayer2802357603__root","mainLayoutWrapper":"MusicPlayer2802357603__mainLayoutWrapper","title":"MusicPlayer2802357603__title","cover":"MusicPlayer2802357603__cover","progressLayout":"MusicPlayer2802357603__progressLayout","progressRangeBar":"MusicPlayer2802357603__progressRangeBar","playButton":"MusicPlayer2802357603__playButton","titleLayout":"MusicPlayer2802357603__titleLayout","timeStamp":"MusicPlayer2802357603__timeStamp","playLayout":"MusicPlayer2802357603__playLayout","mainLayout":"MusicPlayer2802357603__mainLayout","upperLayout":"MusicPlayer2802357603__upperLayout","lowerLayout":"MusicPlayer2802357603__lowerLayout","coverLayout":"MusicPlayer2802357603__coverLayout","playPauseBtn":"MusicPlayer2802357603__playPauseBtn"},"vars":{"display":"--display"},"stVars":{"backgroundColor":"rgba(var(--backgroundColor, var(--color_11)), var(--alpha-backgroundColor, 1))","backgroundColorIcon":"rgba(var(--backgroundColorIcon, var(--color_18)), var(--alpha-backgroundColorIcon, 1))","font":"var(--font, var(--font_8))","regularColor":"rgba(var(--regularColor, var(--color_15)), var(--alpha-regularColor, 1))","regularColorIcon":"rgba(var(--regularColorIcon, var(--color_16)), var(--alpha-regularColorIcon, 1))","colorOnHover":"rgba(var(--colorOnHover, var(--color_19)), var(--alpha-colorOnHover, 1))","borderColor":"rgba(var(--borderColor, var(--color_15)), var(--alpha-borderColor, 1))","borderSize":"var(--borderSize, 0)","borderRadius":"var(--borderRadius, 0)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  