import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getHeightPixelValue,
  getWidthPixelValue,
} from '@wix/editor-elements-common-utils';
import {
  SliderCSSVars,
  SliderDefinition,
  ISliderMapperProps,
} from '../Slider.types';
import {
  SliderTickMarksShape,
  SliderTickMarksPosition,
  SliderThumbShape,
} from '../documentManagement/Slider.schemaTypes';
import { Sizes } from '../constants';

export const props = withCompInfo<ISliderMapperProps, SliderDefinition>()(
  ['compData', 'compProps', 'compLayout', 'compSingleLayout', 'isMobileView'],
  ({ compData, compProps, compLayout, compSingleLayout, isMobileView }) => {
    const { min, max, value, step, stepType, readOnly } = compData;
    const {
      isDisabled,
      thumbShape,
      thumbSize,
      tickMarksShape,
      tickMarksPosition,
      tooltipPosition,
      tooltipVisibility,
      tooltipPrefix,
      tooltipSuffix,
      dir,
      label,
      labelMargin,
    } = compProps;

    const widthValue = getWidthPixelValue(compLayout, compSingleLayout);
    const heightValue = getHeightPixelValue(compLayout, compSingleLayout);

    // TODO: support responsive layout
    const width = widthValue || 0;
    const height = heightValue || 0;

    return {
      width,
      height,
      min,
      max,
      value,
      step,
      stepType,
      isDisabled,
      readOnly,
      thumbShape,
      thumbSize,
      tickMarksShape,
      tickMarksPosition,
      tooltipPosition: isMobileView ? 'normal' : tooltipPosition,
      tooltipVisibility,
      tooltipPrefix,
      tooltipSuffix,
      dir,
      label,
      labelMargin,
    };
  },
);

const getTicksCssByShape = (tickMarksShape: SliderTickMarksShape) => {
  if (tickMarksShape === 'line') {
    return {
      '--tickWidth': `${Sizes.tickLineWidth}px`,
      '--tickHeight': `${Sizes.tickLineHeight}px`,
    };
  }
  return {
    '--tickWidth': `${Sizes.tickDotSize}px`,
    '--tickHeight': `${Sizes.tickDotSize}px`,
    '--tickBorderRadius': '50%',
  };
};

const getTicksCssByPosition = (tickMarksPosition: SliderTickMarksPosition) => {
  return {
    normal: { '--tickBottom': '0' },
    middle: { '--tickTop': '50%', '--tickTranslateY': '-50%' },
    across: { '--tickTop': '0' },
  }[tickMarksPosition];
};

const getThumbBorderRadius = (
  styleProperties: Record<string, string>,
  thumbShape: SliderThumbShape,
) => {
  if (thumbShape === 'circle') {
    return '50%';
  }
  const defaultBorderRadiusByShape = {
    circle: '50%',
    square: '17px',
    rectangle: '37px',
    bar: '5px',
  };
  return (
    styleProperties.thumbBorderRadius || defaultBorderRadiusByShape[thumbShape]
  );
};

export const css = withCompInfo<SliderCSSVars, SliderDefinition>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }) => {
    const {
      trackSize,
      thumbShape,
      thumbSize,
      tickMarksPosition,
      tickMarksShape,
      dir,
      labelMargin,
      labelPadding,
    } = compProps;

    const aligningCSSProps = {
      '--textAlign': 'left',
      '--labelPadding': `0 0 0 ${labelPadding}px`,
    };

    if (dir === 'rtl') {
      aligningCSSProps['--textAlign'] = 'right';
      aligningCSSProps['--labelPadding'] = `0 ${labelPadding}px 0 0`;
    }

    return {
      ...(thumbSize && { height: 'auto' }),
      ...aligningCSSProps,
      '--labelMarginBottom': `${labelMargin}px`,
      '--trackHeight': `${trackSize}%`,
      '--thumbBorderRadius': getThumbBorderRadius(styleProperties, thumbShape),
      ...getTicksCssByPosition(tickMarksPosition),
      ...(tickMarksShape !== 'none' && getTicksCssByShape(tickMarksShape)),
    };
  },
);

export default createComponentMapperModel({ css, props });
