import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import { ButtonPosition, ContentAlignment } from '../constants';
import { ISelectableContainerInputDefinition } from '../types';
import { InputContent } from '../../SelectableContainer/constants';

export const props = withCompInfo<any, any>()(
  ['compStylableClass'],
  ({ compStylableClass }) => {
    return {
      stylableClassName: compStylableClass,
    };
  },
);

const buttonPositionMap: Record<
  ButtonPosition,
  React.CSSProperties['flexDirection']
> = {
  left: 'row',
  right: 'row',
  top: 'column',
  bottom: 'column',
};

const contentAlignmentMap: Record<
  ContentAlignment,
  React.CSSProperties['alignItems']
> = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const getItemsOrder = (buttonPosition: ButtonPosition) => {
  const isLeftOrTop =
    buttonPosition === ButtonPosition.Left ||
    buttonPosition === ButtonPosition.Top;

  return {
    '--input-order': isLeftOrTop ? '0' : '1',
    '--label-order': isLeftOrTop ? '1' : '0',
  };
};

const getItemsDisplay = (inputContent: InputContent) => ({
  '--custom-input-display':
    inputContent === InputContent.Text ? 'none' : 'block',
  '--label-display': inputContent === InputContent.Button ? 'none' : 'block',
});

const getAlignmentAndJustify = (
  buttonPosition: ButtonPosition,
  contentAlignment: ContentAlignment,
) => {
  const isLeftOrRight =
    buttonPosition === ButtonPosition.Left ||
    buttonPosition === ButtonPosition.Right;

  const contentAlignmentStyle = contentAlignmentMap[contentAlignment];

  return {
    '--content-alignment': isLeftOrRight ? 'center' : contentAlignmentStyle,
    '--content-justify': isLeftOrRight ? contentAlignmentStyle : 'center',
  };
};

const css = withCompInfo<any, ISelectableContainerInputDefinition>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      contentAlignment = ContentAlignment.Left,
      contentGap = 25,
      buttonPosition = ButtonPosition.Left,
      inputContent = InputContent.TextAndButton,
    } = styleProperties as {
      contentAlignment?: ContentAlignment;
      contentGap?: number;
      buttonPosition?: ButtonPosition;
      inputContent?: InputContent;
    };

    return {
      '--content-gap': Number(contentGap) + 'px',
      width: inputContent === InputContent.Button ? 'auto' : undefined,
      height: 'auto',
      '--button-position': buttonPositionMap[buttonPosition],
      ...getItemsOrder(buttonPosition),
      ...getItemsDisplay(inputContent),
      ...getAlignmentAndJustify(buttonPosition, contentAlignment),
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, css, stateRefs });
