import * as React from 'react';
import classnames from 'clsx';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import { MenuButtonProps } from '../../../MenuButton.types';
import skinsStyle from './styles/skins.scss';

const PointerMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classnames(props.className, skinsStyle.PointerMenuButtonNSkin)}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton ? ariaHasPopup : 'false',
            ariaDescribedBy,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <MenuButtonBackground
            wrapperProps={{ dir, textAlign, id }}
            classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
          >
            {label}
          </MenuButtonBackground>
          <div className={skinsStyle.labelPad}>
            <div className={classnames(skinsStyle.outer, skinsStyle.arr)} />
            <div className={classnames(skinsStyle.inner, skinsStyle.arr)} />
          </div>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default PointerMenuButtonNSkin;
