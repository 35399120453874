import classNames from 'clsx';
import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import { MenuButtonBackgroundLabel } from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const skin = 'SeparatedArrowsMenuButtonSkin';

const SeparatedArrowsMenuButtonSkin: React.FC<MenuButtonProps> = props => {
  const { id, dir, textAlign, className } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(className, skinsStyle[skin])}
    >
      {label => (
        <>
          <div className={skinsStyle.head}>
            <div className={skinsStyle.arrowHead} />
          </div>
          <div className={skinsStyle.tail}>
            <div className={skinsStyle.arrowTail} />
          </div>
          <MenuButtonBackgroundLabel
            dir={dir}
            textAlign={textAlign}
            className={skinsStyle.label}
            tagName="div"
            id={id}
          >
            {label}
          </MenuButtonBackgroundLabel>
        </>
      )}
    </MenuButtonRoot>
  );
};

export default SeparatedArrowsMenuButtonSkin;
