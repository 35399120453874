
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SelectionTag1023856791",
      {"classes":{"root":"SelectionTag1023856791__root","sr-only":"sronly925865317__sr-only","focus-ring-active":"focus2492309068__focus-ring-active","tag":"SelectionTag1023856791__tag","tagInput":"SelectionTag1023856791__tagInput"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  