import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ISlideshowDefinition,
  ISlideshowMapperProps,
  ISlideshowCarmiProps,
} from '../../types';
import { Transition, TransitionDirection } from '../../constants';
import { getAnimations, getSlideVariables } from '../utils/animations';

export const props = withCompInfo<
  ISlideshowMapperProps,
  ISlideshowDefinition,
  ISlideshowCarmiProps
>()(['compProps'], ({ compProps }, carmiData) => ({
  ...carmiData,
  ...compProps,
  currentIndex: 0,
  navigatingForward: true,
}));

export const css = withCompInfo()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      showNavButtons,
      showDisabledNavButtons,
      transitionEffect,
      transitionDuration,
      transitionDirection,
    } = styleProperties as Record<string, string | boolean>;

    return {
      '--navBtnVisibility': showNavButtons ? 'visible' : 'hidden',
      ...(showDisabledNavButtons === false && {
        '--disabledNavBtnVisibility': 'hidden',
      }),
      '--transitionDuration':
        transitionEffect === Transition.None ? 0 : transitionDuration,
      ...getAnimations(transitionEffect as Transition),
      ...(transitionEffect === Transition.Slide &&
        getSlideVariables(transitionDirection as TransitionDirection)),
    };
  },
);

export default createComponentMapperModel({ props, css });
