import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';

const props = withCompInfo<any, any, any>()(
  ['compData'],
  ({ compData }, carmiData) => {
    return {
      ...{
        logoUrl: '',
        backgroundColor: '',
        brandId: '',
        hasBackground: true,
        preset: 'original',
        link: { $ref: 'document_data.json#/LinkReference' },
        alt: 'Logo',
        logoId: '',
        isLoadingData: true,
      },
      ...compData,
      ...carmiData,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
