import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinterestPinItMapperProps,
  PinterestPinItDefinition,
  PinterestPinItStateRefs,
  PinterestPinItCSSVars,
} from '../PinterestPinIt.types';
import { BUTTON_SIZES, consetPolicyKeys } from '../constants';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const props = withCompInfo<
  PinterestPinItMapperProps,
  PinterestPinItDefinition
>()(
  [
    'compData',
    'compProps',
    'topology',
    'externalBaseUrl',
    'uiType',
    'deviceType',
    'translate',
  ],
  ({
    compData: { uri, description, urlFormat, isHttpsEnabled },
    compProps,
    topology,
    externalBaseUrl,
    uiType,
    deviceType,
    translate,
  }) => {
    return {
      uri,
      description,
      size: compProps.size,
      color: compProps.color,
      counterPosition: compProps.counterPosition,
      staticMediaUrl: topology.staticMediaUrl,
      urlFormat,
      externalBaseUrl,
      isHttpsEnabled,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
    };
  },
);

export const css = withCompInfo<
  PinterestPinItCSSVars,
  PinterestPinItDefinition
>()(['compProps'], ({ compProps }) => {
  const { width, height } =
    BUTTON_SIZES[compProps.counterPosition][compProps.size];

  return {
    width: `${width}px`,
    height: `${height}px`,
  };
});

export const stateRefs = withStateRefsValues<keyof PinterestPinItStateRefs>([
  'pageId',
  'relativeUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
