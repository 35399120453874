import React, { useContext, useRef, useState } from 'react';
import classnames from 'clsx';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import { useClickOutside } from '../../../../../../providers/useClickOutside/useClickOutside';
import styles from '../button.scss';
import StylesContext from '../../../StylesContext';
import semanticClassNames from '../../../../RichTextBox.semanticClassNames';
import AlignButton from './AlignButton';
import {
  AlignButtonType,
  alignmentButtons,
  alignTypes,
  getActiveAlignment,
  preventBubblingUp,
  getStateWithNewTextAlign,
} from './common';

export default (props: ToolbarChildrenProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const activeAlignRef = useRef<HTMLButtonElement>(null);
  const leftAlignRef = useRef<SVGSVGElement>(null);
  const centerAlignRef = useRef<SVGSVGElement>(null);
  const rightAlignRef = useRef<SVGSVGElement>(null);
  const justifyAlignRef = useRef<SVGSVGElement>(null);

  const hideOptions = () => {
    setShowOptions(false);
  };

  const stylesContext = useContext(StylesContext);

  useClickOutside(
    [
      activeAlignRef,
      leftAlignRef,
      centerAlignRef,
      rightAlignRef,
      justifyAlignRef,
    ],
    hideOptions,
  );

  const { setEditorState, getEditorState } = props;

  const alignRefs = {
    left: leftAlignRef,
    right: rightAlignRef,
    center: centerAlignRef,
    justify: justifyAlignRef,
  };

  const changeTextAlign = (
    e: React.MouseEvent<HTMLOrSVGElement>,
    newAlignment: AlignButtonType,
  ) => {
    preventBubblingUp(e);

    const newState = getStateWithNewTextAlign(newAlignment, getEditorState());
    hideOptions();
    setEditorState(newState);
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    preventBubblingUp(event);
    setShowOptions(true);
  };

  const activeAlignment = getActiveAlignment(getEditorState());
  return (
    <div className={styles.buttonContainer} onMouseDown={preventBubblingUp}>
      <button
        ref={activeAlignRef}
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onClick={onClickHandler}
        type="button"
      >
        <div
          className={classnames(
            styles.buttonContent,
            customCssClasses(semanticClassNames.icons),
          )}
        >
          <AlignButton>{alignmentButtons[activeAlignment]}</AlignButton>
        </div>
        {showOptions && (
          <div
            className={classnames(
              styles.alignMenuContainer,
              {
                [styles.hovered]: stylesContext.hovered,
                [styles.focused]: stylesContext.focused,
                [styles.error]: stylesContext.error,
              },
              styles.inline,
            )}
          >
            {alignTypes.map(alignType => {
              return (
                <AlignButton
                  key={alignType}
                  ref={alignRefs[alignType]}
                  onClick={e => changeTextAlign(e, alignType)}
                  selected={activeAlignment === alignType}
                >
                  {alignmentButtons[alignType]}
                </AlignButton>
              );
            })}
          </div>
        )}
      </button>
    </div>
  );
};
