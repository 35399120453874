import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  migrateFields,
  getLabelPaddingsValues,
  convertPhysicalInputAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  addUnitToEveryField,
} from '@wix/editor-elements-common-utils';
import {
  ToggleSwitchDefinition,
  IToggleSwitchMapperProps,
  ToggleSwitchRootCSS,
  LogicalAlignment,
  ToggleSwitchStyleProperties,
} from '../ToggleSwitch.types';

function concatPercent(value: number): string {
  return value !== 0 ? `${value}%` : value.toString();
}

function isOuterKnob(knobSize: number, trackHeight: number): boolean {
  return knobSize > trackHeight;
}

function innerKnobWidth(
  knobSize: number,
  trackHeight: number,
  initialKnobWidth?: number,
): number {
  if (typeof initialKnobWidth !== 'undefined') {
    return 100 - initialKnobWidth - (trackHeight - knobSize) / 4;
  }
  return (trackHeight - knobSize) / 4;
}

function outerKnobWidth(initialKnobWidth: number): number {
  return 100 - initialKnobWidth;
}

function calcUncheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: LogicalAlignment,
): number {
  let result: number;
  if (alignment === 'end') {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  }
  return result;
}

function calcCheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: LogicalAlignment,
): number {
  let result: number;
  if (alignment === 'end') {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  }
  return result;
}

export const props = withCompInfo<
  IToggleSwitchMapperProps,
  ToggleSwitchDefinition
>()(
  ['compData', 'compProps', 'hasResponsiveLayout'],
  ({ compData, compProps, hasResponsiveLayout }) => {
    const { checked, value } = compData;
    const { isDisabled, label } = compProps;
    return {
      hasResponsiveLayout,
      isDisabled,
      checked,
      value,
      label,
    };
  },
);

type Overrides = {
  styleProperties: ToggleSwitchStyleProperties;
};

export const css = withCompInfo<
  ToggleSwitchRootCSS,
  ToggleSwitchDefinition,
  never,
  Overrides
>()(
  [
    'compProps',
    'compLayout',
    'hasResponsiveLayout',
    'styleProperties',
    'compData',
  ],
  ({
    compProps,
    compLayout,
    hasResponsiveLayout,
    styleProperties,
    compData,
  }) => {
    const {
      displayKnobIcons,
      trackHeight,
      knobSize,
      label,
      labelMargin,
      switchHeight,
      labelPadding,
    } = compProps;
    const { direction } = compData;
    const { align } = styleProperties;

    const knobWidth = knobSize / 2;

    const cssVars: ToggleSwitchRootCSS = {
      '--toggleIconDisplay': displayKnobIcons ? 'block' : 'none',
      '--innerLabelHeight': concatPercent(knobSize),
      '--innerLabelWidth': concatPercent(knobWidth),
      '--outerLabelHeight': concatPercent(trackHeight),
      '--labelMovementRangeChecked': concatPercent(
        calcCheckedLeftValue(knobSize, trackHeight, knobWidth, align),
      ),
      '--labelMovementRangeUnchecked': concatPercent(
        calcUncheckedLeftValue(knobSize, trackHeight, knobWidth, align),
      ),
      '--align': align,
      direction,
    };

    if (label) {
      const paddings = getLabelPaddingsValues({
        labelPadding,
        align,
      });

      return {
        ...cssVars,
        ...(hasResponsiveLayout ? {} : { height: 'auto' }),
        '--switchHeight': `${switchHeight}px`,
        '--switchWidth': `${switchHeight * 2}px`,
        '--labelMarginBottom': `${labelMargin}px`,
        ...addUnitToEveryField(paddings),
      };
    }

    const { height, width } = compLayout;
    const wrongAspectRatio = height && width ? height * 2 !== width : false;

    if (height && wrongAspectRatio && !hasResponsiveLayout) {
      return {
        ...cssVars,
        height: `${switchHeight || height}px`,
        width: `${(switchHeight || height) * 2}px`,
      };
    }

    return cssVars;
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'alignment', target: 'align' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'alignment', target: 'direction' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
    ]),
  ],
);

export default createComponentMapperModel({ css, props });
