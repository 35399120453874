import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getWidthPixelValue } from '@wix/editor-elements-common-utils';
import { ariaLabels, TranslationKeys } from '../constants';

// TODO add types
const props = withCompInfo<any, any>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'compSingleLayout',
    'compStylableClass',
    'translate',
  ],
  ({
    compData,
    compProps,
    compLayout,
    compSingleLayout,
    compStylableClass,
    translate,
  }) => {
    const widthPixelValue = getWidthPixelValue(compLayout, compSingleLayout);
    return {
      ...compData,
      ...compProps,
      stylableClassName: compStylableClass,
      width: widthPixelValue,
      translations: {
        minValueAriaLabel:
          translate(
            ariaLabels.ariaNamespace,
            TranslationKeys.minValueAriaLabel,
          ) || ariaLabels.ariaDefaultMinValueLabel,
        maxValueAriaLabel:
          translate(
            ariaLabels.ariaNamespace,
            TranslationKeys.maxValueAriaLabel,
          ) || ariaLabels.ariaDefaultMaxValueLabel,
      },
    };
  },
);

export default createComponentMapperModel({
  props,
});
