import * as React from 'react';
import { IGoogleAuthResponseData } from '../MemberLoginDialog.types';

export const usePopUrl = (
  wrapAsState: boolean = false,
  callbackUrl?: string,
) => {
  const [prevBc, setPrevBc] = React.useState<BroadcastChannel | null>(null);

  return React.useCallback(
    (url: string) =>
      new Promise<IGoogleAuthResponseData>(async (resolve, reject) => {
        prevBc?.close();
        /*
      Once social auth is completed, the server communicates the member's details and session token asynchronously via a BroadcastChannel.
      As this broadcast channel is readable by all sites on the same domain, and certainly by JS apps installed on this site, we:
      - generate a unique session ID for every login attempt. This protects us from accidental interference between sites or even multiple
        login attempts on the same site.
      - encrypt the data using a random key. This protects us from malicious JS apps installed on the site or other sites on the same
        domain.
      */
        const sessionId = window.crypto.randomUUID();
        const key = await window.crypto.subtle.generateKey(
          { name: 'AES-GCM', length: 256 },
          true,
          ['encrypt', 'decrypt'],
        );
        const encryptionKey = uint8ToBase64(
          new Uint8Array(await window.crypto.subtle.exportKey('raw', key)),
        );

        const bc = new BroadcastChannel(`wix-idp-${sessionId}`);
        setPrevBc(bc);
        bc.addEventListener('message', async event => {
          const { data: eventData } = event;
          const { iv, data } = eventData;
          const decryptedData = await window.crypto.subtle.decrypt(
            { name: 'AES-GCM', iv },
            key,
            data,
          );
          const loginResponse = JSON.parse(
            new TextDecoder().decode(decryptedData),
          );
          if (loginResponse.error) {
            reject(loginResponse.error);
          } else {
            resolve(JSON.parse(loginResponse.response));
          }
          bc.postMessage(await encryptedCloseMessage(key));
          bc.close();
          setPrevBc(null);
        });

        const finalUrl = buildUrlWithQueryParams(
          url,
          sessionId,
          encryptionKey,
          wrapAsState,
          callbackUrl,
        );
        window.open(finalUrl, 'oauthPopup', 'width=450,height=522');
      }),
    [prevBc, wrapAsState, callbackUrl],
  );
};

const uint8ToBase64 = (arr: Uint8Array): string =>
  btoa(
    Array(arr.length)
      .fill('')
      .map((_, i) => String.fromCharCode(arr[i]))
      .join(''),
  );

const encryptedCloseMessage = async (key: CryptoKey) => {
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const data = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    new TextEncoder().encode('close'),
  );
  return { iv, data };
};

function buildUrlWithQueryParams(
  url: string,
  sessionId: string,
  encryptionKey: string,
  wrapAsState: boolean = false,
  callbackUrl?: string,
) {
  const stateParams = { sessionId, encryptionKey };

  const queryParams = buildQueryParamsDict(
    stateParams,
    wrapAsState,
    callbackUrl,
  );

  const searchParams = convertToSearchParams(queryParams);
  try {
    const parsedUrl = new URL(url);
    return addParamsToUrl(parsedUrl, searchParams);
  } catch {
    return `${url}&${searchParams.toString()}`;
  }
}

function buildQueryParamsDict(
  stateParams: Record<string, string>,
  wrapAsState: boolean = false,
  callbackUrl?: string,
): Record<string, string> {
  let queryParamsDict: Record<string, string> = {};

  if (wrapAsState) {
    queryParamsDict.state = btoa(JSON.stringify(stateParams));
  } else {
    queryParamsDict = { ...stateParams };
  }
  if (callbackUrl) {
    queryParamsDict.callbackUrl = callbackUrl;
  }

  return queryParamsDict;
}

function convertToSearchParams(
  params: Record<string, string>,
): URLSearchParams {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      searchParams.append(key, params[key].toString());
    }
  }

  return searchParams;
}

function addParamsToUrl(url: URL, searchParams: URLSearchParams) {
  const urlSearchParams = url.searchParams;

  for (const [key, value] of searchParams) {
    urlSearchParams.append(key, value);
  }

  return url.toString();
}
