import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  customCssClasses,
  getDataAttributes,
  getTabIndexAttribute,
} from '@wix/editor-elements-common-utils';
import { IRatingsDisplayProps } from '../RatingsDisplay.types';
import { TestIds, NoReviewsMode } from '../constants';
import semanticClassNames from '../RatingsDisplay.semanticClassNames';
import { st, classes } from './RatingsDisplay.component.st.css';
import RatingIcons from './RatingIcons/RatingIcons';

const getStyle = (elem: HTMLElement | null) =>
  elem ? window.getComputedStyle(elem) : null;

const getWidth = (
  elem: HTMLElement | null,
  shouldCalcWithoutMargins: boolean,
) =>
  shouldCalcWithoutMargins
    ? getWidthWithoutMargins(elem)
    : getWidthWithMargins(elem);

const getWidthWithMargins = (elem: HTMLElement | null) => {
  const elemStyle = getStyle(elem);
  return elemStyle
    ? parseInt(elemStyle.marginRight, 10) +
        elem!.offsetWidth +
        parseInt(elemStyle.marginLeft, 10)
    : 0;
};

const getWidthWithoutMargins = (elem: HTMLElement | null) => {
  const elemStyle = getStyle(elem);

  return elemStyle ? elem!.clientWidth : 0;
};

const getHeight = (elem: HTMLElement | null) => (elem ? elem.offsetHeight : 0);

const getHeightWithMargins = (elem: HTMLElement | null) => {
  const elemStyle = getStyle(elem);
  return elemStyle
    ? parseInt(elemStyle.marginTop, 10) +
        elem!.offsetHeight +
        parseInt(elemStyle.marginBottom, 10)
    : 0;
};

type RootStyle = { minWidth?: string; height?: string };

const noop = () => {};

const getAccessibilityText = (
  translations: IRatingsDisplayProps['translations'],
  rating: IRatingsDisplayProps['rating'] = 0,
  showReviewsCount: IRatingsDisplayProps['showReviewsCount'],
  numRatings: IRatingsDisplayProps['numRatings'],
  reviewsCountLabel: IRatingsDisplayProps['reviewsCountLabel'],
) => {
  const average = translations.a11yAverage;
  const outOf = translations.a11yOutOf;
  const basedOn = translations.a11yBasedOn;
  const votes = translations.a11yVotes;
  const reviewPart =
    `${basedOn} ${numRatings} ${votes}` +
    (reviewsCountLabel ? `, ${reviewsCountLabel}` : '');

  return `${average} ${rating} ${outOf}${
    showReviewsCount ? `, ${reviewPart}` : ''
  }`;
};

const RatingsDisplay: React.FC<IRatingsDisplayProps> = props => {
  const {
    id,
    className,
    customClassNames = [],
    onMouseEnter = noop,
    onMouseLeave = noop,
    onClick = noop,
    onDblClick = noop,
    overrideIconsStyle,
    reCalculateIconsSizeDeps = [],
    svgString,
    noReviewsMode,
    rating,
    noReviewsPlaceholder,
    numRatings,
    reviewsCountLabel,
    showReviewsCount,
    showRating,
    showRatingInfo,
    showIcons,
    isTransparent,
    translations,
  } = props;

  const [style, setStyle] = useState<RootStyle>({});
  const placeholderRef = useRef<HTMLElement>(null);
  const ratingRef = useRef<HTMLElement>(null);
  const iconsRef = useRef<HTMLElement>(null);
  const reviewsCountRef = useRef<HTMLElement>(null);

  const checkedThreshold = React.useMemo(() => {
    if (!rating && noReviewsMode === NoReviewsMode.EMPTY_ICONS) {
      return 0;
    }
    return Math.round(((rating || 0) + 0.7) * 10) / 10;
  }, [rating, noReviewsMode]);

  const reviewsCounter = React.useMemo(() => {
    if (numRatings < 1000) {
      return `${numRatings}`;
    } else if (numRatings < 9950) {
      return `${Math.round((numRatings / 1000) * 10) / 10}K`;
    } else if (numRatings < 999500) {
      return `${Math.round(numRatings / 1000)}K`;
    } else if (numRatings < 9950000) {
      return `${Math.round((numRatings / 1e6) * 10) / 10}M`;
    }
    return `${Math.round(numRatings / 1e6)}M`;
  }, [numRatings]);

  useEffect(() => {
    const shouldCalcWithoutMargins = !!overrideIconsStyle;
    const iconsStyle = overrideIconsStyle?.();

    const placeholderHeight = getHeight(placeholderRef.current);
    const ratingWidth = getWidth(ratingRef.current, shouldCalcWithoutMargins);
    const ratingHeight = getHeightWithMargins(ratingRef.current);
    const iconsWidth = iconsStyle
      ? iconsStyle.iconsWidth
      : getWidth(iconsRef.current, shouldCalcWithoutMargins);
    const iconsHeight = iconsStyle
      ? iconsStyle.iconsHeight
      : getHeightWithMargins(iconsRef.current);
    const reviewsCountWidth = getWidthWithMargins(reviewsCountRef.current);
    const reviewsCountHeight = getHeightWithMargins(reviewsCountRef.current);
    const minWidth = `${ratingWidth + iconsWidth + reviewsCountWidth}px`;
    const calculatedHeight = Math.max(
      placeholderHeight,
      ratingHeight,
      iconsHeight,
      reviewsCountHeight,
    );
    const height = calculatedHeight > 0 ? `${calculatedHeight}px` : '1.5em';
    setStyle({ minWidth, height });

    /*
      Every time icons size or shape spacing is modified in editor (layout panel),
      we need to recalculate the icons styles based on that values.
      We don't want that to happen every render in live sites, so we used reCalculateIconsSizeDeps
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideIconsStyle, ...reCalculateIconsSizeDeps]);

  const a11yText = useMemo(
    () =>
      getAccessibilityText(
        translations,
        rating,
        showReviewsCount,
        numRatings,
        reviewsCountLabel,
      ),
    [translations, rating, showReviewsCount, numRatings, reviewsCountLabel],
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...getTabIndexAttribute(props.a11y)}
      className={st(
        classes.root,
        {
          transparent: !!isTransparent,
        },
        className,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onDoubleClick={onDblClick}
      style={style}
    >
      {!rating && noReviewsMode === NoReviewsMode.PLACEHOLDER_TEXT ? (
        <span
          className={classes.noReviewsPlaceholder}
          data-testid={TestIds.noReviewsPlaceholder}
          ref={placeholderRef}
        >
          {noReviewsPlaceholder}
        </span>
      ) : null}
      {(rating || showRatingInfo) && showRating ? (
        <span
          className={classes.ratingValue}
          data-testid={TestIds.ratingValue}
          ref={ratingRef}
          aria-hidden="true"
        >
          {(rating || 0).toFixed(1)}
        </span>
      ) : null}
      {rating || noReviewsMode === NoReviewsMode.EMPTY_ICONS || showIcons ? (
        <RatingIcons
          rating={rating}
          checkedThreshold={checkedThreshold}
          svgString={svgString}
          ref={iconsRef}
        />
      ) : null}
      {(rating || showRatingInfo) && showReviewsCount ? (
        <span
          className={st(
            classes.reviewsCount,
            customCssClasses(semanticClassNames.label),
          )}
          data-testid={TestIds.reviewsCounter}
          ref={reviewsCountRef}
          aria-hidden="true"
        >
          {reviewsCounter} {reviewsCountLabel}
        </span>
      ) : null}
      <span className={classes.srOnly} data-testid={TestIds.srOnly}>
        {a11yText}
      </span>
    </div>
  );
};

export default RatingsDisplay;
