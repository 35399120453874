import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CompositeDecorator,
  ContentState,
  DraftEditorCommand,
  DraftHandleValue,
  EditorProps as CoreEditorProps,
  EditorState,
  RichUtils,
  convertFromRaw,
} from 'draft-js';

import classnames from 'clsx';
import CoreEditor from 'draft-js-plugins-editor';

import PluginEditor from 'draft-js-plugins-editor/lib';

import { customCssClasses } from '@wix/editor-elements-common-utils';
import {
  editorStateToHtml,
  htmlToEditorState,
  isMaxLength,
} from '../convertors';
import Toolbar from '../toolbars/Toolbar';
import { textAlignmentStylesFn } from '../toolbars/buttons/TextAlignButtons/common';
import { findLinkEntities } from '../utils/draftUtils';
import { textSizeStylesFn } from '../toolbars/buttons/TextSizeButton/utils/utils';
import StylesContext from '../StylesContext';
import { TestIds } from '../../constants';
import {
  IRichTextBoxImperativeActions,
  IRichTextBoxProps,
} from '../../RichTextBox.types';
import semanticClassNames from '../../RichTextBox.semanticClassNames';
import preventWixFocusRingAccessibility from './preventWixFocusRingAccessibility';
import '../.global.scss';
import editorStyles from './editorStyles.scss';

type DecoratorComponentProps = {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode;
};
const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: (props: DecoratorComponentProps) => {
      const { contentState, entityKey, children } = props;
      const { url, targetNewWindow } = contentState
        .getEntity(entityKey)
        .getData();
      return (
        <a
          href={url}
          className={editorStyles.link}
          target={targetNewWindow ? '_blank' : '_self'}
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
]);

export type ToolbarType = {
  toolbarPosition: IRichTextBoxProps['toolbarPosition'];
  instance: any;
  plugin: any;
};

export type EditorProps = Partial<CoreEditorProps> &
  Pick<
    IRichTextBoxProps,
    | 'value'
    | 'maxLength'
    | 'allowLinks'
    | 'componentViewMode'
    | 'hideValidityIndication'
    | 'onValueChange'
    | 'validateValueAndShowIndication'
    | 'openMobileLinkModalTemp'
    | 'closeMobileLinkModalTemp'
    | 'linkModalId'
    | 'label'
    | 'scopedClassName'
  > & {
    toolbar: ToolbarType;
    onChangeState: IRichTextBoxProps['onChange'];
    rootId: IRichTextBoxProps['id'];
  };

const emptyContentState = convertFromRaw({
  entityMap: {},
  blocks: [
    {
      text: '',
      key: 'initializing',
      type: 'unstyled',
      entityRanges: [],
      depth: 0,
      inlineStyleRanges: [],
    },
  ],
});

const Editor: React.ForwardRefRenderFunction<
  IRichTextBoxImperativeActions,
  EditorProps
> = (
  {
    value,
    maxLength,
    toolbar,
    readOnly,
    placeholder,
    textAlignment,
    componentViewMode,
    allowLinks,
    onBlur = () => {},
    onChangeState = () => {},
    onFocus = () => {},
    onValueChange = () => {},
    hideValidityIndication = () => {},
    validateValueAndShowIndication = () => {},
    openMobileLinkModalTemp,
    closeMobileLinkModalTemp,
    rootId,
    linkModalId,
    label,
    scopedClassName,
  },
  ref,
) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(emptyContentState),
  );

  const [changed, setChanged] = useState(false);
  const [overrideDialogIsOpen, setOverrideDialogIsOpen] = useState(false);
  const [onChangeTriggered, setOnChangeTriggered] = useState(false);
  const stylesContext = useContext(StylesContext);

  useEffect(() => {
    if (!onChangeTriggered) {
      const [oldValue, newValue] = [editorStateToHtml(editorState), value].map(
        v =>
          editorStateToHtml(
            EditorState.set(htmlToEditorState(v), { decorator }),
          ).replace(/\u00a0/g, ' '),
      );
      if (oldValue !== newValue) {
        const newState = htmlToEditorState(value, maxLength);
        const newStateDecorated = EditorState.set(newState, { decorator });

        setEditorState(newStateDecorated);
      }
    }
    setOnChangeTriggered(false);
    // eslint-disable-next-line
  }, [value, maxLength]);

  useEffect(() => {
    preventWixFocusRingAccessibility();
  }, []);

  const editorRef = useRef<PluginEditor>(null);

  const getStateWithMaxLengthEnsured = (
    newState: Draft.EditorState,
  ): Draft.EditorState => {
    if (maxLength !== 0 && !maxLength) {
      return newState;
    }
    const contentState = newState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();

    if (contentState === oldContent || !isMaxLength(newState, maxLength)) {
      return newState;
    }
    return EditorState.undo(
      EditorState.push(
        editorState,
        ContentState.createFromText(oldContent.getPlainText()),
        'delete-character',
      ),
    );
  };

  const onChange = (state: EditorState) => {
    const newState = EditorState.set(state, { decorator });
    const validatedNewState = getStateWithMaxLengthEnsured(newState);
    const newHtmlState = editorStateToHtml(validatedNewState);
    const newHtmlStateValue = newHtmlState.replace(/\u00a0/g, ' ');
    const oldHtmlStateValue = editorStateToHtml(editorState).replace(
      /\u00a0/g,
      ' ',
    );
    setEditorState(validatedNewState);

    if (newHtmlStateValue !== oldHtmlStateValue) {
      setOnChangeTriggered(true);
      onValueChange(newHtmlState);
      setChanged(true);
      hideValidityIndication();
    }
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    onBlur(event);
    if (changed) {
      onChangeState(event);
      validateValueAndShowIndication({ value: event.target.textContent || '' });
      setChanged(false);
    }
  };

  const focus = () => {
    editorRef!.current!.focus();
  };

  React.useImperativeHandle(ref, () => {
    return {
      focus,
      blur: () => {
        editorRef!.current!.blur();
      },
      // TODO - Currently we don't show a message, even in Bolt.
      setCustomValidity: _message => {},
    };
  });

  const handleKeyCommand = (command: DraftEditorCommand): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const { toolbarPosition, instance: Instance, plugin } = toolbar;

  const onLabelClick = () => {
    editorRef.current?.focus();
  };

  return (
    <>
      {label && (
        <label
          id={`input_${rootId}`}
          onClick={onLabelClick}
          className={classnames(editorStyles.label, {
            [editorStyles.requiredIndication]: stylesContext.required,
          })}
          data-testid={TestIds.label}
        >
          {label}
        </label>
      )}
      <div
        className={classnames(
          editorStyles.editor,
          customCssClasses(semanticClassNames.input),
          {
            [editorStyles.hovered]: stylesContext.hovered,
            [editorStyles.focused]: stylesContext.focused,
            [editorStyles.error]: stylesContext.error,
            [editorStyles.disabled]: stylesContext.disabled,
            [editorStyles.useTopToolbar]: toolbarPosition === 'top',
            [editorStyles.useBottomToolbar]: toolbarPosition === 'bottom',
            [editorStyles.useInlineToolbar]: toolbarPosition === 'inline',
            [editorStyles.overrideDialogIsOpen]: overrideDialogIsOpen,
            [editorStyles.withLabel]: !!label,
          },
        )}
        onMouseUp={focus}
        onClick={focus}
        onBlur={_onBlur}
        onFocus={onFocus}
      >
        <CoreEditor
          blockStyleFn={contentBlock => {
            const textAlignmentClass = textAlignmentStylesFn(
              contentBlock,
              textAlignment,
            );
            const textSizeClass = textSizeStylesFn(contentBlock);
            return classnames(textAlignmentClass, textSizeClass);
          }}
          editorState={editorState}
          onChange={onChange}
          plugins={[plugin]}
          ref={editorRef}
          handleKeyCommand={handleKeyCommand}
          readOnly={readOnly}
          placeholder={placeholder}
          {...(label ? { tabIndex: 0, ariaLabelledBy: `input_${rootId}` } : {})}
          {...(componentViewMode === 'editor' ? { textAlignment } : {})}
        />
        <Toolbar
          ToolbarInstance={Instance}
          type={toolbarPosition}
          allowLinks={allowLinks}
          linkModalId={linkModalId}
          openMobileLinkModalTemp={openMobileLinkModalTemp}
          closeMobileLinkModalTemp={closeMobileLinkModalTemp}
          onOverrideDialogOpen={() => setOverrideDialogIsOpen(true)}
          onOverrideDialogClose={() => setOverrideDialogIsOpen(false)}
          scopedClassName={scopedClassName}
        />
      </div>
    </>
  );
};

export default React.forwardRef(Editor);
