import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { CheckboxGroupDefinition } from '@wix/thunderbolt-components';
import { CompInfo, ThemeFontMap } from '@wix/editor-elements-types/thunderbolt';
import { getScaledFont } from '@wix/editor-elements-common-utils';
import type {
  ICheckboxMapperProps,
  CheckboxDefinition,
  ICheckboxStateValues,
  CheckboxCSSVars,
  ICheckboxCarmiProps,
} from '../Checkbox.types';

export const props = withCompInfo<
  ICheckboxMapperProps,
  CheckboxDefinition,
  ICheckboxCarmiProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

export const generateCheckboxCSSVars = (
  compInfo: Pick<
    CompInfo<CheckboxDefinition | CheckboxGroupDefinition>,
    | 'compData'
    | 'compProps'
    | 'compLayout'
    | 'hasResponsiveLayout'
    | 'structure'
    | 'isMobileView'
    | 'styleProperties'
  > & { siteFonts: ThemeFontMap },
): CheckboxCSSVars => {
  const { compProps, hasResponsiveLayout, compData, compLayout, structure } =
    compInfo;

  const { alignment, buttonSize, spacing, inputMobileFontSize } = compProps;
  const isGroupOrLabel =
    structure.componentType === 'CheckboxGroup' || !!compData.label;
  const size =
    hasResponsiveLayout || isGroupOrLabel ? buttonSize : compLayout.height;

  const checkboxSize = `${size}px`;
  const fnt = getScaledFont(compInfo, 'fnt', inputMobileFontSize);

  const varsByAlignment = {
    left: {
      '--dir': 'ltr',
      '--flexDirection': 'reverse',
      '--checkmarkMargin': `auto auto auto -${size}px`,
      '--labelMargin': `0 0 0 ${spacing + 1}px`,
    },
    right: {
      '--dir': 'rtl',
      '--flexDirection': 'row-reverse',
      '--checkmarkMargin': `auto -${size}px auto auto`,
      '--labelMargin': `0 ${spacing + 1}px 0 0`,
    },
  } as const;

  return {
    '--checkboxSize': checkboxSize,
    '--fnt': fnt,
    ...varsByAlignment[alignment],
    ...(!isGroupOrLabel && {
      '--labelMargin': undefined,
    }),
    ...(!hasResponsiveLayout && {
      height: 'auto',
    }),
  };
};

export const css = withCompInfo<CheckboxCSSVars, CheckboxDefinition>()(
  [
    'compProps',
    'compData',
    'compLayout',
    'hasResponsiveLayout',
    'structure',
    'styleProperties',
    'siteFonts',
    'isMobileView',
  ],
  generateCheckboxCSSVars,
);

const stateRefs = withStateRefsValues<keyof ICheckboxStateValues>(['reportBi']);

export default createComponentMapperModel({ props, css, stateRefs });
