import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import { BreadcrumbsSdkDataCarmiData } from '@wix/thunderbolt-components-native';

import { getMigratedStyleProps } from '@wix/editor-elements-common-utils';

const isExperimentOpen = (experiment: boolean | string) =>
  String(experiment) === 'true';

import {
  BreadcrumbsDefinition,
  IBreadcrumbsMapperProps,
  BreadcrumbsCSSVars,
  BreadcrumbsPropsCarmiData,
  IBreadcrumbsStateValues,
} from '../Breadcrumbs.types';
import { BREADCRUMBS_STUDIO_EXPERIMENT } from '../constants';

const props = withCompInfo<
  IBreadcrumbsMapperProps,
  BreadcrumbsDefinition,
  BreadcrumbsPropsCarmiData
>()(
  [
    'compProps',
    'styleProperties',
    'compStylableClass',
    'isMobileView',
    'externalBaseUrl',
    'pagesMap',
    'mainPageId',
    'editorName',
    'experiments',
  ],
  (
    {
      compProps,
      compStylableClass,
      isMobileView,
      externalBaseUrl,
      pagesMap,
      mainPageId,
      styleProperties,
      editorName,
      experiments,
    },
    carmiData,
  ) => {
    const {
      separator,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      showOnlyPreviousPageOnMobile,
      itemsBeforeCollapse,
      itemsAfterCollapse,
    } = compProps;
    const migratedProps = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields: ['direction', 'alignment'],
    }) as {
      direction: 'ltr' | 'rtl';
      alignment: 'left' | 'center' | 'right';
    };

    const isEnabledInStudio = isExperimentOpen(
      experiments[BREADCRUMBS_STUDIO_EXPERIMENT],
    );

    return {
      hasPageHierarchyBreadcrumbs: isEnabledInStudio && editorName === 'Studio',
      svgString: carmiData.svgString,
      pagesMenuItems: carmiData.pagesMenuItems,
      pagesMap,
      mainPageId,
      separator,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      itemsBeforeCollapse,
      itemsAfterCollapse,
      showOnlyPreviousPageOnMobile:
        isMobileView && showOnlyPreviousPageOnMobile,
      isMobile: isMobileView,
      stylableClassName: compStylableClass,
      externalBaseUrl,
      ...migratedProps,
    };
  },
);

const css = withCompInfo<BreadcrumbsCSSVars, BreadcrumbsDefinition>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }) => {
    const { horizontalMargin, verticalMargin } = compProps;

    const { direction, alignment } = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields: ['direction', 'alignment'],
    });

    const isLtr = direction === 'ltr';

    return {
      '--lastItemMargin': isLtr
        ? `0 0 0 ${horizontalMargin}px`
        : `0 ${horizontalMargin}px 0 0`,
      '--rightMargin': isLtr ? '0px' : `${horizontalMargin}px`,
      '--leftMargin': isLtr ? `${horizontalMargin}px` : '0px',
      '--horizontalMargin': `${horizontalMargin}px`,
      '--verticalMargin': `${verticalMargin}px`,
      '--alignment': alignment,
      '--flexDirection': isLtr ? 'row' : 'row-reverse',
      '--direction': direction,
      height: 'auto', // component is not resizable vertically and should have content height
    };
  },
);

const sdkData = withCompInfo<
  BreadcrumbsSdkDataCarmiData,
  BreadcrumbsDefinition
>()(['topology'], ({ topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();

  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
  };
});

const stateRefs = withStateRefsValues<keyof IBreadcrumbsStateValues>([
  'currentUrl',
  'pageId',
  'setVeloSeoTags',
  'previewMode',
]);

export default createComponentMapperModel({ css, props, sdkData, stateRefs });
