import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  ISelectableContainerCarmiProps,
  ISelectableContainerDefinition,
  ISelectableContainerMapperProps,
} from '../types';
import { props as viewerPropsMapper } from './SelectableContainer.mapper';

const props = composeMapper<
  ISelectableContainerMapperProps,
  ISelectableContainerDefinition,
  ISelectableContainerCarmiProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode: deps.componentViewMode,
        compPreviewState: deps.compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
