import React, { useEffect } from 'react';
import { IStylableButtonImperativeActions } from '../../../StylableButton/StylableButton.types';
import StylableButton from '../../../StylableButton/viewer/StylableButton';
import { ISlideshowButtonProps } from '../../types';
import { classes as stylableButtonClasses } from '../../../StylableButton/viewer/StylableButton.component.st.css';
import { useSlideshowContext } from '../../SlideshowContext';
import { classes, st } from './SlideshowButton.component.st.css';

const SlideshowButton: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  ISlideshowButtonProps
> = (
  { action, isDisabled, isDisabledByCtx, onDisabledChange, ...props },
  ref,
) => {
  const { onButtonClick, isButtonDisabled } = useSlideshowContext();
  const onClick = () => onButtonClick(action);
  const disabled = isDisabled ?? isDisabledByCtx;

  useEffect(() => {
    const uxDisabled = isButtonDisabled(action);
    if (uxDisabled !== isDisabledByCtx) {
      onDisabledChange(uxDisabled);
    }
  }, [isDisabledByCtx, isButtonDisabled, action, onDisabledChange]);

  return (
    <StylableButton
      {...props}
      onClick={onClick}
      ref={ref}
      className={st(
        stylableButtonClasses.root,
        classes.root,
        disabled ? classes.disabled : '',
      )}
      isDisabled={disabled}
    />
  );
};

export default React.forwardRef(SlideshowButton);
