import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISlideshowIndicatorProps,
  ISlideshowIndicatorMapperProps,
  ISlideshowIndicatorControllerProps,
  ISlideshowIndicatorStateValues,
} from '../../../components/SlideshowIndicator/SlideshowIndicator.props';

export default withCompController<
  ISlideshowIndicatorMapperProps,
  ISlideshowIndicatorControllerProps,
  ISlideshowIndicatorProps,
  ISlideshowIndicatorStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
