import { withCompController } from '@wix/editor-elements-integrations';
import {
  IPaginationControllerProps,
  IPaginationMapperProps,
  IPaginationProps,
} from '../Pagination.types';

export default withCompController<
  IPaginationMapperProps,
  IPaginationControllerProps,
  IPaginationProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onChange: ({
      target: {
        value: { currentPage },
      },
    }) => controllerUtils.updateProps({ currentPage }),
  };
});
