import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISelectableContainerInputProps,
  ISelectableContainerInputMapperProps,
  ISelectableContainerInputControllerProps,
  ISelectableContainerInputStateValues,
} from '../../../components/SelectableContainerInput/SelectableContainerInput.props';

export default withCompController<
  ISelectableContainerInputMapperProps,
  ISelectableContainerInputControllerProps,
  ISelectableContainerInputProps,
  ISelectableContainerInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
