import classnames from 'clsx';
import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import {
  ISkinableContainerProps,
  IContainerImperativeActions,
} from '../../Container.types';
import semanticClassNames from '../../Container.semanticClassNames';
import { ContainerLogic } from './ContainerLogic';

/** This is a shared dom structure for the bubble skin (box with arrow) */
const BubbleSkinContainerComp: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  ISkinableContainerProps
> = ({ classes, className, customClassNames = [], ...rest }, ref) => {
  return (
    <ContainerLogic
      {...rest}
      ref={ref}
      className={classnames(classes.root, className)}
      renderSlot={({ containerChildren }) => (
        <>
          <div
            className={classnames(
              classes.bg,
              customCssClasses(...customClassNames, semanticClassNames.root),
            )}
          />
          <div className={classes.arrow} />
          {containerChildren}
        </>
      )}
    />
  );
};

export const BubbleSkinContainer = React.forwardRef(BubbleSkinContainerComp);
