import React, { useEffect, useState } from 'react';
import { IExternalComponentProps } from '../../../ExternalComponent.types';
import { DEFAULT_ERROR, ERROR_MESSAGE_DELAY } from './constants';
import { classes } from './OnStageError.st.css';

const ErrorMessage: React.FC<{
  translations: IExternalComponentProps['translations'];
  translationKey?: string | Array<string>;
}> = ({ translationKey = '', translations }) => {
  const [keys, setKeys] = useState<Array<string>>(
    Array.isArray(translationKey) ? translationKey : [translationKey],
  );

  useEffect(() => {
    setKeys(Array.isArray(translationKey) ? translationKey : [translationKey]);
  }, [translationKey]);

  useEffect(() => {
    if (keys.length > 1) {
      const timeout = setTimeout(() => {
        keys.shift();
        setKeys([...keys]);
      }, ERROR_MESSAGE_DELAY);
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
  }, [keys]);

  const [key] = keys;

  return (
    <div className={classes.errMsg}>{translations[key] || DEFAULT_ERROR}</div>
  );
};

export default ErrorMessage;
