import * as React from 'react';
import { ISliderGalleryProps } from '../../../SliderGallery.types';
import SliderGallery from '../../SliderGallery';
import skinsItemStyle from '../../../../SliderGalleryItem/viewer/style/SliderGalleryIronSkinItem.scss';
import SliderGalleryIronSkinButtons from '../../skinButtons/SliderGalleryIronSkinButtons';
import skinsStyle from './style/SliderGalleryIronSkin.scss';

const SliderGalleryIronSkin: React.FC<
  Omit<ISliderGalleryProps, 'skin'>
> = props => {
  return (
    <SliderGallery
      {...props}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      SkinNavButton={SliderGalleryIronSkinButtons}
    />
  );
};

export default SliderGalleryIronSkin;
