import * as React from 'react';
import classnames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IWelcomeDialogProps } from '../WelcomeDialog.types';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { WelcomeDialogTranslationKeys as keys } from '../constants';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import style from './style/style.scss';

const WelcomeDialog: React.FC<IWelcomeDialogProps> = props => {
  const {
    id,
    isCloseable,
    className,
    onCloseDialogCallback,
    onSubmitCallback,
    translations,
  } = props;
  const headlineId = `WelcomeHeadline_${id}`;
  const title = translations[keys.title];
  const description = `${translations[keys.description]?.replace(
    '&#39;',
    "'",
  )} `;
  const descriptionSecondary = translations[keys.descriptionSecondary];
  const btnTxt = translations[keys.btnTxt]!;
  const backToSiteLinkTxt = translations[keys.backToSiteLinkTxt];

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      translations={translations}
    >
      <div
        id={id}
        className={classnames(style.welcomeContent, style.responsive)}
      >
        <h3 id={headlineId} className={style.title} data-testid="title">
          {title}
        </h3>
        <div className={style.wrapper}>
          <div className={style.content}>
            <div className={style.someInfoTxt} data-testid="someInfoTxt">
              {description}
            </div>
            <div className={style.checkTxt} data-testid="checkTxt">
              {descriptionSecondary}
            </div>
          </div>
          <div className={style.footer}>
            <div className={style.actionButton}>
              <BasicButton
                label={btnTxt}
                id={`okButton_${id}`}
                isDisabled={false}
                hasPlatformClickHandler={true}
                link={undefined}
                onClick={() => onSubmitCallback()}
              />
            </div>
            <button
              id={`backButton_${id}`}
              role="link"
              tabIndex={0}
              className={style.backButton}
              onClick={() => {
                if (onCloseDialogCallback) {
                  onCloseDialogCallback();
                }
              }}
              autoFocus
            >
              {backToSiteLinkTxt}
            </button>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default WelcomeDialog;
