import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
  IDefaultPreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import React from 'react';
import {
  DatePickerDefaultTextType,
  IDatePickerImperativeActions,
  IDatePickerProps,
} from '../DatePicker.types';

type ComponentViewMode = 'editor' | 'preview';

export type DatePickerPreviewWrapperProps = IDefaultPreviewWrapperProps & {
  shouldResetComponent: boolean;
  defaultTextType: DatePickerDefaultTextType;
  componentViewMode?: ComponentViewMode;
};

export type DatePickerViewerProps = IDatePickerProps;

const noop = () => {};

type CalendarPreviewStates = 'fakeData' | 'open';

const CALENDAR_PREVIEW_STATES = {
  FAKE_DATA: 'fakeData',
  OPEN: 'open',
} as const;

const isPreviewState = (
  componentPreviewState: string | null | undefined,
  state: CalendarPreviewStates,
) => componentPreviewState?.includes(state);

const shouldChangeEditorValue = (
  componentViewMode: ComponentViewMode | undefined,
  lastComponentViewMode: ComponentViewMode | undefined,
) => componentViewMode === 'editor' && lastComponentViewMode === 'editor';

const generateCalendarFakeData = () => {
  const selectedDateInCalendar = new Date(2080, 8, 13);
  const disabledDays = Array.from(
    { length: selectedDateInCalendar.getDate() - 1 },
    (_, i) => i,
  );
  const disabledDates = disabledDays.map(day =>
    new Date(
      selectedDateInCalendar.getFullYear(),
      selectedDateInCalendar.getMonth(),
      day,
    ).toISOString(),
  );

  return {
    disabledDates,
    selectedDateInCalendar,
  };
};

function withComponentPreview(
  WrappedComponent: React.ComponentType<IDatePickerProps>,
): IComponentPreviewWrapper<
  DatePickerViewerProps,
  DatePickerPreviewWrapperProps
> {
  return React.forwardRef<
    IDatePickerImperativeActions,
    PreviewWrapperProps<DatePickerViewerProps, DatePickerPreviewWrapperProps>
  >(
    (
      {
        previewWrapperProps,
        ...viewerProps
      }: PreviewWrapperProps<
        DatePickerViewerProps,
        DatePickerPreviewWrapperProps
      >,
      ref,
    ) => {
      const {
        compPreviewState,
        shouldResetComponent,
        defaultTextType,
        componentViewMode,
      } = previewWrapperProps || {};
      const {
        id,
        value = '',
        useTodayAsDefaultValue = false,
        setValidityIndication = noop,
        validateValue = noop,
        onValueChange = noop,
        setUseTodayAsDefaultValue = noop,
        shouldOpenCloseCalendar: initialShouldOpenCloseCalendar,
        isCompactMode,
      } = viewerProps;
      const editorValue = React.useRef(new Date(value));
      const editorUseTodayAsDefaultValue = React.useRef(useTodayAsDefaultValue);
      const [shouldOpenCloseCalendar, setShouldOpenCloseCalendar] =
        React.useState(initialShouldOpenCloseCalendar);
      const lastComponentViewMode = usePrevious(componentViewMode);

      React.useEffect(() => {
        if (shouldChangeEditorValue(componentViewMode, lastComponentViewMode)) {
          editorValue.current = new Date(value);
        }
      }, [value, lastComponentViewMode, componentViewMode]);

      React.useEffect(() => {
        if (shouldChangeEditorValue(componentViewMode, lastComponentViewMode)) {
          editorUseTodayAsDefaultValue.current = useTodayAsDefaultValue;
        }
      }, [useTodayAsDefaultValue, lastComponentViewMode, componentViewMode]);

      React.useEffect(() => {
        if (defaultTextType === 'placeholder' || defaultTextType === 'none') {
          onValueChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultTextType]);

      /*
      This useEffect is for setting shouldOpenCloseCalendar prop for
      DatePicker in order to control the opening and closing of the calendar.

      Cases:
      * If we are in editor mode we need to consider two cases(one for when to
        open the calendar(usually in the calendar design panel) and one for when to close it):
          1) we are in the open compPreviewState, are not in mobile and the calendar is
          not already open => we should open the calendar.
          2) we are not in the open compPreviewState and the calendar is
          open => we should close the calendar.

      * If we are in preview mode, we need to consider two cases because we need to
        close the calendar if it was open in the editor, and second we need to reset the
        prop so that we can close the calendar if it was opened by the user):
          1) the calendar is open => we should close the calendar
          2) the calendar was closed when we entered preview mode => we should reset the prop
            so that when we return to editor mode we can close the calendar.
    */
      React.useEffect(() => {
        if (componentViewMode === 'editor') {
          if (isPreviewState(compPreviewState, CALENDAR_PREVIEW_STATES.OPEN)) {
            if (shouldOpenCloseCalendar !== 'open' && !isCompactMode) {
              setShouldOpenCloseCalendar('open');
            }
          } else if (shouldOpenCloseCalendar === 'open') {
            setShouldOpenCloseCalendar('close');
          }
        } else if (componentViewMode === 'preview') {
          if (shouldOpenCloseCalendar === 'open') {
            setShouldOpenCloseCalendar('close');
          } else if (shouldOpenCloseCalendar === 'close') {
            setShouldOpenCloseCalendar(undefined);
          }
        }
      }, [
        compPreviewState,
        componentViewMode,
        setShouldOpenCloseCalendar,
        shouldOpenCloseCalendar,
        isCompactMode,
      ]);

      React.useEffect(() => {
        if (isCompactMode && componentViewMode === 'editor') {
          setShouldOpenCloseCalendar('close');
        }
      }, [isCompactMode, setShouldOpenCloseCalendar, componentViewMode]);

      usePreviewState(id, compPreviewState);

      usePreviewEffect({
        componentViewMode,
        onPreviewViewMode: validateValue,
      });

      useResetComponent({
        id,
        shouldResetComponent: !!shouldResetComponent,
        onResetComponent: () => {
          onValueChange(
            isNaN(editorValue.current.getDate())
              ? undefined
              : editorValue.current,
          );
          setUseTodayAsDefaultValue(editorUseTodayAsDefaultValue.current);
          setValidityIndication(false);
          validateValue();
          setShouldOpenCloseCalendar('close');
        },
      });

      const calendarFakeData = React.useMemo(
        () =>
          componentViewMode === 'editor' && shouldOpenCloseCalendar === 'open'
            ? generateCalendarFakeData()
            : {},
        [componentViewMode, shouldOpenCloseCalendar],
      );

      return (
        <WrappedComponent
          {...viewerProps}
          shouldOpenCloseCalendar={shouldOpenCloseCalendar}
          {...calendarFakeData}
          ref={ref}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<IDatePickerProps>) =>
  createComponentPreviewEntry<
    DatePickerViewerProps,
    DatePickerPreviewWrapperProps
  >(withComponentPreview(ViewerComponent));
