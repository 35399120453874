import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  migrateFields,
  convertPhysicalAlignmentToLogical,
  convertLogicalAlignmentToFlex,
  LogicalAlignment,
} from '@wix/editor-elements-common-utils';
import {
  GridDefinition,
  IGridMapperProps,
  GridProperties,
  GridCSSVars,
  GridPagination,
  GridSkin,
  GridHeaderColumn,
} from '../Grid.types';
import { translationKeys } from '../constants';
import { convertPaginationAlignmentToFlex } from '../utils';

const skinToHeaderColumnMap: Record<GridSkin, GridHeaderColumn> = {
  GridSkin: 'none',
  GridLeftHeaderSkin: 'first',
  GridRightHeaderSkin: 'last',
};

const mapPagination = ({
  type,
  rowsPerPage,
  style,
}: GridProperties['pagination']): GridPagination => ({
  type,
  rowsPerPage,
  style,
});

function tryJsonParse<T = {}>(
  text: string | null | undefined,
  fallback: () => T,
): T {
  if (text == null) {
    return fallback();
  }
  try {
    return JSON.parse(text);
  } catch (e) {
    return fallback();
  }
}

export const props = withCompInfo<IGridMapperProps, GridDefinition>()(
  [
    'skin',
    'compProps',
    'compData',
    'compLayout',
    'topology',
    'viewMode',
    'styleProperties',
    'translate',
  ],
  ({
    skin,
    compProps: {
      columns,
      showHeader,
      pagination,
      userSelectionType,
      dateFormat,
      linkTarget,
      columnLayout,
      rowHeight,
    },
    compData: { rows, dataSource },
    compLayout: { width },
    topology: { staticMediaUrl },
    styleProperties: { rowHeightUnit, columnWidthUnitMap },
    translate,
  }) => ({
    dataSource: dataSource.type,
    columns,
    columnWidthUnitMap: tryJsonParse(columnWidthUnitMap, () => ({})),
    rows,
    headerColumn: skinToHeaderColumnMap[skin],
    showHeader,
    pagination: mapPagination(pagination),
    userSelectionType,
    dateFormat,
    linkTarget,
    columnLayout,
    staticMediaUrl,
    rowHeight,
    width,
    rowHeightUnit,
    translations: {
      defaultColumnLabel: translate(
        translationKeys.NAMESPACE,
        translationKeys.DEFAULT_COLUMN_LABEL,
      ),
    },
  }),
);

export const css = withCompInfo<GridCSSVars, GridDefinition>()(
  ['compProps', 'compData', 'styleProperties', 'editorName'],
  ({
    compProps: { headerHeight, rowHeight, heightLayout },
    compData: { direction = 'inherit' },
    styleProperties: {
      cellPadding,
      rowHeightUnit,
      headerHeightUnit,
      align,
      paginationAlign,
    },
    editorName,
  }) => {
    return {
      ...(heightLayout === 'auto' && { height: 'auto' }),
      ...(heightLayout === 'auto' &&
        (editorName === 'EditorX' || editorName === 'Studio') && {
          'min-height': 'auto',
        }),
      '--headerHeight':
        headerHeightUnit === 'auto' ? 'auto' : `${headerHeight}px`,
      '--rowHeight':
        rowHeightUnit === 'auto'
          ? 'auto'
          : `${rowHeight}${rowHeightUnit === 'percent' ? '%' : 'px'}`,
      '--align': align as LogicalAlignment,
      '--contentAlign': convertLogicalAlignmentToFlex(
        align as LogicalAlignment,
      ),
      ...(align === 'start' && {
        '--cellPaddingStart': cellPadding,
      }),
      ...(align === 'end' && {
        '--cellPaddingEnd': cellPadding,
      }),
      '--paginationAlign': paginationAlign,
      '--direction': direction,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'horizontalAlignment', target: 'align' }],
        enhancer: convertPhysicalAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'pagination', target: 'paginationAlign' }],
        enhancer: (pagination: GridProperties['pagination']) =>
          convertPaginationAlignmentToFlex(pagination.alignment),
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
