import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { INotificationDialogProps } from '../NotificationDialog.types';
import style from './style/style.scss';
import { TestIds } from './constants';

const NotificationDialog: React.FC<INotificationDialogProps> = props => {
  const {
    id,
    className,
    title,
    description,
    okButtonText,
    onOkButtonClick,
    secondaryButtonText,
    onCloseDialogCallback,
    onSecondaryButtonClick,
    translations,
  } = props;
  const headlineId = `notificationHeadline_${id}`;

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      isCloseable={true}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      className={className}
      translations={translations}
    >
      <div id={id} className={style.notificationDialog}>
        <h2
          data-testid={TestIds.title}
          className={
            onSecondaryButtonClick ? style.titleSecondaryStyle : style.title
          }
          id={headlineId}
        >
          {title}
        </h2>
        <div data-testid={TestIds.description} className={style.description}>
          {description}
        </div>
        <div data-testid={TestIds.okButton} className={style.actionButton}>
          <BasicButton
            label={okButtonText}
            id={`okButton_${id}`}
            isDisabled={false}
            hasPlatformClickHandler={true}
            link={undefined}
            onClick={onOkButtonClick}
          />
        </div>
        {onSecondaryButtonClick && (
          <button
            id={`secondaryButton_${id}`}
            data-testid={TestIds.secondaryButton}
            className={style.secondaryButton}
            onClick={onSecondaryButtonClick}
            autoFocus
          >
            {secondaryButtonText}
          </button>
        )}
      </div>
    </SiteMembersDialogLayout>
  );
};

export default NotificationDialog;
