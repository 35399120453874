import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import { IExternalComponentProps } from '../ExternalComponent.types';
import PreviewExternalComponent from './components/PreviewExternalComponent/PreviewExternalComponent';
import { ExternalComponentPreviewProps } from './components/PreviewExternalComponent/PreviewExternalComponent.types';

function withComponentPreview(): IComponentPreviewWrapper<
  IExternalComponentProps,
  ExternalComponentPreviewProps
> {
  return React.forwardRef<
    {},
    PreviewWrapperProps<IExternalComponentProps, ExternalComponentPreviewProps>
  >((props, ref) => {
    const { previewWrapperProps, className = '', ...viewerProps } = props;

    return (
      <PreviewExternalComponent
        className={className}
        {...viewerProps}
        {...previewWrapperProps}
        ref={ref}
      />
    );
  });
}

export default () => createComponentPreviewEntry(withComponentPreview());
