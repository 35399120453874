import * as React from 'react';
import {
  noButtonsErrorMessage,
  defaultRootAriaLabel,
  shareUrlPrefixes,
  TestIds,
  TranslationKeys,
} from '../constants';
import { IShareButtonProps, IShareButtonsViewerProps } from '../types';
import { shouldDisplayIcon, shouldDisplayLabel } from '../utils/display';
import getFullShareUrl from '../utils/getFullShareUrl';
import getPlatformIdWithFallback from '../utils/getPlatformIdWithFallback';
import ShareButton from './ShareButton';
import { classes, st } from './style/ShareButtons.component.st.css';

const ShareButtons: React.FC<IShareButtonsViewerProps> = (
  props: IShareButtonsViewerProps,
) => {
  const {
    id,
    className,
    stylableClassName,
    buttons,
    buttonContent,
    shareUrl,
    translations,
    onMouseEnter,
    onMouseLeave,
  } = props;

  if (buttons.length === 0) {
    // eslint-disable-next-line no-console
    console.error(noButtonsErrorMessage);
  }

  const totalDisplayedButtons = React.useMemo(
    () => buttons.reduce((acc, next) => (acc += next.isHidden ? 0 : 1), 0),
    [buttons],
  );

  const getShareUrlPrefix = (button: IShareButtonProps) => {
    const platformIdWithFallback = getPlatformIdWithFallback(
      button.platformId,
      button.value,
    );

    return shareUrlPrefixes[platformIdWithFallback] ?? button.value;
  };

  return (
    <div id={id} className={className}>
      <div
        className={st(classes.root, stylableClassName)}
        role="group"
        aria-label={
          translations[TranslationKeys.component.label] || defaultRootAriaLabel
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {totalDisplayedButtons === 1 ? (
          <ShareButton
            {...buttons.find(button => !button.isHidden)}
            url={getFullShareUrl(getShareUrlPrefix(buttons[0]), shareUrl)}
            displayIcon={shouldDisplayIcon(buttonContent)}
            displayLabel={shouldDisplayLabel(buttonContent)}
            translations={translations}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/no-redundant-roles
          <ul
            id={`items_${id}`}
            className={classes.container}
            data-testid={TestIds.buttonsContainer}
            role="list"
          >
            {(buttons as Array<IShareButtonProps>)
              .filter(button => !button.isHidden)
              .map(button => (
                <li key={`item_${button.id}`} className={classes.item}>
                  <ShareButton
                    {...button}
                    url={getFullShareUrl(getShareUrlPrefix(button), shareUrl)}
                    displayIcon={shouldDisplayIcon(buttonContent)}
                    displayLabel={shouldDisplayLabel(buttonContent)}
                    translations={translations}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ShareButtons;
