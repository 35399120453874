import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { SiteButtonDefinition, ISiteButtonProps } from '../SiteButton.types';
import { props as viewerPropsMapper } from './SiteButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<ISiteButtonProps>,
  SiteButtonDefinition,
  ISiteButtonProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
    remove: ['language', 'mainPageId', 'compId', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;
    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: { compPreviewState },
      ...(compPreviewState === 'disabled' && { isDisabled: true }),
    };
  },
);

export default createComponentPreviewMapperModel({ props });
