import { composeMapper } from '@wix/editor-elements-preview-utils';
import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { IRichContentViewerProps } from '../RichContentViewer.types';
import { props as viewerPropsMapper } from './RichContentViewer.mapper';
import { getEmptyEditorContent } from './getEmptyContent';

const props = composeMapper<IRichContentViewerProps, any, any>()(
  viewerPropsMapper,
  { add: ['componentViewMode', 'translate'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { componentViewMode, translate } = deps;

    return {
      ...viewerProps,
      previewContent:
        componentViewMode === 'editor'
          ? getEmptyEditorContent({
              title:
                translate(
                  'rich_content',
                  'richContentViewer.emptyState.title',
                ) ?? 'Connect to CMS to show rich content',
              label:
                translate(
                  'rich_content',
                  'richContentViewer.emptyState.body',
                ) ??
                'Choose a Rich Content field from your CMS collection to show its content here.',
            })
          : undefined,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
