
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Breadcrumbs1375615820",
      {"classes":{"root":"Breadcrumbs1375615820__root","list":"Breadcrumbs1375615820__list","breadcrumb-icon":"Breadcrumbs1375615820__breadcrumb-icon","breadcrumb":"Breadcrumbs1375615820__breadcrumb","separator":"Breadcrumbs1375615820__separator","only-previous":"Breadcrumbs1375615820__only-previous","breadcrumb-content":"Breadcrumbs1375615820__breadcrumb-content","anchor":"Breadcrumbs1375615820__anchor","label":"Breadcrumbs1375615820__label","ellipsis":"Breadcrumbs1375615820__ellipsis","icon":"Breadcrumbs1375615820__icon"},"vars":{"horizontalMargin":"--horizontalMargin","verticalMargin":"--verticalMargin","lastItemMargin":"--lastItemMargin","rightMargin":"--rightMargin","leftMargin":"--leftMargin","alignment":"--alignment","flexDirection":"--flexDirection","direction":"--direction"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  