import * as React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'clsx';
import { usePortalPopper } from '../../../../providers/usePortalPopper';
import style from '../styles/TooltipAndTexts.scss';
import CookieIcon from '../assets/CookieIcon.svg';
import { ConsentText } from '../ConsentText/ConsentText';

export type TooltipProps = {
  id: string;
  datahook: string;
  text: string;
  ariaLabelText: string;
  isMobile: boolean;
  onSettingsClick: () => void;
  onPrivacyPolicyClick?: () => void;
};

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  datahook,
  text,
  ariaLabelText,
  isMobile,
  onSettingsClick,
  onPrivacyPolicyClick,
}) => {
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [showPopper, setShowPopper] = React.useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = React.useState(false);
  const [isAnimationEnd, setIsAnimationEnd] = React.useState(false);
  const [isTooltipLinkClicked, setIsTooltipLinkClicked] = React.useState(false);

  const tooltipButtonRef = React.useRef<HTMLButtonElement>(null);
  const compId = `${id}-${datahook}`;

  const {
    setRef: setPopperSourceEl,
    setPopper: setPopperTargetElem,
    styles: popperStyles,
    attributes,
    poppersWrapper,
    popper,
  } = usePortalPopper({
    id: `${compId}-tooltip`,
    containerId: 'SITE_CONTAINER',
    shouldMountWrapper: true,
    popperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrowElement },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['auto'],
          },
        },
      ],
    },
  });

  React.useEffect(() => {
    // move focus to tooltip online after the tooltip animation ended and tooltip is clicked
    if (showPopper && isTooltipClicked && isAnimationEnd) {
      popper?.focus();
      // to cancel the transtion when tooltip closes
      popper?.classList.add(style.tooltipEntered);
    }
  }, [showPopper, isTooltipClicked, popper, isAnimationEnd]);

  const showPopperTooltip = () => setShowPopper(true);
  const hidePopperTooltip = () => setShowPopper(false || isTooltipClicked);

  const tooltipButtonOnClick: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    setShowPopper(!showPopper);
    setIsTooltipClicked(!isTooltipClicked);
    setIsAnimationEnd(false);
  };

  const tooltipButtonOnMouseLeave: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    if (!isMobile) {
      setShowPopper(false);
    }
    // on mouse leave reset isTooltipClicked to false
    setIsTooltipClicked(false);
  };

  const tooltipButtonOnMouseEnter: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    if (!isMobile) {
      showPopperTooltip();
    }
  };

  const closeTooltip = () => {
    setShowPopper(false);
    setIsTooltipClicked(false);
    tooltipButtonRef?.current?.focus();
  };

  const tooltipOnKeyUp: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      closeTooltip();
    }
  };

  const onSettingsClickedWithFocus = () => {
    setIsTooltipLinkClicked(true);
    onSettingsClick();
  };

  return (
    <>
      {poppersWrapper &&
        createPortal(
          <div
            id={`${compId}-tooltip`}
            data-hook={`${compId}-tooltip`}
            ref={setPopperTargetElem}
            style={popperStyles.popper}
            className={classNames(style.tooltip, {
              [style.showTooltip]: showPopper,
            })}
            tabIndex={-1}
            role="tooltip"
            aria-labelledby={`${compId}-tooltip-text`}
            onMouseEnter={showPopperTooltip}
            onMouseLeave={hidePopperTooltip}
            onKeyUp={tooltipOnKeyUp}
            onAnimationEnd={() => {
              setIsAnimationEnd(true);
            }}
            onTransitionEnd={event => {
              if (
                window.getComputedStyle(event.currentTarget).opacity !== '1' &&
                !isMobile
              ) {
                // when keyboard focus-within is removed we move the focus back to the button
                setShowPopper(false);
                setIsTooltipClicked(false);
                isTooltipLinkClicked
                  ? setIsTooltipLinkClicked(false)
                  : tooltipButtonRef?.current?.focus();
              }
            }}
            {...attributes.popper}
          >
            <div className={style.animationBox}>
              <div
                id={`${compId}-tooltip-text`}
                className={style.tooltipTextBox}
              >
                <ConsentText
                  text={text}
                  textButtonClass={style.tooltipTextButton}
                  onSettingsClick={onSettingsClickedWithFocus}
                  onPrivacyPolicyClick={onPrivacyPolicyClick}
                />
              </div>
              <div
                ref={setArrowElement}
                className={style.arrow}
                style={popperStyles.arrow}
              />
            </div>
          </div>,
          poppersWrapper,
        )}
      <button
        id={`${compId}-button`}
        className={style.tooltipButton}
        onMouseEnter={tooltipButtonOnMouseEnter}
        onMouseLeave={tooltipButtonOnMouseLeave}
        onBlur={hidePopperTooltip}
        onClick={tooltipButtonOnClick}
        aria-label={ariaLabelText}
        aria-expanded={showPopper}
        ref={tooltipButtonRef}
      >
        <div
          data-hook={`${compId}-tooltip-button`}
          className={style.tooltipIconWrapper}
          ref={setPopperSourceEl}
        >
          <CookieIcon></CookieIcon>
        </div>
      </button>
    </>
  );
};
