import { useState, useCallback } from 'react';
import { useSiteStylesObserver } from '@wix/thunderbolt-elements/providers/useSiteStylesObserver';
import { MinSize, MenuMode } from '../../StylableHorizontalMenu.types';

const parseStyle =
  (styles: CSSStyleDeclaration) =>
  (key: string): number =>
    Number.parseInt(styles.getPropertyValue(key), 10) || 0;

// paddings and borders define min size that element should have
const getElMinSize = (
  getVal: (key: string) => number,
  size: 'width' | 'height',
) => {
  const isVertical = size === 'height';
  const direction1 = isVertical ? 'top' : 'left';
  const direction2 = isVertical ? 'bottom' : 'right';

  return (
    getVal(`padding-${direction1}`) +
    getVal(`padding-${direction2}`) +
    getVal(`border-${direction1}-width`) +
    getVal(`border-${direction2}-width`)
  );
};

const getNodeMinSize = (node: Element) => {
  const getValue = parseStyle(window.getComputedStyle(node));
  return [getElMinSize(getValue, 'width'), getElMinSize(getValue, 'height')];
};

type UseMinSizeProps = {
  id: string;
  menuMode: MenuMode;
};

const wrapMinSize = { minWidth: 'min-content' };

export const useMinSize = ({ id, menuMode }: UseMinSizeProps): MinSize => {
  const [scrollMinSize, setScrollMinSize] = useState<MinSize>({});
  const isScroll = menuMode === 'scroll';

  useSiteStylesObserver({
    shouldObserve: isScroll,
    callback: useCallback(() => {
      const nav = document.querySelector(`#${id} nav`);
      if (nav) {
        const scrollBtn = nav.querySelector('[aria-label="scroll"]')!;
        const icon = scrollBtn.firstChild as HTMLElement;
        const [minBtnWidth, minBtnHeight] = getNodeMinSize(scrollBtn);
        const [minNavWidth, minNavHeight] = getNodeMinSize(nav);
        setScrollMinSize({
          minWidth: `${minNavWidth + 2 * (minBtnWidth + icon.offsetWidth)}px`,
          minHeight: `${minNavHeight + minBtnHeight + icon.offsetHeight}px`,
        });
      }
    }, [id]),
  });

  return isScroll ? scrollMinSize : wrapMinSize;
};
