import { withCompController } from '@wix/editor-elements-integrations';
import { PagesToShare } from '../constants';
import {
  IShareButtonsMapperProps,
  IShareButtonsStateRefs,
  IShareButtonsViewerProps,
} from '../types';

export default withCompController<
  IShareButtonsMapperProps,
  IShareButtonsMapperProps,
  IShareButtonsViewerProps,
  IShareButtonsStateRefs
>(({ mapperProps, stateValues }) => {
  const { currentUrlWithQueryParams } = stateValues;
  const { pageToShare, externalBaseUrl } = mapperProps;

  const shareUrlByPage: { [key: string]: string } = {
    [PagesToShare.home]: externalBaseUrl,
    [PagesToShare.current]: currentUrlWithQueryParams,
  };
  const isCustomUrl = !shareUrlByPage[pageToShare];
  const shareUrl = isCustomUrl ? pageToShare : shareUrlByPage[pageToShare];

  return {
    ...mapperProps,
    shareUrl,
  };
});
