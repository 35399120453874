import { ILinkTarget } from '../SignUpDialog.types';

export const SignUpDialogTranslationKeys = {
  title: 'membersLoginDialogSwitchDialogLink',
  submitButton: 'membersLoginDialogSwitchDialogLink',
  switchDialogLink: 'membersSignupSwitchDialogLink',
  mobileForgotPassword: 'membersLoginDialogMobileForgotPassword',
  switchToSignIn: 'membersSignupSwitchToSignIn',
  socialSectionDivider: 'membersSignupSocialSectionDivider',
  emailSectionDivider: 'membersLoginDialogEmailSectionDivider',
  copyLinkText: 'membersLoginDialogCopyLinkText',
  linkCopied: 'membersLoginDialogLinkCopied',
  password: {
    title: 'membersLoginDialogPasswordFieldTitle',
    placeholder: 'membersLoginDialogPasswordFieldLabel',
  },
  email: {
    title: 'membersLoginDialogEmailField',
    placeholder: 'membersLoginDialogEmailField',
  },
  social: {
    iframeTitle: 'membersLoginDialogSocialLoginTitle',
    google: 'membersLoginDialogLoginWithGoogle',
    facebook: 'membersLoginDialogLoginWithFacebook',
  },
  switchToAuthWithEmail: 'membersSignupSwitchToAuthWithEmail',
  community: {
    label: 'membersSignupCommunityLabel',
    read: {
      more: 'membersSignupCommunityReadMore',
      less: 'membersSignupCommunityReadLess',
    },
    content: 'membersSignupCommunityContent',
    codeOfConductLink: 'membersSignupCommunityCodeOfConductLink',
  },
  policies: {
    content: 'membersSignupPoliciesContent',
    termsOfUseLink: 'membersPoliciesTermsOfUseLink',
    privacyLink: 'membersPoliciesPrivacyLink',
    and: 'membersPoliciesAnd',
  },
};

export const testIds = {
  iframe: 'socialAuthIframe',
  headline: 'signUp.headline',
  copyLinkButton: 'copyLinkButton',
  switchToSignUp: {
    title: 'signUp.switchToSignUp',
    description: 'signUp.switchToSignUpDescription',
  },
  switchToEmailLink: 'switchToEmailLink',
  submit: 'submit',
  community: {
    readMore: 'signUp.communityReadMore',
    label: 'signUp.communityLabel',
    content: 'signUp.communityContent',
    codeOfConductLink: 'signUp.codeOfConductLink',
  },
  policies: {
    content: 'signUp.privacyPolicyContent',
    privacyPolicyLink: 'signUp.privacyPolicyLink',
    and: 'signUp.policiesAnd',
    termsOfUseLink: 'signUp.termsOfUseLink',
  },
  socialAuthMessage: 'socialAuthMessage',
  signUpSocialAuth: 'signUpSocialAuth',
  signUpEmailAuth: 'signUpEmailAuth',
  googleMain: 'googlenew',
  googleSmall: 'googlenew-small',
  facebookMain: 'fbnew',
  facebookSmall: 'fbnew-small',
  ssoBtn: 'ssoBtn',
};

export const closeDialog =
  (target: ILinkTarget, onCloseDialogCallback: () => void) => () => {
    if (target === '_self') {
      onCloseDialogCallback();
    }
  };
