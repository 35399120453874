export const HELP_GFPP_ID = 'c2d2160a-9851-4bd4-96f1-e00e4ab40ba0';
export const SETTINGS_PANEL_HELP_ID = 'c0b9ce8f-e4ed-4852-a2f0-62f382559188';
export const defaultLogoUrl =
  'https://static.parastorage.com/services/santa-resources/dist/editor/addPanelData/sections/logo/logoDefault.v2.png';

export const logoServer =
  'https://editor.wix.com/logoapi/logo-builder-server-webapp';
export const logoServerless =
  'https://editor.wix.com/_serverless/brand-maker/api/v1';

export const LOGO_PREVIEWS = 'LOGO_PREVIEWS';
