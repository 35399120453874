/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useMemo } from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import { IAccordionContainerProps } from '../types';
import semanticClassNames from '../AccordionContainer.semanticClassNames';
import { populateWithSlot } from './utils/populateWithSlot';
import { AccordionAnimated } from './components/AccordionAnimated';
import { AccordionSimple } from './components/AccordionSimple';
import { st, classes } from './AccordionContainer.component.st.css';

export const AccordionContainer: React.FC<IAccordionContainerProps> = ({
  id,
  icon,
  items: rawItems,
  slots,
  multiple,
  opened,
  animated,
  onOpenedChange,
  onItemClicked,
  onClick,
  onDblClick,
  onFocus,
  onBlur,
  stylableClassName,
  containerRootClassName,
  customClassNames = [],
}) => {
  const toggleItem = (itemId: string) => {
    const { label } = rawItems.find(i => i.id === itemId)!;
    onItemClicked?.({ id: itemId, label });
    if (opened.includes(itemId)) {
      onOpenedChange(opened.filter(i => i !== itemId));
      return;
    }
    onOpenedChange(multiple ? [...opened, itemId] : [itemId]);
  };

  const items = useMemo(
    () => populateWithSlot(rawItems, slots),
    [rawItems, slots],
  );

  const Accordion = animated ? AccordionAnimated : AccordionSimple;

  return (
    <div
      className={containerRootClassName}
      id={id}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <ul
        className={st(
          classes.root,
          stylableClassName,
          customCssClasses(semanticClassNames.root, ...customClassNames),
        )}
        role="list"
      >
        {items.map(item => (
          <Accordion
            key={item.id}
            item={item}
            toggleItem={toggleItem}
            isOpened={opened.includes(item.id)}
            icon={icon}
          />
        ))}
      </ul>
    </div>
  );
};

export default AccordionContainer;
