import { ComponentType, MouseEventHandler } from 'react';
import type { CorvidTypes } from '@wix/editor-elements-types/corvid';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type { IExternalComponentProps as ExternalComponentDataSchema } from './ExternalComponent.props';

export type IExternalComponentProps = DefaultCompPlatformProps &
  Pick<ExternalComponentDataSchema, 'importKey' | 'url'> & {
    id: string;
    ssrMarkup: string;
    compProps: object;
    // this part for the sdk
    handleEvents: Function;
    events: Array<Array<string>>;
    Component: ComponentType<any>;
    error?: Error;
    ssrContent?: string;
    translations: Record<string, string | undefined>;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
  };

export interface IExternalComponentSDK {
  props: Record<string, any>;
}

export type IExternalComponentSDKFactory = CorvidTypes.CorvidSDKFactory<
  IExternalComponentProps,
  IExternalComponentSDK
>;

// TODO consolidate with the thunderbolt enum
export enum ExternalComponentErrorType {
  Runtime = 'ExternalComponentRuntimeError',
  WrongExport = 'ExternalComponentWrongExportError',
  MissingExport = 'ExternalComponentMissingExportError',
  Timeout = 'ExternalComponentTimeoutError',
  Fetch = 'ExternalComponentFetchError',
  Bundling = 'ExternalComponentBundlingError',
  FileNotFound = 'ExternalComponentFileNotFoundError',
  UnsupportedBrowser = 'ExternalComponentUnsupportedBrowser',
  UserOfUserDisplay = 'UserOfUserDisplay',
  MissingConsent = 'ExternalComponentMissingConsent',
  Reloading = 'ExternalComponentReloadingError',
}
