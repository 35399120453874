import * as React from 'react';
import { IMusicPlayerProps } from '../MusicPlayer.types';

import iconPlay0 from './assets/iconPlay0.svg';
import iconPlay1 from './assets/iconPlay1.svg';
import iconPlay2 from './assets/iconPlay2.svg';
import iconPlay3 from './assets/iconPlay3.svg';
import iconPlay4 from './assets/iconPlay4.svg';
import iconPlay5 from './assets/iconPlay5.svg';
import iconPlay6 from './assets/iconPlay6.svg';
import iconPlay7 from './assets/iconPlay7.svg';
import iconPlay8 from './assets/iconPlay8.svg';
import iconPlay9 from './assets/iconPlay9.svg';
import iconPlay10 from './assets/iconPlay10.svg';
import iconPlay11 from './assets/iconPlay11.svg';
import iconPlay12 from './assets/iconPlay12.svg';
import iconPlay13 from './assets/iconPlay13.svg';
import iconPlay14 from './assets/iconPlay14.svg';
import iconPlay15 from './assets/iconPlay15.svg';
import iconPlay16 from './assets/iconPlay16.svg';
import iconPlay17 from './assets/iconPlay17.svg';
import iconPlay18 from './assets/iconPlay18.svg';
import iconPlay19 from './assets/iconPlay19.svg';
import iconPlay20 from './assets/iconPlay20.svg';
import iconPlay21 from './assets/iconPlay21.svg';
import iconPlay22 from './assets/iconPlay22.svg';
import iconPlay23 from './assets/iconPlay23.svg';
import iconPause0 from './assets/iconPause0.svg';
import iconPause1 from './assets/iconPause1.svg';
import iconPause2 from './assets/iconPause2.svg';
import iconPause3 from './assets/iconPause3.svg';
import iconPause4 from './assets/iconPause4.svg';
import iconPause5 from './assets/iconPause5.svg';
import iconPause6 from './assets/iconPause6.svg';
import iconPause7 from './assets/iconPause7.svg';
import iconPause8 from './assets/iconPause8.svg';
import iconPause9 from './assets/iconPause9.svg';
import iconPause10 from './assets/iconPause10.svg';
import iconPause11 from './assets/iconPause11.svg';
import iconPause12 from './assets/iconPause12.svg';
import iconPause13 from './assets/iconPause13.svg';
import iconPause14 from './assets/iconPause14.svg';
import iconPause15 from './assets/iconPause15.svg';
import iconPause16 from './assets/iconPause16.svg';
import iconPause17 from './assets/iconPause17.svg';
import iconPause18 from './assets/iconPause18.svg';
import iconPause19 from './assets/iconPause19.svg';
import iconPause20 from './assets/iconPause20.svg';
import iconPause21 from './assets/iconPause21.svg';
import iconPause22 from './assets/iconPause22.svg';
import iconPause23 from './assets/iconPause23.svg';

const icons = {
  icon0: { play: iconPlay0, pause: iconPause0 },
  icon1: { play: iconPlay1, pause: iconPause1 },
  icon2: { play: iconPlay2, pause: iconPause2 },
  icon3: { play: iconPlay3, pause: iconPause3 },
  icon4: { play: iconPlay4, pause: iconPause4 },
  icon5: { play: iconPlay5, pause: iconPause5 },
  icon6: { play: iconPlay6, pause: iconPause6 },
  icon7: { play: iconPlay7, pause: iconPause7 },
  icon8: { play: iconPlay8, pause: iconPause8 },
  icon9: { play: iconPlay9, pause: iconPause9 },
  icon10: { play: iconPlay10, pause: iconPause10 },
  icon11: { play: iconPlay11, pause: iconPause11 },
  icon12: { play: iconPlay12, pause: iconPause12 },
  icon13: { play: iconPlay13, pause: iconPause13 },
  icon14: { play: iconPlay14, pause: iconPause14 },
  icon15: { play: iconPlay15, pause: iconPause15 },
  icon16: { play: iconPlay16, pause: iconPause16 },
  icon17: { play: iconPlay17, pause: iconPause17 },
  icon18: { play: iconPlay18, pause: iconPause18 },
  icon19: { play: iconPlay19, pause: iconPause19 },
  icon20: { play: iconPlay20, pause: iconPause20 },
  icon21: { play: iconPlay21, pause: iconPause21 },
  icon22: { play: iconPlay22, pause: iconPause22 },
  icon23: { play: iconPlay23, pause: iconPause23 },
};

interface IIconProps {
  type: 'play' | 'pause';
  iconId: IMusicPlayerProps['iconId'];
  className: string;
}

const Icon: React.FC<IIconProps> = ({ type, iconId, className }) => {
  const SVG = icons[iconId][type];
  return <SVG className={className} />;
};

export default Icon;
