import React, { FC, useMemo } from 'react';
import Container from '@wix/thunderbolt-elements/components/Container';
import cn from 'clsx';

import { Plugin } from '@wix/wix-ui-icons-common/classic-editor';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISlotsPlaceholderProps } from '../SlotsPlaceholder.props';
import classes from './SlotsPlaceholder.scss';

const SlotsPlaceholder: FC<ISlotsPlaceholderProps> = props => {
  const {
    slots,
    isHighlighted,
    isWixBlocks,
    isPreviewMode,
    className,
    ...otherProps
  } = props;
  const slotContent = slots?.slot;
  const slotContentComponent = useMemo(() => () => slotContent, [slotContent]);

  const isEmptySlot = !slotContent;

  if (isEmptySlot && !isHighlighted && !isWixBlocks) {
    return null;
  }

  const isBlocksEditMode = isWixBlocks && !isPreviewMode;

  const containerProps = {
    ...otherProps,
    className: cn(
      {
        [classes.highlighted]: isHighlighted,
        [classes.withContent]: !isEmptySlot && !isWixBlocks,
        [classes.empty]: isEmptySlot && isBlocksEditMode,
      },
      className,
    ),
  };

  if (isEmptySlot && isBlocksEditMode) {
    return (
      <Container {...getDataAttributes(props)} {...containerProps}>
        {() => <Plugin id="empty-slots-placeholder" />}
      </Container>
    );
  }

  return (
    <Container {...getDataAttributes(props)} {...containerProps}>
      {slotContentComponent}
    </Container>
  );
};

export default SlotsPlaceholder;
