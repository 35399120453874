import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { StageModes } from '@wix/editor-elements-preview-utils/src/panels/store/getters';
import {
  activeFocusRingStyle,
  cssVariables,
  defaultInputDisplay,
  InputContent,
} from '../constants';
import {
  ISelectableContainerCarmiProps,
  ISelectableContainerDefinition,
  ISelectableContainerMapperProps,
} from '../types';

const convertIsInputVisibleToBoolean = (isInputVisible?: string | boolean) => {
  if (typeof isInputVisible !== 'undefined') {
    return typeof isInputVisible === 'string' && Boolean(isInputVisible)
      ? JSON.parse(isInputVisible)
      : isInputVisible;
  }

  return true;
};

export const props = withCompInfo<
  ISelectableContainerMapperProps,
  ISelectableContainerDefinition,
  ISelectableContainerCarmiProps
>()(
  [
    'compData',
    'compStylableClass',
    'compMeshProps',
    'hasResponsiveLayout',
    'compVariants',
    'styleProperties',
  ],
  (
    {
      compData,
      compStylableClass,
      compMeshProps,
      hasResponsiveLayout,
      compVariants = {},
      styleProperties,
    },
    carmiData,
  ) => {
    const selectedName = Object.values(compVariants).find(
      compVariant => compVariant.type === StageModes.Selected,
    )?.name;
    const isInputVisible = convertIsInputVisibleToBoolean(
      styleProperties?.isInputVisible,
    );

    return {
      ...carmiData,
      ...compData,
      selectedVariantName: selectedName,
      stylableClassName: compStylableClass,
      meshProps: compMeshProps,
      hasResponsiveLayout,
      isInputVisible,
    } as ISelectableContainerMapperProps;
  },
);

const css = withCompInfo<any, ISelectableContainerDefinition>()(
  ['styleProperties'],
  ({ styleProperties }, carmiData) => {
    const isInputVisible = convertIsInputVisibleToBoolean(
      styleProperties?.isInputVisible,
    );

    const isTextOnlyInput =
      carmiData && carmiData['--input-content'] === InputContent.Text;

    if (isInputVisible) {
      return {
        [cssVariables.inputDisplay]: defaultInputDisplay,
        [cssVariables.containerFocusRing]: isTextOnlyInput
          ? activeFocusRingStyle
          : 'none',
      };
    }

    return {
      [cssVariables.inputDisplay]: 'none',
      [cssVariables.containerFocusRing]: activeFocusRingStyle,
    };
  },
);

export const stateRefs = withStateRefsValues([
  'applyEffects',
  'removeEffects',
  'editorType',
]);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
