import * as React from 'react';
import classNames from 'clsx';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import { MenuButtonProps } from '../../../MenuButton.types';
import skinsStyle from './styles/skins.scss';

const ArrowRightMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  const {
    link,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    dir,
    textAlign,
    id,
    positionInList,
    isMoreButton,
  } = props;
  return (
    <MenuButtonRoot
      {...props}
      className={classNames(
        props.className,
        skinsStyle.ArrowRightMenuButtonNSkin,
      )}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton ? ariaHasPopup : 'false',
            ariaDescribedBy,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.arrow}>
            <div className={skinsStyle.tail}>
              <div className={skinsStyle.tailTop} />
              <div className={skinsStyle.tailBottom} />
            </div>
            <div
              className={classNames(skinsStyle.sq, skinsStyle.dropSizeTarget)}
            />
            <div
              className={classNames(skinsStyle.head, skinsStyle.dropSizeTarget)}
            >
              <div className={skinsStyle.headTop} />
              <div className={skinsStyle.headBottom} />
            </div>
          </div>
          <MenuButtonBackground
            wrapperProps={{ dir, textAlign, id }}
            classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
          >
            {label}
          </MenuButtonBackground>
          <div className={skinsStyle.hitArea} />
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default ArrowRightMenuButtonNSkin;
