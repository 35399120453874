import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  migrateFields,
  isExperimentOpen,
  renderAllTabsExperiment,
} from '@wix/editor-elements-common-utils';
import type { Direction } from '@wix/editor-elements-types/thunderbolt';
import { contentAlignmentMap } from '../constants';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import {
  ITabsCarmiData,
  ITabsMapperProps,
  TabsAlignment,
  TabsMenuMode,
  ITabsProperties,
} from '../Tabs.types';
import { toBoolean } from '../utils';

export const props = withCompInfo<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'hasResponsiveLayout',
    'styleProperties',
    'experiments',
  ],
  (
    {
      compData,
      compProps,
      compStylableClass,
      hasResponsiveLayout,
      experiments,
    },
    carmiData,
  ) => {
    const { tabItems, containerProps, containerRootClassName } = carmiData;
    const { defaultTabId } = compData;

    return {
      ...compData,
      ...compProps,
      stylableClassName: compStylableClass,
      tabItems,
      currentTabId: defaultTabId,
      hasResponsiveLayout,
      containerProps,
      containerRootClassName,
      shouldRenderAllTabs: isExperimentOpen(
        experiments,
        renderAllTabsExperiment,
      ),
    };
  },
);

const toPxValue = (val: string | number) => `${val}px`;
type MarginCSSValue = number | 'auto';
type JustifyContentValue = 'flex-start' | 'flex-end' | 'normal' | 'center';

type FlexCssVars = {
  '--first-child-margin-left': MarginCSSValue;
  '--first-child-margin-right': MarginCSSValue;
  '--last-child-margin-left': MarginCSSValue;
  '--last-child-margin-right': MarginCSSValue;
};
type MenuModeCSSVars = {
  '--tabs-list-justify-content':
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'normal';
  '--tabs-list-overflow-x': 'visible' | 'auto';
  '--tabs-list-flex-wrap': 'wrap' | 'nowrap';
  '--tabs-list-scroll-buttons-display': 'flex' | 'none';
};
const getMenuModeCSSVars = (
  menuMode: TabsMenuMode,
  itemsAlignment: TabsAlignment,
  itemsDirection: Direction,
): MenuModeCSSVars => {
  if (menuMode === 'scroll') {
    return {
      '--tabs-list-overflow-x': 'auto',
      '--tabs-list-flex-wrap': 'nowrap',
      '--tabs-list-justify-content': 'normal',
      '--tabs-list-scroll-buttons-display': 'flex',
    };
  } else {
    let justifyContent: JustifyContentValue = 'normal';
    if (itemsAlignment === 'center') {
      justifyContent = 'center';
    } else if (itemsAlignment === 'left') {
      justifyContent = itemsDirection === 'ltr' ? 'flex-start' : 'flex-end';
    } else {
      justifyContent = itemsDirection === 'rtl' ? 'flex-start' : 'flex-end';
    }
    return {
      '--tabs-list-overflow-x': 'visible',
      '--tabs-list-flex-wrap': 'wrap',
      '--tabs-list-justify-content': justifyContent,
      '--tabs-list-scroll-buttons-display': 'none',
    };
  }
};
const getTabsListAlignmentCssVars = (
  menuMode: TabsMenuMode,
  itemsAlignment: TabsAlignment,
  itemsDirection: Direction,
): FlexCssVars => {
  let firstChildMarginLeft: MarginCSSValue = 0;
  let firstChildMarginRight: MarginCSSValue = 0;
  let lastChildMarginLeft: MarginCSSValue = 0;
  let lastChildMarginRight: MarginCSSValue = 0;

  if (menuMode === 'scroll') {
    if (itemsDirection === 'ltr') {
      if (itemsAlignment === 'center') {
        firstChildMarginLeft = 'auto';
        lastChildMarginRight = 'auto';
      } else if (itemsAlignment === 'right') {
        firstChildMarginLeft = 'auto';
      }
    } else {
      if (itemsAlignment === 'center') {
        firstChildMarginRight = 'auto';
        lastChildMarginLeft = 'auto';
      } else if (itemsAlignment === 'right') {
        lastChildMarginLeft = 'auto';
      } else {
        firstChildMarginRight = 'auto';
      }
    }
  }
  return {
    '--first-child-margin-left': firstChildMarginLeft,
    '--first-child-margin-right': firstChildMarginRight,
    '--last-child-margin-left': lastChildMarginLeft,
    '--last-child-margin-right': lastChildMarginRight,
  };
};

const getTabsListDirectionCSSVars = (itemsDirection: Direction) => {
  const tabsListBackButtonLeft = itemsDirection === 'ltr' ? 0 : 'auto';
  const tabsListBackButtonRight = itemsDirection === 'ltr' ? 'auto' : 0;
  const tabsListForwardButtonLeft = itemsDirection === 'ltr' ? 'auto' : 0;
  const tabsListForwardButtonRight = itemsDirection === 'ltr' ? 0 : 'auto';
  const tabsListScrollButtonsTransform =
    itemsDirection === 'ltr' ? 'scaleX(1)' : 'scaleX(-1)'; // Flip buttons in rtl

  return {
    '--tabs-list-direction': itemsDirection,
    '--tabs-list-back-button-left': tabsListBackButtonLeft,
    '--tabs-list-back-button-right': tabsListBackButtonRight,
    '--tabs-list-forward-button-left': tabsListForwardButtonLeft,
    '--tabs-list-forward-button-right': tabsListForwardButtonRight,
    '--tabs-list-scroll-buttons-transform': tabsListScrollButtonsTransform,
  };
};

export const css = withCompInfo<any, ITabsDefinition>()(
  ['compProps', 'hasResponsiveLayout', 'styleProperties'],
  ({ styleProperties, hasResponsiveLayout }) => {
    const {
      menuMode,
      itemsDirection = 'ltr',
      itemsAlignment,
      containerSpacing,
      itemSpacing,
      contentAlignment,
      horizontalTabPadding,
      verticalTabPadding,
      stretchTabsToMenuWidth,
      itemRowSpacing,
    } = styleProperties as Record<
      string,
      string | number | boolean
    > as ITabsProperties;

    const responsiveContainerVars = {
      '--rd': '0',
      '--brw': '0',
      '--shd': 'none',
      '--bg': 'transparent',
    };

    const tabsListAlignmentCSSVars = getTabsListAlignmentCssVars(
      menuMode,
      itemsAlignment,
      itemsDirection,
    );

    const tabsListLayoutCSSVars = {
      '--tabs-list-items-gap': toPxValue(itemSpacing),
      '--tabs-list-container-gap': toPxValue(containerSpacing),
      '--tabs-list-content-alignment':
        contentAlignmentMap[itemsDirection][contentAlignment],
      '--tabs-list-horizontal-padding': toPxValue(horizontalTabPadding),
      '--tabs-list-vertical-padding': toPxValue(verticalTabPadding),
      '--tabs-list-item-flex-grow': toBoolean(stretchTabsToMenuWidth)
        ? '1'
        : '0',
      '--tabs-list-wrap-row-gap': toPxValue(itemRowSpacing),
      '--tabs-list-direction': itemsDirection,
    };
    const menuModeCSSVars = getMenuModeCSSVars(
      menuMode,
      itemsAlignment,
      itemsDirection,
    );

    return {
      height: 'auto',
      ...(hasResponsiveLayout ? responsiveContainerVars : {}),
      ...tabsListLayoutCSSVars,
      ...tabsListAlignmentCSSVars,
      ...getTabsListDirectionCSSVars(itemsDirection),
      ...menuModeCSSVars,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [
          'menuMode',
          'itemsDirection',
          'itemsAlignment',
          'containerSpacing',
          'itemSpacing',
          'contentAlignment',
          'horizontalTabPadding',
          'verticalTabPadding',
          'stretchTabsToMenuWidth',
          'itemRowSpacing',
        ],
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
