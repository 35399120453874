import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import classNames from 'clsx';
import semanticClassNames from '../HamburgerMenuRoot.semanticClassNames';
import { HamburgerMenuContextProvider } from '../../HamburgerMenuContext';
import type { IHamburgerMenuRootProps } from '../HamburgerMenuRoot.props';

const HamburgerMenuRoot: React.FC<IHamburgerMenuRootProps> = props => {
  const {
    id,
    customClassNames = [],
    children,
    compPreviewState,
    meshProps,
    containerProps,
    containerRootClassName,
    hasResponsiveLayout,
  } = props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  return (
    <div
      id={id}
      className={classNames(
        customCssClasses(semanticClassNames.root, ...customClassNames),
        containerRootClassName,
      )}
    >
      <HamburgerMenuContextProvider compPreviewState={compPreviewState}>
        {hasResponsiveLayout ? (
          <ResponsiveContainer {...containerProps}>
            {childrenToRender}
          </ResponsiveContainer>
        ) : (
          <MeshContainer id={id} {...meshProps}>
            {childrenToRender}
          </MeshContainer>
        )}
      </HamburgerMenuContextProvider>
    </div>
  );
};

export default HamburgerMenuRoot;
