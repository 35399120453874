import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ILogoProps,
  ILogoMapperProps,
  ILogoControllerProps,
  ILogoStateValues,
} from '../../../components/Logo/Logo.props';

export default withCompController<
  ILogoMapperProps,
  ILogoControllerProps,
  ILogoProps,
  ILogoStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
