import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  ComboBoxInputDefinition,
  IComboBoxInputMapperProps,
} from '../ComboBoxInput.types';
import { props as viewerPropsMapper } from './ComboBoxInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<IComboBoxInputMapperProps>,
  ComboBoxInputDefinition,
  IComboBoxInputMapperProps
>()(
  viewerPropsMapper,
  {
    add: [
      'shouldResetComponent',
      'compPreviewState',
      'componentViewMode',
      'activeVariant',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      activeVariant,
    } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        compPreviewState,
        componentViewMode,
      },
      portalClassName: activeVariant || '',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
