import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getInputHeight,
  getLabelPaddingsValues,
  getRequiredIndicationDisplay,
  migrateFields,
  addUnitToEveryField,
  convertPhysicalInputAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  PhysicalAlignment,
  LogicalAlignment,
  Direction,
  WithInherit,
} from '@wix/editor-elements-common-utils';
import {
  ITextAreaInputMapperProps,
  TextAreaInputDefinition,
  TextAreaInputCSSVars,
  TextAreaInputCssCarmiData,
} from '../TextAreaInput.types';
import { translationKeys } from './constants';

export const props = withCompInfo<
  ITextAreaInputMapperProps,
  TextAreaInputDefinition
>()(
  ['compData', 'compProps', 'skin', 'translate'],
  ({ compData, compProps, skin, translate }) => ({
    skin:
      skin === 'ResponsiveTextAreaDefaultSkin'
        ? 'ResponsiveTextAreaDefaultSkin'
        : 'TextAreaDefaultSkin',
    value: compData.value || '',
    label: compData.label,
    placeholder: compData.placeholder || compProps.placeholder,
    readOnly: compProps.readOnly || false,
    required: compProps.required || false,
    isDisabled: compProps.isDisabled || false,
    maxLength: compData.maxLength,
    errorMessageType: compProps.errorMessageType,
    translations: {
      errorMessage: translate(
        translationKeys.NAMESPACE,
        translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
      ),
    },
  }),
);

export const css = withCompInfo<
  TextAreaInputCSSVars,
  TextAreaInputDefinition,
  TextAreaInputCssCarmiData
>()(
  [
    'compLayout',
    'compSingleLayout',
    'compProps',
    'compData',
    'experiments',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  (
    {
      compLayout,
      compSingleLayout,
      compProps,
      compData,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
    },
    cssVars,
  ) => {
    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      errorDirection = 'inherit',
    } = compData;
    const {
      align,
      labelMargin,
      labelPadding,
      textPadding = 3,
    } = styleProperties;

    const sharedProps = {
      ...cssVars,
      '--direction': direction as WithInherit<Direction>,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--errorDirection': errorDirection,
      '--align': align as LogicalAlignment,
      '--textPaddingTop': hasResponsiveLayout ? '0.75em' : '3px',
      '--textPaddingStart': `${textPadding}px`,
      '--textPaddingEnd': align === 'center' ? `${textPadding}px` : '10px',
      '--labelMarginBottom': `${labelMargin}px`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      ...addUnitToEveryField(
        getLabelPaddingsValues({
          labelPadding,
          align: align as LogicalAlignment,
        }),
      ),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeight({
          inputHeightProps: compProps,
          compLayout,
          compSingleLayout,
          isMobileView,
        }),
      };
    }
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: ['labelMargin', 'labelPadding', 'textPadding', 'textAlignment'],
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: textAlignment =>
          convertPhysicalInputAlignmentToLogical(
            textAlignment as PhysicalAlignment,
          ),
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'direction' }],
        enhancer: textAlignment =>
          convertPhysicalInputAlignmentToDirection(
            textAlignment as PhysicalAlignment,
          ),
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
