import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getHeightCSS } from '@wix/editor-elements-common-utils';
import { getLanguage } from '../common/utils';
import {
  WFacebookCommentDefinition,
  WFacebookCommentStateRefs,
  WFacebookCommentMapperProps,
} from '../WFacebookComment.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_WFacebookComment',
  componentNameDefault: 'Facebook Comments',
};

export const props = withCompInfo<
  WFacebookCommentMapperProps,
  WFacebookCommentDefinition
>()(
  [
    'compData',
    'compProps',
    'isMobileView',
    'language',
    'externalBaseUrl',
    'uiType',
    'deviceType',
    'translate',
  ],
  ({
    compData: { urlFormat, isHttpsEnabled },
    compProps,
    isMobileView,
    language,
    externalBaseUrl,
    uiType,
    deviceType,
    translate,
  }) => ({
    ...compProps,
    width: isMobileView ? 320 : compProps.width,
    language: getLanguage(language),
    urlFormat,
    externalBaseUrl,
    isHttpsEnabled,
    isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
    isMobile: isMobile(deviceType),
    translations: {
      ...translateConsentWrapperKeys(
        consetPolicyKeys.componentNameKey,
        consetPolicyKeys.componentNameDefault,
        translate,
      ),
    },
  }),
);

export const css = withCompInfo<any, WFacebookCommentDefinition>()(
  ['hasResponsiveLayout', 'compLayout', 'compSingleLayout'],
  ({ hasResponsiveLayout, compLayout, compSingleLayout }) => {
    const heightCSS = getHeightCSS(compLayout, compSingleLayout);
    return hasResponsiveLayout
      ? undefined
      : {
          height: 'auto',
          'min-height': heightCSS,
        };
  },
);

export const stateRefs = withStateRefsValues<keyof WFacebookCommentStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
