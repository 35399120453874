import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import { IHamburgerMenuRootProps } from '../HamburgerMenuRoot.props';
import { IHamburgerMenuRootDefinition } from '../documentManagement/HamburgerMenuRoot.definition';

export type IHamburgerMenuRootCarmiProps = {
  containerProps?: ResponsiveContainerProps;
  containerRootClassName?: string;
};

export const props = withCompInfo<
  IHamburgerMenuRootProps,
  IHamburgerMenuRootDefinition,
  IHamburgerMenuRootCarmiProps
>()(
  ['compData', 'hasResponsiveLayout', 'compMeshProps'],
  ({ compData, hasResponsiveLayout }, carmiData) => {
    return {
      ...{ label: 'label' },
      ...compData,
      ...carmiData,
      hasResponsiveLayout,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
