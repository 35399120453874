import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { ContainerDefinition } from '@wix/thunderbolt-components';
import { IContainerProps } from '../Container.types';
import { ARIA_LABEL_KEY, ARIA_LABEL_NAMESPACE } from './shared/constants';

export const props = withCompInfo<
  IContainerProps,
  ContainerDefinition,
  IContainerProps
>()(['translate'], ({ translate }, carmiProps) => {
  return {
    ...carmiProps,
    translations: {
      ariaLabel: translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY),
    },
  };
});

export default createComponentMapperModel({ props });
