import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getHeightCSS, getWidthCSS } from '@wix/editor-elements-common-utils';
import {
  VideoPlayerDefinition,
  IVideoPlayerMapperProps,
  VideoPlayerData,
} from '../VideoPlayer.types';
import {
  getConsentPolicyStateRefs,
  shouldShowConsentPolicyWrapper,
  consentPolicyUiTypeMapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { componentNameKey, componentNameDefault } from '../constants';
import { getPlayerName } from './utils';

export const props = withCompInfo<
  IVideoPlayerMapperProps,
  VideoPlayerDefinition
>()(
  [
    'compData',
    'compProps',
    'isMobileView',
    'compLayout',
    'compSingleLayout',
    'uiType',
    'deviceType',
    'translate',
  ],
  ({
    compData,
    compProps,
    isMobileView,
    compLayout,
    compSingleLayout,
    uiType,
    deviceType,
    translate,
  }) => {
    const widthCSS = getWidthCSS(compLayout, compSingleLayout);
    const heightCSS = getHeightCSS(compLayout, compSingleLayout);
    return {
      src: getVideoUrl(compData) || '',
      animatePoster: compProps.animatePoster,
      loop: compProps.loop,
      controls: compProps.controlsVisibility === 'hover',
      showTitle: compProps.showVideoTitle,
      playableConfig: {
        title: compData.videoTitle || '',
        description: compData.description || '',
        poster: compData.videoRef?.posterImageRef,
      },
      muted: compProps.autoplay,
      autoplay: compProps.autoplay,
      isMobileView,
      containerDimensions: {
        width: widthCSS,
        height: heightCSS,
      },

      currentTime: 0,
      duration: compData.videoRef?.duration || 0,
      isPlaying: false,
      volume: compProps.autoplay ? 0 : 100,
      isMuted: compProps.autoplay,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          componentNameKey,
          componentNameDefault,
          translate,
        ),
      },
    };
  },
);

const getVideoUrl = (compData: VideoPlayerData) => {
  const { videoUrl } = compData;
  if (videoUrl) {
    return videoUrl;
  }

  const qualities = compData.videoRef?.qualities;

  if (!qualities || !qualities.length) {
    return;
  }

  type Quality = (typeof qualities)[0];

  const { url } = qualities.reduce((acc: Quality, next: Quality) =>
    acc.width! > next.width! ? acc : next,
  );

  return `https://video.wixstatic.com/${url}`; // TODO staticVideoUrl
};

export const stateRefs = withStateRefsValues([
  'reducedMotion',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = withCompInfo<any, any>()(
  ['experiments', 'isConsentPolicyActive', 'compData'],
  ({ experiments, isConsentPolicyActive, compData }) => {
    const src = getVideoUrl(compData) || '';
    const playerName = getPlayerName(src);
    const isPlayable = playerName === 'playable';

    return (
      (!isPlayable &&
        consentPolicyUiTypeMapper.resolver(
          { isConsentPolicyActive, experiments },
          undefined,
        )) ||
      undefined
    );
  },
);

export default createComponentMapperModel({ props, stateRefs, uiType });
