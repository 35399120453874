import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getHeightCSS,
  getLabelPadding,
} from '@wix/editor-elements-common-utils';
import {
  SelectionTagsListDefinition,
  ISelectionTagsListMapperProps,
  SelectionTagsListCSSVars,
  SelectionTagsListCssCarmiData,
  SelectionTagsListAlignmentProps,
} from '../SelectionTagsList.types';

export const props = withCompInfo<
  ISelectionTagsListMapperProps,
  SelectionTagsListDefinition
>()(['compData', 'compProps'], ({ compData, compProps }) => {
  const { isDisabled, required, label } = compProps;
  const { options, value } = compData;

  return { options, value, isDisabled, required, label };
});

export const css = withCompInfo<
  SelectionTagsListCSSVars,
  SelectionTagsListDefinition,
  SelectionTagsListCssCarmiData
>()(
  ['compProps', 'compLayout', 'compSingleLayout', 'hasResponsiveLayout'],
  (
    { compProps, compLayout, compSingleLayout, hasResponsiveLayout },
    carmiProps,
  ) => {
    const { direction, labelMargin } = compProps;

    let alignment: string = compProps.alignment;

    if (compProps.alignment === 'left') {
      if (direction === 'rtl') {
        alignment = 'flex-end';
      } else {
        alignment = 'flex-start';
      }
    } else if (compProps.alignment === 'right') {
      if (direction === 'rtl') {
        alignment = 'flex-start';
      } else {
        alignment = 'flex-end';
      }
    }

    const classicCSS = hasResponsiveLayout
      ? {}
      : {
          'min-height': getHeightCSS(compLayout, compSingleLayout) || 0,
        };

    return {
      height: 'auto',
      '--textAlign': compProps.alignment,
      '--labelDir': compProps.alignment === 'right' ? 'rtl' : 'ltr',
      '--direction': direction,
      '--alignment': alignment as SelectionTagsListAlignmentProps,
      '--fnt': carmiProps['--fnt'],
      '--labelPadding': getLabelPadding(compProps),
      '--labelMarginBottom': `${labelMargin}px`,
      ...classicCSS,
    };
  },
);

export default createComponentMapperModel({ props, css: css as any });
