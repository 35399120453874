import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetDefinition,
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
} from '../FlickrBadgeWidget.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

const props = withCompInfo<
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeWidgetDefinition
>()(
  ['compData', 'uiType', 'deviceType', 'translate'],
  ({ compData, uiType, deviceType, translate }) => {
    return {
      userId: compData.userId,
      tag: compData.tag,
      imageCount: `${compData.imageCount}`,
      whichImages: compData.whichImages,
      imageSize: compData.imageSize,
      layoutOrientation: compData.layoutOrientation,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof FlickrBadgeStateRefs>([
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
