
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RatingsInputcomponent2840320257",
      {"classes":{"root":"RatingsInputcomponent2840320257__root","icons":"RatingsInputcomponent2840320257__icons","labelsContainer":"RatingsInputcomponent2840320257__labelsContainer","label":"RatingsInputcomponent2840320257__label","title":"RatingsInputcomponent2840320257__title","a11yRoot":"RatingsInputcomponent2840320257__a11yRoot","ratingWrapper":"RatingsInputcomponent2840320257__ratingWrapper","limitWidth":"RatingsInputcomponent2840320257__limitWidth","fullWidth":"RatingsInputcomponent2840320257__fullWidth","a11yLabel":"RatingsInputcomponent2840320257__a11yLabel"},"vars":{"display":"--display"},"stVars":{"labelFont":"var(--labelFont, var(--font_8))","labelFontColor":"rgba(var(--labelFontColor, var(--color_15)), var(--alpha-labelFontColor, 1))","errorLabelFontColor":"rgba(var(--errorLabelFontColor, var(--color_17)), var(--alpha-errorLabelFontColor, 1))","inputFlexDirection":"var(--inputFlexDirection, column)","inputTextAlign":"var(--inputTextAlign, center)","inputAlignItems":"var(--inputAlignItems, center)","iconsOrder":"var(--iconsOrder, 2)","iconsMinWidth":"var(--iconsMinWidth, auto)","iconsMarginTop":"var(--iconsMarginTop, 0)","iconsMarginBottom":"var(--iconsMarginBottom, 0)","iconsMarginLeft":"var(--iconsMarginLeft, 0)","iconsMarginRight":"var(--iconsMarginRight, 0)","labelsContainerAlignItems":"var(--labelsContainerAlignItems, flex-end)","iconPaddingLeft":"var(--iconPaddingLeft, 0)","iconPaddingRight":"var(--iconPaddingRight, 0)","direction":"var(--direction, ltr)","a11yLabelFont":"var(--fntlbl, var(--font_8))","a11yLabelColor":"rgba(var(--txtlbl, var(--color_15)), var(--alpha-txtlbl, 1))","a11yLabelAsteriskColor":"rgba(var(--txtlblrq, var(--color_15)), var(--alpha-txtlblrq, 1))","a11yFlexDirection":"var(--a11yFlexDirection, column)","a11yLabelMargin":"var(--a11yLabelMargin, 0)","a11yLabelPadding":"var(--a11yLabelPadding, 0)","a11yLabelAlign":"var(--a11yLabelAlign, center)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  