import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { isHorizontallyDocked as horizontallyDocked } from '@wix/editor-elements-common-utils';
import {
  AnimationCSSVars,
  MenuCSSVars,
  SubsubmenuBoxCSSVars,
  MenuItemCSSVars,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuSdkData,
  StylableHorizontalMenuPropsCarmiData,
  StylableHorizontalMenuStateRefs,
} from '../StylableHorizontalMenu.types';

export const props = withCompInfo<
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData
>()(
  [
    'compProps',
    'compLayout',
    'compSingleLayout',
    'compStylableClass',
    'isQaMode',
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'compData',
    'trackClicksAnalytics',
  ],
  (
    {
      compProps,
      compLayout,
      compSingleLayout,
      compStylableClass,
      fullNameCompType,
      isQaMode,
      compData,
      ...restCompProps
    },
    carmiProps,
  ) => {
    const { menuMode, submenuMode, isContainerStretched } = compProps;
    const { a11y } = compData;
    const { items, containerRootClassName } = carmiProps;

    const isHorizontallyDocked = horizontallyDocked(
      compLayout,
      compSingleLayout,
    );

    return {
      ...restCompProps,
      menuMode,
      submenuMode,
      items,
      a11y,
      containerRootClassName,
      isStretched: isHorizontallyDocked,
      isContainerStretched,
      stylableClassName: compStylableClass,
      isQaMode,
      fullNameCompType,
    };
  },
);

export const css = withCompInfo<
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuDefinition
>()(['compProps'], ({ compProps }): StylableHorizontalMenuCSSVars => {
  const { menuMode, submenuMode } = compProps;
  const isWrap = menuMode === 'wrap';
  const isFlyout = submenuMode === 'flyout';

  const anumationCSSVars: AnimationCSSVars = {
    '--is-animated': 1,
    '--position-box-z-index': 47,
  };

  const menuCSSVars: MenuCSSVars = {
    '--menu-flex-wrap': isWrap ? 'wrap' : 'nowrap',
  };

  const subsubmenuBoxCSSVars: SubsubmenuBoxCSSVars = {
    '--subsubmenu-box-display': isFlyout ? 'none' : 'block',
    '--subsubmenu-box-position': isFlyout ? 'absolute' : 'static',
  };

  const menuItemCSSVars: MenuItemCSSVars = {
    '--white-space': isFlyout ? 'nowrap' : 'normal',
    '--label-word-wrap': isFlyout ? 'normal' : 'break-word',
  };

  return {
    ...anumationCSSVars,
    ...menuCSSVars,
    ...subsubmenuBoxCSSVars,
    ...menuItemCSSVars,
    ...(isWrap && {
      height: 'auto',
      'max-height': 'unset',
    }),
  };
});

export const sdkData = withCompInfo<
  StylableHorizontalMenuSdkData,
  StylableHorizontalMenuDefinition
>()(
  ['pagesMap', 'compData', 'hasResponsiveLayout'],
  ({ pagesMap, compData, hasResponsiveLayout }) => {
    const isAdvancedMenu =
      typeof compData.menuRef === 'string'
        ? compData.menuRef !== 'CUSTOM_MAIN_MENU'
        : compData?.menuRef?.id !== 'CUSTOM_MAIN_MENU';

    return {
      pageList: pagesMap,
      isSubSubEnabled: !hasResponsiveLayout && isAdvancedMenu,
    };
  },
);

export const stateRefs = withStateRefsValues<
  keyof StylableHorizontalMenuStateRefs
>(['currentUrl', 'reportBi']);

export default createComponentMapperModel({ css, props, stateRefs, sdkData });
