import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition,
  YouTubeSubscribeButtonProps,
} from '../YouTubeSubscribeButton.types';
import { Layout } from '../documentManagement/YouTubeSubscribeButton.schemaTypes';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

const getSize = (layout: Layout) => {
  const [width, height] = layout === 'full' ? [212, 55] : [145, 33];
  return {
    width: `${width}px`,
    height: `${height}px`,
  };
};

export const props = withCompInfo<
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonDefinition
>()(
  ['compData', 'compProps', 'uiType', 'deviceType', 'translate'],
  ({
    compData,
    compProps: { layout, theme },
    uiType,
    deviceType,
    translate,
  }) => ({
    channel: compData.youtubeChannelId,
    layout,
    theme: theme === 'dark' ? 'dark' : 'default',
    isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
    isMobile: isMobile(deviceType),
    translations: {
      ...translateConsentWrapperKeys(
        consetPolicyKeys.componentNameKey,
        consetPolicyKeys.componentNameDefault,
        translate,
      ),
    },
  }),
);

export const css = withCompInfo<
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition
>()(['compProps'], ({ compProps: { layout } }) => getSize(layout));

export const stateRefs = withStateRefsValues([...getConsentPolicyStateRefs()]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ css, props, stateRefs, uiType });
