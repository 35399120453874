import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ImageXProps,
  ImageXDefinition,
} from '@wix/thunderbolt-components-native';
import {
  IImageXProps,
  IImageXStateValues,
  ObjectFit,
  ResponsiveImageInfo,
} from '../ImageX.types';
import {
  getResponsiveImageObjectFit,
  getResponsiveImageSources,
} from './ImageX.utils';

export const props = withCompInfo<
  ImageXProps,
  ImageXDefinition,
  IImageXProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
    'editorName',
  ],
  ({ editorName, ...compPropsRest }, carmiProps: IImageXProps) => {
    const {
      link: _link,
      imageInfo: { alignType, imageData },
      responsiveImagesInfo,
      shouldUseResponsiveImages,
      hasScrollEffects,
    } = carmiProps;

    const link = _link && Object.keys(_link).length ? _link : undefined;
    const { width, height } = imageData.crop ? imageData.crop : imageData;
    const aspectRatio = height / width;

    const shouldSkipResponsiveImages: boolean =
      !responsiveImagesInfo ||
      responsiveImagesInfo?.some((data: ResponsiveImageInfo) => {
        return (
          data.layout?.width.type === 'Calc' ||
          data.layout?.height.type === 'Calc'
        );
      }) ||
      !shouldUseResponsiveImages ||
      hasScrollEffects;

    const sources =
      !shouldSkipResponsiveImages && responsiveImagesInfo
        ? getResponsiveImageSources(responsiveImagesInfo, imageData, alignType)
        : [];

    const objectFit: ObjectFit =
      !shouldSkipResponsiveImages && responsiveImagesInfo && sources?.length > 0
        ? getResponsiveImageObjectFit(
            sources,
            responsiveImagesInfo,
            imageData,
            alignType,
          )
        : 'cover';

    return {
      ...compPropsRest,
      ...carmiProps,
      link,
      sources,
      objectFit,
      aspectRatio,
      useNativeAspectRatio: editorName === 'Studio',
    };
  },
);

const stateRefs = withStateRefsValues<keyof IImageXStateValues>(['reportBi']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
