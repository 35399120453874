import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { CompInfo, FillLayersProps } from '@wix/thunderbolt-components-native';
import { getFromColorMap } from '@wix/thunderbolt-commons/dist/color';
import {
  TextMaskCSSVars,
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps,
} from '../TextMask.types';

export const props = withCompInfo<
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps
>()(
  ['compData', 'siteColors'],
  (
    { compData, siteColors }: Partial<CompInfo>,
    carmiProps: TextMaskPropsCarmiProps & { fillLayers: FillLayersProps },
  ) => {
    const { fillLayers, shadow, ...design } = carmiProps || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resolveColor = (color: string) => getFromColorMap(color, siteColors);
    const shadowFilter =
      shadow && Object.keys(shadow).length > 0
        ? `drop-shadow(${shadow.x}px ${shadow.y}px ${
            shadow.blurRadius
          }px ${resolveColor(shadow.color)}${Math.round(
            shadow.opacity * 255,
          ).toString(16)})`
        : '';

    return {
      ...compData,
      ...design,
      fillLayers,
      shadowFilter,
    };
  },
);

export const css = withCompInfo<TextMaskCSSVars, TextMaskDefinition>()(
  [],
  // eslint-disable-next-line no-empty-pattern
  ({}, carmiCssVars: any): TextMaskCSSVars => {
    return {
      ...carmiCssVars,
      height: 'auto',
    };
  },
);

export default createComponentMapperModel({ css, props });
