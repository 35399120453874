import * as React from 'react';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IVideoPlayerHandles,
  IVideoPlayerProps,
  VideoPlayerCorvidState,
} from '../VideoPlayer.types';
import { useAsyncRef } from '../../../providers/useAsyncRef';
import { useHasBeenInViewport } from '../../../providers/useHasBeenInViewport/useHasBeenInViewport';
import semanticClassNames from '../VideoPlayer.semanticClassNames';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import VideoPlayers from './players';
import { IPlayerHandles } from './players/players.types';
import { st, classes } from './style/VideoPlayer.st.css';

import { getPlayerName } from './utils';

const VideoPlayer: React.ForwardRefRenderFunction<
  IVideoPlayerHandles,
  IVideoPlayerProps
> = (props: IVideoPlayerProps, ref) => {
  const {
    onMouseEnter,
    onMouseLeave,
    reducedMotion,
    className,
    customClassNames = [],
    isConsentPolicyActive,
  } = props;
  const autoplay = reducedMotion ? false : props.autoplay;
  const [reloadId, setReloadId] = React.useState<number>(0);
  const [isLoadingForcedByCorvid, setIsLoadingForcedByCorvid] =
    React.useState<boolean>(false);
  const playerName = getPlayerName(props.src);
  const isPlayable = playerName === 'playable';
  const Player = VideoPlayers[playerName];

  const reloadPlayer = React.useCallback(
    () => setReloadId(reloadId + 1),
    [reloadId],
  );
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<IPlayerHandles>(null);
  const [waitForPlayer, getPlayer, setPlayer, resetPlayer] =
    useAsyncRef<IPlayerHandles>();

  const updateCorvidState = (override?: Partial<VideoPlayerCorvidState>) => {
    if (!props.onStateUpdated) {
      return;
    }
    const overrideState = override || {};
    const player = getPlayer();
    const playerState = player
      ? {
          isPlaying: player.isPlaying(),
          duration: Number(player.getDuration()),
        }
      : {};
    const newState = {
      ...playerState,
      ...overrideState,
    };
    props.onStateUpdated({
      type: 'onStateUpdated',
      state: newState,
    });
  };

  React.useImperativeHandle(ref, () => ({
    resetPlayerRef: () => resetPlayer(),
    play: () => {
      setIsLoadingForcedByCorvid(true);
      return waitForPlayer().then(player => player.play());
    },
    pause: () => waitForPlayer().then(player => player.pause()),
    stop: () =>
      waitForPlayer().then(player =>
        player.stop ? player.stop() : reloadPlayer(),
      ),
    togglePlay: () => {
      setIsLoadingForcedByCorvid(true);
      return waitForPlayer().then(player => player.togglePlay());
    },
    mute: () => {
      updateCorvidState({ isMuted: true });
      return waitForPlayer().then(player => player.mute());
    },
    unmute: () => {
      updateCorvidState({ isMuted: false });
      return waitForPlayer().then(player => player.unMute());
    },
    seek: (timePoint: number) =>
      waitForPlayer().then(player => player.seekTo(timePoint)),
    setVolume: (volume: number) => {
      updateCorvidState({ volume });
      waitForPlayer().then(player => player.setVolume(volume));
      // synchronous void return
    },
  }));

  const handleDuration = (duration: number) => {
    updateCorvidState({ duration });
  };

  const handlePlay = () => {
    updateCorvidState({ isPlaying: true });
    props.onPlay?.({ type: 'onPlay' });
  };

  const handlePause = () => {
    updateCorvidState({ isPlaying: false });
    props.onPause?.({ type: 'onPause' });
  };

  const handleEnded = () => {
    updateCorvidState({ isPlaying: false });
    props.onEnded?.({ type: 'onEnded' });
  };

  const handleProgress = (currentTime: number) => {
    const player = getPlayer() as IPlayerHandles;
    const volume = player.getVolume();
    const isMuted = player.isMuted();
    updateCorvidState({
      currentTime,
      volume,
      isMuted: Boolean(isMuted),
    });
    props.onProgress?.({ type: 'onProgress' });
  };

  const handleInit = () => {
    setPlayer(playerRef.current as IPlayerHandles);
    props.onPlayerInitialized?.({ type: 'onPlayerInitialized' });
  };

  const hasBeenInViewport = useHasBeenInViewport(containerRef);
  const shouldRenderPlayer =
    autoplay || hasBeenInViewport || isLoadingForcedByCorvid;

  const baseComponentProps = getComponentProps(
    playerName !== 'playable' && isConsentPolicyActive,
    {
      id: props.id,
      ...getDataAttributes(props),
      className: st(
        classes.root,
        className,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      ),
    },
  );

  return shouldRenderPlayer ? (
    <div
      {...baseComponentProps}
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Player
        key={`player-${props.src}-${reloadId}`}
        {...props}
        {...(isPlayable && props.playableConfig)}
        playing={autoplay}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onInit={handleInit}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        ref={playerRef}
      />
    </div>
  ) : (
    <div id={props.id} className={st(classes.root)} ref={containerRef} />
  );
};

export default React.forwardRef(VideoPlayer);
