import * as React from 'react';
import { FreemiumBannerResponsiveProps } from '../../../FreemiumBannerResponsive.types';
import { StudioLogo } from '../../assets/studioLogo';
import FreemiumBannerResponsiveCommon from '../../FreemiumBannerResponsiveCommon';
import style from '../../style/FreemiumBannerResponsiveStudio.scss';

const FreemiumBannerResponsiveStudio: React.FC<
  FreemiumBannerResponsiveProps
> = props => (
  <FreemiumBannerResponsiveCommon {...props} styles={style}>
    <StudioLogo className={style.logo} logoText={props.logoText} />
  </FreemiumBannerResponsiveCommon>
);
export default FreemiumBannerResponsiveStudio;
