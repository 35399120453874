import * as React from 'react';
import { IGridProps } from '../Grid.types';
import { isValidDate } from '../utils';

type IDateCellProps = Pick<IGridProps, 'dateFormat'> & {
  value: any;
};

const formatDate = (
  date: Date,
  format: IDateCellProps['dateFormat'],
): string => {
  const yyyy = date.getUTCFullYear().toString();
  const mm = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const dd = date.getUTCDate().toString().padStart(2, '0');
  return format === 'dd/mm/yyyy'
    ? `${dd}/${mm}/${yyyy}`
    : `${mm}/${dd}/${yyyy}`;
};

const DateCell: React.FC<IDateCellProps> = ({ value, dateFormat }) => {
  const dateString = React.useMemo(() => {
    if (value === null || value === undefined) {
      return '';
    }

    let date = new Date(`${value}`);
    if (!isValidDate(date)) {
      date = new Date(Date.now());
    }

    return formatDate(date, dateFormat);
  }, [value, dateFormat]);

  return dateString as any;
};

export default DateCell;
