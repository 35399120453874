import * as React from 'react';
import classnames from 'clsx';
import {
  activateByEnterButton,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IMemberLoginDialogProps,
  IIFrameConfig,
} from '../MemberLoginDialog.types';
import Preloader from '../../Preloader/viewer/Preloader';
import { useSocialErrorHandler } from '../useSocialErrorHandler';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import { SocialAuth } from './SocialAuth';
import { SocialAuthButtons } from './SocialAuthButtons';
import { EmailAuth } from './EmailAuth';
import style from './style/style.scss';
import {
  MemberLoginDialogTranslationKeys as keys,
  getReportCopyToClipboardBiCallback,
  isGoogleEnabled,
  isFacebookEnabled,
  isMandatorySSO,
} from './utils';
import { UnsupportedSocialAuthMessage } from './UnsupportedSocialAuthMessage';
import { MandatorySSODialog } from './MandatorySSODialog';

const MemberLoginDialog: React.FC<IMemberLoginDialogProps> = props => {
  const {
    id,
    className,
    isEmailLoginEnabled,
    isSocialAuthSupported,
    idps,
    submit,
    onSwitchDialogLinkClick,
    onForgetYourPasswordClick,
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    onCloseDialogCallback,
    isCloseable,
    onBackendSocialLogin,
    reportBi,
    displayMode,
    directionByLanguage,
    serverError,
    reportSocialAuthStarted,
    translations,
  } = props;

  const headlineId = `loginHeadline_${id}`;
  const [
    socialAuthError,
    translateAndSetSocialAuthError,
    resetSocialAuthError,
  ] = useSocialErrorHandler(translations);

  const wrappedOnBackendSocialLogin =
    resetSocialAuthError(onBackendSocialLogin);

  const iframeConfig: IIFrameConfig = {
    bsi,
    language,
    biVisitorId,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked: false,
    useGoogleSdk: props.useGoogleSdk,
  };

  const isExternalWebsiteInSocialInAppBrowser =
    !isSocialAuthSupported && isEmailLoginEnabled;
  const isSocialAuthEnabled = idps.length > 0;
  // If no social auth enabled or we in external wix site inside social in app browser we skip to sign in with email page
  const [showSocialAuthScreen, setShowSocialAuthScreen] = React.useState(
    isSocialAuthEnabled && !isExternalWebsiteInSocialInAppBrowser,
  );
  const [isFormLoading, setIsFormLoading] = React.useState(false);

  const title = translations[keys.title];
  const submitButtonLabel = translations[keys.submitButton];
  const switchDialogLink = translations[keys.switchDialogLink];
  const switchToSignUp = translations[keys.switchToSignUp];
  const emailSectionDivider = translations[keys.emailSectionDivider];
  const switchToAuthWithEmail = translations[keys.switchToAuthWithEmail];
  const socialSectionDividerLabel = translations[keys.socialSectionDivider];

  const presentedAuthMethodScreen = showSocialAuthScreen ? (
    <SocialAuth
      id={id}
      emailSectionDivider={emailSectionDivider}
      switchToAuthWithEmail={switchToAuthWithEmail}
      goToEmailAuthScreen={resetSocialAuthError(setShowSocialAuthScreen)}
      idps={idps}
      iframeConfig={iframeConfig}
      style={style}
      isEmailLoginEnabled={isEmailLoginEnabled}
      mode="login"
      translations={translations}
      error={socialAuthError}
      onBackendSocialLogin={wrappedOnBackendSocialLogin}
      onSocialError={translateAndSetSocialAuthError}
      reportSocialAuthStarted={reportSocialAuthStarted}
    />
  ) : (
    <EmailAuth
      id={id}
      translations={translations}
      onForgetYourPasswordClick={onForgetYourPasswordClick}
      onSubmitStart={() => setIsFormLoading(true)}
      onSubmitEnd={() => setIsFormLoading(false)}
      submit={submit}
      style={style}
      mode="login"
      submitButtonLabel={submitButtonLabel}
      language={language}
      directionByLanguage={directionByLanguage}
      shouldForceCaptchaVerification={props.shouldForceCaptchaVerification}
      serverError={serverError}
    />
  );

  const isMandatorySSOEnabled = isMandatorySSO(idps);

  const socialAuthButtons = (
    <SocialAuthButtons
      id={id}
      idps={idps}
      iframeConfig={iframeConfig}
      style={style}
      translations={translations}
      mode="login"
      error={socialAuthError}
      isHorizontal={true}
      hideSSOButton={!isMandatorySSOEnabled}
      onBackendSocialLogin={wrappedOnBackendSocialLogin}
      onSocialError={translateAndSetSocialAuthError}
      reportSocialAuthStarted={reportSocialAuthStarted}
    />
  );

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      displayMode={displayMode}
      dialogPosition="start"
      translations={translations}
    >
      {isMandatorySSOEnabled ? (
        <MandatorySSODialog
          id={id}
          directionByLanguage={directionByLanguage}
          headlineId={headlineId}
          translations={translations}
        >
          <div className={style.mandatorySsoButtonContainer}>
            {socialAuthButtons}
          </div>
        </MandatorySSODialog>
      ) : (
        <>
          <div
            id={id}
            className={classnames(style.memberLoginContent, {
              [style.formLoading]: isFormLoading,
              [style.rtl]: directionByLanguage === 'rtl',
            })}
          >
            <h1 id={headlineId} className={style.title}>
              {title}
            </h1>
            <div
              className={classnames(style.contentWrapper, style.wrapper, {
                [style.horizontal]: isSocialAuthEnabled,
                [style.socialLoginMode]: showSocialAuthScreen,
                [style.vertical]: !isSocialAuthEnabled,
                [style.emailLoginMode]: !showSocialAuthScreen,
              })}
            >
              <div className={style.switchToSignUpContainer}>
                <span
                  data-testid="switchToSignUpDescription"
                  className={style.switchToSignUpText}
                >
                  {switchToSignUp}
                </span>
                <button
                  className={style.switchDialogLink}
                  data-testid="switchToSignUp"
                  onClick={onSwitchDialogLinkClick}
                  onKeyDown={activateByEnterButton}
                  autoFocus
                  type="button"
                >
                  {switchDialogLink}
                </button>
              </div>
              {presentedAuthMethodScreen}
              {isSocialAuthEnabled &&
                (isExternalWebsiteInSocialInAppBrowser ? (
                  <UnsupportedSocialAuthMessage
                    isSocialLoginGoogleEnabled={isGoogleEnabled(idps)}
                    isSocialLoginFacebookEnabled={isFacebookEnabled(idps)}
                    translations={translations}
                    mode="Login"
                    styleMode="dialog"
                    reportCopyToClipboardBi={getReportCopyToClipboardBiCallback(
                      reportBi,
                      { biVisitorId, metaSiteId },
                    )}
                  />
                ) : (
                  <div
                    className={classnames({
                      [style.hideAuthMethod]: showSocialAuthScreen,
                    })}
                  >
                    <div
                      className={classnames(
                        style.socialSectionDivider,
                        style.sectionDivider,
                      )}
                    >
                      <span>{socialSectionDividerLabel}</span>
                    </div>
                    {socialAuthButtons}
                  </div>
                ))}
            </div>
          </div>
          <div className={style.preloaderContainer}>
            <Preloader enabled={isFormLoading} dark={true} />
          </div>
        </>
      )}
    </SiteMembersDialogLayout>
  );
};

export default MemberLoginDialog;
