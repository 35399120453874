import * as React from 'react';
import {
  customCssClasses,
  sanitizeHTML,
  getDataAttributes,
  getTabIndexAttribute,
} from '@wix/editor-elements-common-utils';
import type { ICollapsibleTextCompProps } from '../CollapsibleText.types';
import { ExpandModeValues, TestIds } from '../constants';
import { TagValue } from '../../../common/panel-sections/seoAndA11y/consts';
import semanticClassNames from '../CollapsibleText.semanticClassNames';
import { st, classes } from './style/CollapsibleText.component.st.css';
import { ExpandControl } from './ExpandControl/ExpandControl';

const noop = () => {};

const CollapsibleText: React.FC<ICollapsibleTextCompProps> = props => {
  const {
    id,
    text,
    className,
    customClassNames = [],
    stylableClassName,
    direction,
    showExpandControl,
    expandMode,
    ellipsis,
    wrappingStyle,
    isExpanded,
    textDescription,
    tag: TagName = TagValue.P,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;
  const wrapperId = `wrapper-${id}`;

  const shouldShowExpandControl = () =>
    ellipsis && (showExpandControl || expandMode === ExpandModeValues.Expand);
  const textClassName = st(
    classes.text,
    {
      collapse: ellipsis && !isExpanded,
      wrappingStyle,
    },
    customCssClasses(semanticClassNames.text),
  );

  // Needed for rich-text support
  const Text: React.FC<{ _text: string }> = ({ _text }) =>
    React.createElement(TagName, {
      dangerouslySetInnerHTML: {
        __html: sanitizeHTML(_text),
      },
      className: textClassName,
    });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...getTabIndexAttribute(props.a11y)}
      className={className}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        id={wrapperId}
        dir={direction}
        className={st(
          classes.root,
          {},
          stylableClassName,
          customCssClasses(semanticClassNames.root, ...customClassNames),
        )}
        data-testid={TestIds.viewer.root}
        aria-label={textDescription}
        role="group"
      >
        {shouldShowExpandControl() && (
          <ExpandControl {...props} wrapperId={wrapperId} />
        )}

        <div className={st(classes['text-wrapper'])}>
          <Text _text={text} />
        </div>
      </div>
    </div>
  );
};

export default CollapsibleText;
