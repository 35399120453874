import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types/src/entries/thunderboltPreview';
import {
  ExpandableMenuProps,
  ExpandableMenuDefinition,
} from '../ExpandableMenu.types';
import { forceOpenMenu } from './utils/forceOpenMenu';
import { props as viewerPropsMapper } from './ExpandableMenu.mapper';

const demoData = [
  {
    label: 'Menu item 1',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 2',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 3',
    items: [],
    link: undefined,
  },
];

const props = composeMapper<
  PreviewWrapperProps<ExpandableMenuProps>,
  ExpandableMenuDefinition,
  ExpandableMenuProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState', 'isWixBlocks'],
    remove: ['trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState, isWixBlocks } = deps;
    let items = carmiData.items;

    if (isWixBlocks) {
      // TODO: replace labels with translation keys
      items = demoData;
    }
    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      items: ['regular', 'selected'].includes(compPreviewState as string)
        ? forceOpenMenu(items)
        : items,
    };
  },
);

export default createComponentPreviewMapperModel({ props } as any);
