import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  keyCodes,
  debounce,
  customCssClasses,
} from '@wix/editor-elements-common-utils';
import { useDidUpdate } from '@wix/thunderbolt-elements/providers/useDidUpdate';
import { TestIds } from '../../constants';
import { AllTabsRef, ITabsListProps } from '../../Tabs.types';
import semanticClassNames from '../../Tabs.semanticClassNames';
import TabsListItem from './TabsListItem';
import { LeftScrollButton, RightScrollButton } from './ScrollButton';
import { classes, st } from './style/TabsList.st.css';
import { animateElementByProp } from './animation';

enum ScrollDirection {
  FORWARD = 1,
  BACKWARD = -1,
}

const clamp = (value: number = 0, min: number = 0, max: number = 0) =>
  Math.max(Math.min(value, max), min);

const TabsList: React.FC<ITabsListProps> = ({
  currentTabId,
  tabItems,
  onTabItemClick,
  className,
  activeMenuItemRef,
}) => {
  const [shouldShowBackButton, setShouldShowBackButton] = useState(true);
  const [shouldShowForwardButton, setShouldShowForwardButton] = useState(true);

  const navRef = useRef<HTMLDivElement>(null);
  const leftScrollButtonRef = useRef<HTMLDivElement>(null);
  const allTabsRef: AllTabsRef = {};

  const addTabRef = (
    ref: React.RefObject<HTMLDivElement>,
    idx: number,
    isActive: boolean,
  ) => {
    if (!ref || !activeMenuItemRef) {
      return;
    }
    allTabsRef[idx] = isActive ? activeMenuItemRef : ref;
  };

  const isTabActive = (tabId: string) => currentTabId === tabId;

  const checkActiveTabVisible = () => {
    if (!activeMenuItemRef?.current || !navRef.current) {
      return false;
    }
    const activeTabStartPosition = activeMenuItemRef.current.offsetLeft;
    const activeTabEndPosition =
      activeMenuItemRef.current.offsetLeft +
      activeMenuItemRef.current.offsetWidth;
    const navScrollStartPosition = navRef.current.scrollLeft;
    const navScrollEndPosition =
      navRef.current.scrollLeft + navRef.current.offsetWidth;

    return (
      navScrollEndPosition >= activeTabEndPosition &&
      navScrollStartPosition <= activeTabStartPosition
    );
  };

  const isRtl = () => {
    if (!navRef.current) {
      return false;
    }
    const navStyles = getComputedStyle(navRef.current);
    return navStyles.direction === 'rtl';
  };
  const isScrollMode = () => {
    if (!leftScrollButtonRef.current) {
      return false;
    }
    const scrollButtonStyles = getComputedStyle(leftScrollButtonRef.current);
    return scrollButtonStyles.display !== 'none';
  };

  useEffect(() => {
    if (isScrollMode()) {
      const isActiveTabVisible = checkActiveTabVisible();
      if (isActiveTabVisible) {
        handleScrollPosition(0);
      } else {
        navRef.current?.scrollTo({
          left: activeMenuItemRef?.current?.offsetLeft,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to active tab after selection
  useDidUpdate(() => {
    const isActiveTabVisible = checkActiveTabVisible();
    if (!isActiveTabVisible) {
      navRef.current?.scrollTo({
        left: activeMenuItemRef?.current?.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [currentTabId]);

  // Returns boundaries of TabList scroll
  const getMaxMinScroll = () => {
    if (!navRef.current) {
      return { max: 0, min: 0 };
    }

    const { scrollWidth, offsetWidth } = navRef.current;
    return { max: Math.abs(scrollWidth - offsetWidth), min: 0 };
  };

  const getBufferWidth = () => {
    if (!navRef.current) {
      return 0;
    }

    const { offsetWidth } = navRef.current;
    return offsetWidth / 50;
  };

  // Sets the visibility of forward and backwards button to match whether there's more content
  const handleScrollPosition = (scrollPosition: number) => {
    const { min, max } = getMaxMinScroll();
    const buffer = getBufferWidth();
    const canScrollBackwards = scrollPosition > min + buffer;
    const canScrollForwards = scrollPosition + buffer < max;
    setShouldShowBackButton(canScrollBackwards);
    setShouldShowForwardButton(canScrollForwards);
  };

  /**
   * @param scrollDirection {Number} 1 if moving forward or -1 if moving backwards
   * When pressing back or forward button, scrolls TabList toward the received direction (with animation)
   */
  const handleScrollButton = (scrollDirection: number) => {
    if (!navRef.current) {
      return;
    }
    const rtl = isRtl();
    const { min, max } = getMaxMinScroll();
    const { scrollLeft, clientWidth } = navRef.current;
    const rtlDirectionFix = rtl ? -1 : 1; // in RTL, the scroll position is represented as a negative value
    const scrollDistance = scrollDirection * (clientWidth / 2);
    const scrollPosition =
      rtlDirectionFix * clamp(Math.abs(scrollLeft) + scrollDistance, min, max);

    animateElementByProp({
      propToAnimate: 'scrollLeft',
      element: navRef.current,
      moveTo: scrollPosition,
      duration: 400,
    });
  };

  const handleScrollForward = () => {
    handleScrollButton(ScrollDirection.FORWARD);
  };
  const handleScrollBackward = () => {
    handleScrollButton(ScrollDirection.BACKWARD);
  };
  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = event.currentTarget;
    debouncedHandelScrollPosition(Math.abs(scrollLeft));
  };

  // Debounce scroll handling to reduce re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandelScrollPosition = useCallback(
    debounce(handleScrollPosition, 100),
    [],
  );

  const handleKeyboardNav = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const rtl = isRtl();
    const target = e.target as HTMLDivElement;
    const tabItemIdx = Number(target.getAttribute('data-idx'));

    const moveFocus = (direction: number) => {
      if (allTabsRef[tabItemIdx + direction]) {
        allTabsRef[tabItemIdx + direction].current?.focus();
      }
    };

    switch (e.keyCode) {
      case keyCodes.arrowRight:
        return rtl ? moveFocus(-1) : moveFocus(1);
      case keyCodes.arrowLeft:
        return rtl ? moveFocus(1) : moveFocus(-1);
      case keyCodes.enter:
      case keyCodes.space:
        e.preventDefault();
        target.click();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={st(
        classes.root,
        className,
        customCssClasses(semanticClassNames.menuContainer),
      )}
    >
      <LeftScrollButton
        isVisible={shouldShowBackButton}
        onClick={handleScrollBackward}
        dataHook={TestIds.BackwardScrollBtn}
        className={classes.scrollButton}
        ref={leftScrollButtonRef}
      />
      <div
        className={classes.tabsList}
        ref={navRef}
        data-hook={TestIds.TabsList}
        onScroll={onScroll}
        role="tablist"
        onKeyDown={handleKeyboardNav}
      >
        {tabItems?.map((item, index) => {
          const isActive = isTabActive(item.tabId);
          return (
            <TabsListItem
              {...item}
              isActive={isActive}
              key={`${item.label}-${index}`}
              onClick={() => onTabItemClick(item.tabId, item.id)}
              ref={isActive ? activeMenuItemRef : undefined}
              idx={index}
              addRef={addTabRef}
            />
          );
        })}
      </div>
      <div className={classes.border} />
      <RightScrollButton
        isVisible={shouldShowForwardButton}
        onClick={handleScrollForward}
        dataHook={TestIds.ForwardScrollBtn}
        className={classes.scrollButton}
      />
    </div>
  );
};

export default TabsList;
