import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
} from '../FreemiumBannerDesktop.types';
import translationKeys from './constants';

export const props = withCompInfo<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition
>()(
  ['direction', 'metaSiteId', 'translate'],
  ({ direction, metaSiteId, translate }) => {
    return {
      useOverlay: false,
      direction,
      href: `//www.wix.com/lpviral/enviral?utm_campaign=vir_wixad_live&adsVersion=white&orig_msid=${metaSiteId}`,
      classNames: [direction],
      translations: {
        bannerText:
          translate(translationKeys.NAMESPACE, translationKeys.MAIN_TEXT_KEY) ||
          translationKeys.MAIN_TEXT_DEFAULT ||
          '',
        buttonText:
          translate(
            translationKeys.NAMESPACE,
            translationKeys.BUTTON_TEXT_KEY,
          ) || translationKeys.BUTTON_TEXT_DEFAULT,
      },
    };
  },
);
export default createComponentMapperModel({ props });
