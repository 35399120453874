import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  ICustomElementMapperProps,
  ICustomElementPreviewMapperProps,
  CustomElementComponentDefinition,
} from '../CustomElementComponent.types';
import { Namespace, TranslationKeys } from '../../translations';
import { props as viewerPropsMapper } from './CustomElementComponent.mapper';

const props = composeMapper<
  PreviewWrapperProps<
    ICustomElementMapperProps,
    ICustomElementPreviewMapperProps
  >,
  CustomElementComponentDefinition
>()(
  viewerPropsMapper,
  {
    add: [
      'componentViewMode',
      'compLayout',
      'isWixBlocks',
      'compData',
      'translate',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { componentViewMode, compLayout, isWixBlocks, compData, translate } =
      deps;
    const { width, height } = compLayout;
    const { scriptType } = compData;

    return {
      ...viewerProps,
      scriptType,
      previewWrapperProps: {
        componentViewMode,
        isWixBlocks,
        containerSizes: { width, height },
        translations: {
          blocksUpgrade: translate(Namespace, TranslationKeys.BlocksUpgrade),
          upgradePremium: translate(Namespace, TranslationKeys.UpgradePremium),
          connectDomain: translate(Namespace, TranslationKeys.ConnectDomain),
          upgradeNoAds: translate(Namespace, TranslationKeys.UpgradeNoAds),
        },
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
