import * as React from 'react';
import classNames from 'clsx';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { IResponsivePopupContainerProps } from '../ResponsivePopupContainer.types';
import style from '../../ResponsivePopupPage/viewer/style/ResponsivePopupPage.scss';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import semanticClassNames from '../ResponsivePopupContainer.semanticClassNames';

const ResponsivePopupContainer: React.FC<
  IResponsivePopupContainerProps
> = props => {
  const {
    id,
    className,
    customClassNames = [],
    containerProps,
    containerRootClassName,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, containerRootClassName)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          style.bg,
          customCssClasses(semanticClassNames.root, ...customClassNames),
        )}
        data-testid={TestIds.ResponsivePopupContainerBg}
      ></div>
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </div>
  );
};

export default ResponsivePopupContainer;
