import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { PREVIEW_STATE } from './HamburgerMenuRoot/viewer/constants';

export type IHamburgerMenuContextValue = {
  isMenuOpen?: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

type IHamburgerMenuContextProviderProps = {
  compPreviewState: string | null;
  children: React.ReactNode | Array<React.ReactNode>;
};

export const HamburgerMenuContext = createContext<IHamburgerMenuContextValue>({
  isMenuOpen: undefined,
  setIsMenuOpen: () => {},
});

export const useHamburgerMenuContext = () => useContext(HamburgerMenuContext);

export const HamburgerMenuContextProvider: React.FC<
  IHamburgerMenuContextProviderProps
> = ({ children, compPreviewState }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (compPreviewState === PREVIEW_STATE.SHOW_OVERLAY) {
      setIsMenuOpen(true);
    } else if (compPreviewState === PREVIEW_STATE.HIDE_OVERLAY) {
      setIsMenuOpen(false);
    }
  }, [compPreviewState]);

  const contextValue = useMemo(
    () => ({
      isMenuOpen,
      setIsMenuOpen,
    }),
    [isMenuOpen, setIsMenuOpen],
  );

  return (
    <HamburgerMenuContext.Provider value={contextValue}>
      {children}
    </HamburgerMenuContext.Provider>
  );
};
