import React, { ReactNode } from 'react';
import classnames from 'clsx';

import { customCssClasses } from '@wix/editor-elements-common-utils';
import styles from '../button.scss';
import semanticClassNames from '../../../../RichTextBox.semanticClassNames';

export type AlignButtonProps = {
  onClick?: React.MouseEventHandler<SVGElement>;
  selected?: boolean;
  children: ReactNode;
  dataTestId?: string;
};

export default React.forwardRef(
  (props: AlignButtonProps, ref: React.Ref<SVGSVGElement>) => {
    const { onClick, selected, children, dataTestId } = props;
    return (
      <svg
        viewBox="0 0 19 19"
        onClick={onClick}
        ref={ref}
        className={classnames(
          styles.buttonContent,
          customCssClasses(semanticClassNames.icons),
          selected && styles.buttonContentSelected,
        )}
        data-testid={dataTestId}
      >
        {children}
      </svg>
    );
  },
);
