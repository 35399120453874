import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  SliderDefinition,
  ISliderMapperProps,
  SliderTooltipVisibility,
} from '../Slider.types';
import { props as viewerPropsMapper } from './Slider.mapper';

const isInEditor = (componentPreviewMode: string) =>
  componentPreviewMode === 'editor';

const getEditorTooltipVisibility = (
  tooltipVisibility: SliderTooltipVisibility,
) => (tooltipVisibility === 'none' ? 'none' : 'always');

const props = composeMapper<
  PreviewWrapperProps<ISliderMapperProps>,
  SliderDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState, componentViewMode } = dependencies;
    const { tooltipVisibility, ...viewerProps } = resolver(
      dependencies,
      carmiData,
    );

    return {
      ...viewerProps,
      tooltipVisibility: isInEditor(componentViewMode)
        ? getEditorTooltipVisibility(tooltipVisibility)
        : tooltipVisibility,
      previewWrapperProps: {
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
