
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "HamburgerOverlay3642185523",
      {"classes":{"root":"HamburgerOverlay3642185523__root","overlay":"HamburgerOverlay3642185523__overlay"},"vars":{"above-all-z-index":"--above-all-z-index"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  