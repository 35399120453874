import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import BaseButton from './BaseButton';
import skinsStyle from './styles/LinesMenuButtonNBorderRadiusFixSkin.scss';

const LinesMenuButtonNBorderRadiusFixSkin: React.FC<
  MenuButtonProps
> = props => {
  return (
    <BaseButton
      {...props}
      skinsStyle={skinsStyle}
      skin="LinesMenuButtonNBorderRadiusFixSkin"
    />
  );
};

export default LinesMenuButtonNBorderRadiusFixSkin;
