import classnames from 'clsx';
import { IStyles } from 'playable/dist/src/modules/ui/core/types';
import * as React from 'react';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { usePopUrl } from './usePopUrl';

export type HeadlessAuthButtons = {
  style: IStyles;
  error?: string;
  loginUrl: string;
  externalBaseUrl?: string;
};

export const HeadlessAuthButtons: React.FC<HeadlessAuthButtons> = ({
  loginUrl,
  style,
  externalBaseUrl,
  error, // Will be set to a non-empty string if an error was thrown during social auth
}) => {
  const loginCallbackUrl = '/_api/iam/authentication/v1/callback';
  const openPopup = usePopUrl(
    true,
    externalBaseUrl
      ? `${externalBaseUrl}${loginCallbackUrl}`
      : loginCallbackUrl,
  );

  return (
    <div className={style.socialLoginWrapper}>
      <div
        data-testid="headless-auth-buttons"
        className={classnames(style.socialButton, style.sso)}
      >
        <BasicButton
          label="Sign in"
          id="headless_sign_in"
          link={undefined}
          hasPlatformClickHandler={true}
          isDisabled={false}
          onClick={() => openPopup(loginUrl)}
        />
      </div>
      <p className={style.socialLoginErrorMsg}>{error}</p>
    </div>
  );
};
