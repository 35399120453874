import * as React from 'react';
import { activateBySpaceOrEnterButton } from '@wix/editor-elements-common-utils';
import { parseTextWithLinks, cancelBrowserScroll } from '../utils';

export type ConsentTextProps = {
  text: string;
  textButtonClass: string;
  rootClass?: string;
  onSettingsClick: () => void;
  onPrivacyPolicyClick?: () => void;
};

export const ConsentText: React.FC<ConsentTextProps> = ({
  text,
  textButtonClass,
  rootClass,
  onSettingsClick,
  onPrivacyPolicyClick,
}) => {
  const { firstText, firstUrlText, secondText, secondUrlText, thirdText } =
    parseTextWithLinks(text);

  return (
    <div className={rootClass}>
      {firstText}
      {firstUrlText && (
        <span
          className={textButtonClass}
          role="button"
          aria-haspopup="true"
          tabIndex={0}
          onClick={onSettingsClick}
          onKeyUp={activateBySpaceOrEnterButton}
          onKeyDown={cancelBrowserScroll}
        >
          {firstUrlText}
        </span>
      )}
      {secondText}
      {secondUrlText && (
        <span
          className={textButtonClass}
          role="button"
          aria-haspopup="true"
          tabIndex={0}
          onClick={onPrivacyPolicyClick}
          onKeyUp={activateBySpaceOrEnterButton}
          onKeyDown={cancelBrowserScroll}
        >
          {secondUrlText}
        </span>
      )}
      {thirdText}
    </div>
  );
};
