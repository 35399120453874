import * as React from 'react';
import type { IShareButtonProps } from '../types';
import {
  COPY_CONFIRMATION_TIMEOUT,
  TranslationKeys,
  platformIds,
  toastTextKey,
} from '../constants';
import getPlatformIdWithFallback from '../utils/getPlatformIdWithFallback';
import ShareButtonIcon from './ShareButtonIcon';
import { classes, st } from './style/ShareButton.component.st.css';
import ButtonContentWrapper from './ButtonContentWrapper';

const ShareButton: React.FC<IShareButtonProps> = ({
  id,
  platformId,
  value,
  label,
  illustration,
  url = '',
  displayLabel,
  displayIcon,
  translations,
}) => {
  const platformIdWithFallback = getPlatformIdWithFallback(platformId, value);
  const translatedCopyLinkLabel =
    translations[TranslationKeys.manage.copy_link_default_text];
  const initializedLabel =
    platformIdWithFallback === platformIds.copyButton && translatedCopyLinkLabel
      ? translatedCopyLinkLabel
      : label;
  const [buttonLabel, setButtonLabel] = React.useState(initializedLabel);

  const onCopyButtonClicked = () => {
    setButtonLabel(translations[toastTextKey] || 'Link Copied!');
    setTimeout(() => {
      setButtonLabel(initializedLabel);
    }, COPY_CONFIRMATION_TIMEOUT);
  };
  return (
    <ButtonContentWrapper
      id={id}
      platformId={platformIdWithFallback}
      url={url}
      onCopyButtonClicked={onCopyButtonClicked}
      ariaLabel={buttonLabel}
    >
      {displayIcon && (
        <ShareButtonIcon
          className={st(classes.icon)}
          svg={illustration?.svgId ?? ''}
        />
      )}
      {displayLabel && <span className={st(classes.label)}>{buttonLabel}</span>}
    </ButtonContentWrapper>
  );
};

export default ShareButton;
