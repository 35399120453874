import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { MeshGroupCarmiData, MeshGroupPlatformProps } from '../MeshGroup';

export const props = withCompInfo<
  MeshGroupPlatformProps,
  any,
  MeshGroupCarmiData
>()([], (_, { className, ...carmiData }) => {
  return { carmiClassName: className, ...carmiData };
});

export default createComponentMapperModel({ props });
