import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISocialAuthProps } from '../SocialAuth.types';
import { SocialAuthButtons } from '../../MemberLoginDialog/viewer/SocialAuthButtons';
import {
  IIFrameConfig,
  IdpConnection,
} from '../../MemberLoginDialog/MemberLoginDialog.types';
import { useSocialErrorHandler } from '../../MemberLoginDialog/useSocialErrorHandler';
import { UnsupportedSocialAuthMessage } from '../../MemberLoginDialog/viewer/UnsupportedSocialAuthMessage';
import { getReportCopyToClipboardBiCallback } from '../../MemberLoginDialog/viewer/utils';
import style from './style/style.scss';

const SocialAuth: React.FC<ISocialAuthProps> = props => {
  const {
    id,
    className,
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked,
    isSocialLoginGoogleEnabled,
    isSocialLoginFacebookEnabled,
    mode = 'login',
    isSmallIcons,
    onBackendSocialLogin,
    reportBi,
    isSocialAuthSupported = true,
    reportSocialAuthStarted,
    translations,
  } = props;

  const [
    socialAuthError,
    translateAndSetSocialAuthError,
    resetSocialAuthError,
  ] = useSocialErrorHandler(translations);

  const iframeConfig: IIFrameConfig = {
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked,
    useGoogleSdk: props.useGoogleSdk,
  };

  const idps = new Array<IdpConnection>();
  if (isSocialLoginGoogleEnabled) {
    idps.push({
      name: 'Google',
      appDefId: '0e6a50f5-b523-4e29-990d-f37fa2ffdd69',
      id: '0e6a50f5-b523-4e29-990d-f37fa2ffdd69',
    });
  }
  if (isSocialLoginFacebookEnabled) {
    idps.push({
      name: 'Facebook',
      appDefId: '3ecad13f-52c3-483d-911f-31dbcf2a6d23',
      id: '3ecad13f-52c3-483d-911f-31dbcf2a6d23',
    });
  }

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        className,
        style.socialLoginWrapper,
        style.socialAuthComponent,
      )}
    >
      {isSocialAuthSupported ? (
        <SocialAuthButtons
          id={id}
          idps={idps}
          iframeConfig={iframeConfig}
          style={style}
          mode={mode}
          translations={translations}
          isHorizontal={isSmallIcons}
          error={socialAuthError}
          reportSocialAuthStarted={reportSocialAuthStarted}
          onBackendSocialLogin={resetSocialAuthError(onBackendSocialLogin)}
          onSocialError={translateAndSetSocialAuthError}
        />
      ) : (
        <UnsupportedSocialAuthMessage
          isSocialLoginGoogleEnabled={isSocialLoginGoogleEnabled}
          isSocialLoginFacebookEnabled={isSocialLoginFacebookEnabled}
          translations={translations}
          mode="Login"
          styleMode="custom"
          reportCopyToClipboardBi={getReportCopyToClipboardBiCallback(
            reportBi,
            { biVisitorId, metaSiteId },
          )}
        />
      )}
    </div>
  );
};

export default SocialAuth;
