import { withCompController } from '@wix/editor-elements-integrations';
import {
  CustomElementComponentControllerProps,
  ICustomElementMapperProps,
} from '../CustomElementComponent.types';

export default withCompController<
  ICustomElementMapperProps,
  CustomElementComponentControllerProps
>(({ mapperProps, controllerUtils }) => {
  const { updateStyles } = controllerUtils;
  return {
    ...mapperProps,
    resizeCustomElement: ({ height }) => {
      if (height > 0) {
        updateStyles({
          '--custom-element-height': `${height}px !important`,
        });
      }
    },
  };
});
