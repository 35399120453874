import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
} from '../StylableButton.types';
import { props as viewerPropsMapper } from './StylableButton.mapper';

const props = composeMapper<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  viewerPropsMapper,
  {
    add: [],
    remove: ['language', 'mainPageId', 'compId', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
