import * as React from 'react';
import cn from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import type { IMegaMenuContainerItemPropsResponsive } from '../../../MegaMenuContainerItem.types';
import { useSHMStyles } from '../../useSHMStyles';
import { classes } from './styles/Responsive.st.css';

const MegaMenuContainerItem: React.FC<
  IMegaMenuContainerItemPropsResponsive
> = props => {
  const { id, children, containerRootClassName, parentStylableClassName } =
    props;
  const childrenToRender =
    typeof children === 'function' ? children : () => children;
  const { className, cssVars } = useSHMStyles(parentStylableClassName);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={cn(containerRootClassName, className, classes.root)}
      style={cssVars}
    >
      <ResponsiveContainer {...props.containerProps}>
        {childrenToRender}
      </ResponsiveContainer>
    </div>
  );
};

export default MegaMenuContainerItem;
