import React, { useContext, useState, useEffect, useRef } from 'react';
import classnames from 'clsx';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import { createPortal } from 'react-dom';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import LinkModal from '../../../../../../RichTextBoxLinkModal/viewer/RichTextBoxLinkModal';
import buttonStyles from '../../button.scss';
import { hasEntity } from '../../../../utils/draftUtils';
import { TestIds } from '../../../../../constants';
import StylesContext from '../../../../StylesContext';
import {
  IRichTextBoxProps,
  OverrideDialogVisibilty,
} from '../../../../../RichTextBox.types';
import semanticClassNames from '../../../../../RichTextBox.semanticClassNames';
import LinkIcon from './LinkIcon';
import styles from './styles.scss';

export default (
  props: ToolbarChildrenProps &
    OverrideDialogVisibilty &
    Pick<
      IRichTextBoxProps,
      | 'linkModalId'
      | 'openMobileLinkModalTemp'
      | 'closeMobileLinkModalTemp'
      | 'scopedClassName'
    > & {
      type: IRichTextBoxProps['toolbarPosition'];
    },
) => {
  const stylesContext = useContext(StylesContext);

  const {
    setEditorState,
    getEditorState,
    type,
    linkModalId,
    openMobileLinkModalTemp,
    closeMobileLinkModalTemp,
    onOverrideDialogClose,
    onOverrideDialogOpen,
    scopedClassName,
  } = props;

  const [showDesktopModal, setShowDesktopModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [mobileLinkModalWrapper, setMobileLinkModalWrapper] =
    useState<HTMLElement>();
  const linkButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const portalContainer = document.createElement('div');
    const attachContainer =
      document.getElementById('SITE_CONTAINER') || document.body;

    if (attachContainer) {
      attachContainer.appendChild(portalContainer);

      if (scopedClassName) {
        portalContainer.classList.add(scopedClassName);
      }
    }

    setMobileLinkModalWrapper(portalContainer);
  }, [scopedClassName]);

  const openLinkModal = () => {
    if (openMobileLinkModalTemp) {
      setShowMobileModal(true);
      openMobileLinkModalTemp();
    } else {
      setShowDesktopModal(true);
      if (typeof onOverrideDialogOpen === 'function') {
        onOverrideDialogOpen();
      }
    }
  };

  const closeDesktopModal = () => {
    setShowDesktopModal(false);
    if (typeof onOverrideDialogClose === 'function') {
      onOverrideDialogClose();
    }
  };

  const closeMobileModal = () => {
    if (closeMobileLinkModalTemp) {
      closeMobileLinkModalTemp();
    }
    setShowMobileModal(false);
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected = hasEntity(getEditorState(), 'LINK');
  return (
    <div className={buttonStyles.buttonContainer} ref={linkButtonRef}>
      <button
        className={classnames(buttonStyles.button, {
          [buttonStyles.hovered]: stylesContext.hovered,
          [buttonStyles.focused]: stylesContext.focused,
          [buttonStyles.error]: stylesContext.error,
        })}
        onMouseDown={preventBubblingUp}
        onClick={openLinkModal}
        data-testid={TestIds.linkButton}
        type="button"
      >
        <div
          className={classnames(
            buttonStyles.buttonContent,
            customCssClasses(semanticClassNames.icons),
            selected && buttonStyles.buttonContentSelected,
          )}
        >
          <LinkIcon />
        </div>
      </button>
      {showDesktopModal && (
        <div
          className={
            type === 'bottom' ? styles.bottomToolbar : styles.topToolbar
          }
        >
          <LinkModal
            getEditorState={getEditorState}
            setEditorState={setEditorState}
            onClose={closeDesktopModal}
            staticDialog={true}
            linkButtonRef={linkButtonRef}
          />
        </div>
      )}
      {showMobileModal &&
        mobileLinkModalWrapper &&
        createPortal(
          <LinkModal
            id={linkModalId}
            getEditorState={getEditorState}
            setEditorState={setEditorState}
            onClose={closeMobileModal}
            linkButtonRef={linkButtonRef}
          />,
          mobileLinkModalWrapper,
        )}
    </div>
  );
};
