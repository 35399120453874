import * as React from 'react';
// TODO: deep import is bad, fix importing from index
import Link from '@wix/thunderbolt-elements/components/Link';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import {
  ICollapsibleTextControllerProps,
  ExpandControlData,
  ExpandControlProps,
} from '../../CollapsibleText.types';
import { st, classes } from '../style/CollapsibleText.component.st.css';
import { ExpandModeValues, TestIds } from '../../constants';
import semanticClassNames from '../../CollapsibleText.semanticClassNames';

const buttonSemanticClassName = customCssClasses(semanticClassNames.button);

export type ExpandControlCompProps = ExpandControlData &
  ExpandControlProps &
  ICollapsibleTextControllerProps & { wrapperId: string };

export const ExpandControl: React.FC<ExpandControlCompProps> = ({
  link,
  linkText,
  expandMode,
  readMoreText,
  readLessText,
  isExpanded,
  wrapperId,
  setIsExpanded,
  reportBiOnExpandClick,
  reportBiOnLinkClick,
}) => {
  const onExpandClick = () => {
    setIsExpanded(!isExpanded);
    reportBiOnExpandClick(!isExpanded);
  };

  const onLinkClick = () => {
    reportBiOnLinkClick();
  };

  switch (expandMode) {
    case ExpandModeValues.Expand:
      return (
        <button
          className={st(classes['expand-control'], buttonSemanticClassName)}
          onClick={onExpandClick}
          data-testid={TestIds.viewer.expand_control}
          aria-pressed={isExpanded}
          aria-describedby={wrapperId}
        >
          {isExpanded ? readLessText : readMoreText}
        </button>
      );
    case ExpandModeValues.Link:
      return (
        <Link
          {...link}
          onClick={onLinkClick}
          className={st(classes['expand-control'], buttonSemanticClassName)}
          dataTestId={TestIds.viewer.expand_control}
          aria-describedby={wrapperId}
        >
          {linkText}
        </Link>
      );
    default:
      return null;
  }
};
