import { MenuItemProps } from '../../StylableHorizontalMenu.types';
import { PREVIEW_STATE, Selectors } from './constants';

const hasSlot = (item: MenuItemProps) => !!item.slot;

export const forceOpenSlot = (items: Array<MenuItemProps>) => {
  const itemWithSlotIndex = items.findIndex(hasSlot);

  if (itemWithSlotIndex === -1) {
    return items;
  }

  return items.map((item, index) =>
    index === itemWithSlotIndex ? { ...item, forceHovered: true } : item,
  );
};

const hasSubItems = (item: MenuItemProps) => item.items?.length;

export const forceOpenSubmenu = (items: Array<MenuItemProps>) => {
  const itemWithSubItemsIndex = items.findIndex(hasSubItems);

  if (itemWithSubItemsIndex === -1) {
    return items;
  }

  const itemWithSubSubItemsIndex = items.findIndex(item =>
    item.items?.some(hasSubItems),
  );

  if (itemWithSubSubItemsIndex === -1) {
    return items.map((item, index) =>
      index === itemWithSubItemsIndex ? { ...item, forceHovered: true } : item,
    );
  }

  const subItemIndex =
    items[itemWithSubSubItemsIndex].items!.findIndex(hasSubItems);

  return items.map((item, index) =>
    index === itemWithSubSubItemsIndex
      ? {
          ...item,
          forceHovered: true,
          items: item.items?.map((subItem, sIndex) =>
            sIndex === subItemIndex
              ? { ...subItem, forceHovered: true }
              : subItem,
          ),
        }
      : item,
  );
};

export type ForceOpenIfNeededProps = {
  items: Array<MenuItemProps>;
  editSlotId?: string;
  compPreviewState?: string;
  cssSelector?: string;
};

export const forceOpenIfNeeded = ({
  items,
  editSlotId,
  compPreviewState,
  cssSelector,
}: ForceOpenIfNeededProps): Array<MenuItemProps> => {
  if (editSlotId) {
    return items.map(item =>
      item.slot === editSlotId ? { ...item, forceHovered: true } : item,
    );
  }

  if (compPreviewState === PREVIEW_STATE.MEGAMENU_TAB) {
    return forceOpenSlot(items);
  }
  if (compPreviewState === PREVIEW_STATE.SUBMENU_TAB) {
    return forceOpenSubmenu(items);
  }

  if (cssSelector?.startsWith(Selectors.submenu)) {
    return items.some(hasSubItems)
      ? forceOpenSubmenu(items)
      : forceOpenSlot(items);
  }

  return items;
};
