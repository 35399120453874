import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  getValidationControllerProps,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ICheckboxControllerProps,
  ICheckboxMapperProps,
  ICheckboxProps,
  ICheckboxStateValues,
} from '../Checkbox.types';

export default withCompController<
  ICheckboxMapperProps,
  ICheckboxControllerProps,
  ICheckboxProps,
  ICheckboxStateValues
>(({ mapperProps, stateValues, controllerUtils }) => {
  const { updateProps } = controllerUtils;
  const { reportBi } = stateValues;
  const { setValidityIndication } = getValidationControllerProps(updateProps);
  const {
    compId,
    language,
    mainPageId,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const commonAnalyticsClicksBiParams = {
    language,
    trackClicksAnalytics,
    element_id: compId,
    elementType: restMapperProps.fullNameCompType,
    elementGroup: AnalyticsClicksGroups.Input,
  };

  const reportBiOnClick = () => {
    const { label, checked } = restMapperProps;

    tryReportAnalyticsClicksBi(reportBi, {
      ...commonAnalyticsClicksBiParams,
      value: !checked,
      actionName: 'None',
      elementTitle: label,
    });
  };

  const reportBiOnLinkClick = () => {
    const { link, linkLabel } = restMapperProps;

    tryReportAnalyticsClicksBi(reportBi, {
      ...commonAnalyticsClicksBiParams,
      link,
      elementTitle: linkLabel,
      pagesMetadata: { mainPageId },
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
    reportBiOnLinkClick,
    setValidityIndication,
    onCheckedChange: (isChecked: boolean) => {
      updateProps({
        checked: isChecked,
      });
    },
  };
});
