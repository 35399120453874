import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getInputHeight } from '@wix/editor-elements-common-utils';
import {
  TimePickerDefinition,
  ITimePickerMapperProps,
  TimePickerCSSVars,
  TimePickerCssCarmiData,
  TimePickerAlignment,
} from '../TimePicker.types';
import { getEnabledTimes } from '../utils';
import { TranslationKeys } from '../constants';

export const props = withCompInfo<
  ITimePickerMapperProps,
  TimePickerDefinition
>()(
  ['compData', 'compProps', 'hasResponsiveLayout', 'translate'],
  ({ compData, compProps, hasResponsiveLayout, translate }) => {
    const { value, placeholder, step, label } = compData;
    const {
      useAmPmFormat,
      controller,
      initialTime,
      readOnly,
      isDisabled,
      required,
      disabledTimes,
      enabledTimes,
    } = compProps;

    return {
      value,
      placeholder: initialTime === 'none' ? '' : placeholder,
      step,
      label,
      useAmPmFormat,
      controller,
      initialTime,
      readOnly,
      isDisabled,
      required,
      isResponsive: hasResponsiveLayout,
      enabledTimes: getEnabledTimes({ enabledTimes, disabledTimes }),
      translations: {
        invalidTime: translate(
          TranslationKeys.Namespace,
          TranslationKeys.TIME_PICKER_INVALID_TIME,
        ),
      },
    };
  },
);

const alignmentCssVars = (
  alignment: TimePickerAlignment,
  labelPadding: number,
) => {
  const alignmentToCssVars: Record<
    TimePickerAlignment,
    Pick<
      TimePickerCSSVars,
      '--dir' | '--arrowMargin' | '--flexDirection' | '--labelPadding'
    >
  > = {
    left: {
      '--dir': 'ltr' as 'ltr',
      '--arrowMargin': '0 20px 0 0',
      '--flexDirection': 'row',
      '--labelPadding': `0 0 0 ${labelPadding}px`,
    },
    center: {
      '--dir': 'ltr' as 'ltr',
      '--arrowMargin': '0 20px 0 0',
      '--flexDirection': 'row',
      '--labelPadding': '0',
    },
    right: {
      '--dir': 'rtl' as 'rtl',
      '--arrowMargin': '0 0 0 20px',
      '--flexDirection': 'row-reverse',
      '--labelPadding': `0 ${labelPadding}px 0 0`,
    },
  };

  return {
    '--textAlign': alignment,
    ...(alignmentToCssVars[alignment] || alignmentToCssVars.left),
  };
};

const inputPaddingVar = (
  alignment: TimePickerAlignment,
  isMobileView: boolean,
  margin: number = 15,
) => {
  const alignmentToPadding: Record<TimePickerAlignment, string> = {
    left: `0 0 0 ${margin}px`,
    center: '0',
    right: `0 ${margin}px 0 0`,
  };

  return isMobileView
    ? `0 ${margin}px`
    : alignmentToPadding[alignment] || alignmentToPadding.center;
};

export const css = withCompInfo<
  TimePickerCSSVars,
  TimePickerDefinition,
  TimePickerCssCarmiData
>()(
  [
    'compLayout',
    'compSingleLayout',
    'compProps',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  (
    {
      compLayout,
      compSingleLayout,
      compProps,
      isMobileView,
      hasResponsiveLayout,
    },
    cssVars,
  ) => {
    const { labelMargin, labelPadding, alignment, margin } = compProps;

    const sharedProps = {
      ...cssVars,
      ...alignmentCssVars(alignment, labelPadding),
      '--labelMarginBottom': `${labelMargin}px`,
      '--inputPadding': inputPaddingVar(alignment, isMobileView, margin),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      return {
        ...sharedProps,
        height: 'auto',
        '--inputHeight': getInputHeight({
          inputHeightProps: compProps,
          compLayout,
          compSingleLayout,
          isMobileView: false,
        }),
      };
    }
  },
);

export default createComponentMapperModel({ css, props });
