import React from 'react';
import { ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import type { ILogoProps } from '../Logo.props';
import semanticClassNames from '../Logo.semanticClassNames';
import { defaultLogoUrl } from '../constants';
import styles from './style/Logo.scss';

const Logo: React.FC<ILogoProps> = props => {
  const {
    id,
    logoId,
    alt,
    customClassNames = [],
    backgroundColor,
    hasBackground,
    logoUrl,
    isLoadingData,
    link,
  } = props;
  const logoToDisplay = logoId && logoUrl ? logoUrl : defaultLogoUrl;

  const logoImage = (
    <img
      src={logoToDisplay}
      style={backgroundColor && hasBackground ? { backgroundColor } : {}}
      alt={alt}
      className={styles.imageStyling}
    />
  );

  const logo =
    link && Object.keys(link).length ? (
      <Link {...link} className={styles.imageStyling} role="img">
        {logoImage}
      </Link>
    ) : (
      logoImage
    );

  return (
    <div
      className={customCssClasses(semanticClassNames.root, ...customClassNames)}
      id={id}
    >
      {isLoadingData ? (
        <div className={styles.loader}>
          <ThreeDotsLoader />
        </div>
      ) : (
        logo
      )}
    </div>
  );
};

export default Logo;
