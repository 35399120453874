import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { IHamburgerMenuContainerDefinition } from '../documentManagement/HamburgerMenuContainer.definition';

import { IHamburgerMenuContainerProps } from '../HamburgerMenuContainer.props';
import {
  IHamburgerMenuContainerCarmiProps,
  props as viewerPropsMapper,
} from './HamburgerMenuContainer.mapper';

const props = composeMapper<
  IHamburgerMenuContainerProps,
  IHamburgerMenuContainerDefinition,
  IHamburgerMenuContainerCarmiProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { componentViewMode } = dependencies;

    return {
      ...viewerProps,
      shouldFocus: componentViewMode === 'preview',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
