import * as React from 'react';
import classNames from 'clsx';
import {
  customCssClasses,
  getAriaAttributes,
  getDataAttributes,
  getTabIndexAttribute,
} from '@wix/editor-elements-common-utils';
import {
  IResponsiveBoxProps,
  IContainerImperativeActions,
} from '../../Container/Container.types';
import commonStyles from '../../Container/viewer/shared/common.scss';
import repeaterSemanticClassNames from '../../Repeater/Repeater.semanticClassNames';
import { ARIA_LABEL_DEFAULT } from '../../Container/viewer/shared/constants';
import styles from './ResponsiveBox.scss';

// This container is used in responsive site
const ResponsiveBox: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IResponsiveBoxProps
> = (props, ref) => {
  const {
    id,
    className,
    containerRootClassName = '',
    customClassNames = [],
    children,
    role,
    onClick,
    onDblClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    hasPlatformClickHandler,
    translations,
    a11y = {},
    ariaAttributes = {},
    tabIndex,
    isRepeaterItem = false,
  } = props;
  const {
    'aria-label-interactions': ariaLabelInteractions,
    tabindex,
    ...a11yAttr
  } = a11y;

  if (ariaLabelInteractions) {
    a11yAttr['aria-label'] = translations?.ariaLabel || ARIA_LABEL_DEFAULT;
  }

  const rootElementRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        rootElementRef.current?.focus();
      },
      blur: () => {
        rootElementRef.current?.blur();
      },
    };
  });

  const getRootSemanticClassName = () => {
    if (isRepeaterItem) {
      return repeaterSemanticClassNames.repeaterItem;
    }
    if (props.semanticClassNames) {
      return props.semanticClassNames.root;
    }
    return '';
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      ref={rootElementRef}
      {...a11yAttr}
      {...getAriaAttributes({ ...ariaAttributes, role })}
      {...getTabIndexAttribute({ tabIndex, tabindex })}
      className={classNames(
        styles.root,
        styles.resetDesignAttributes,
        containerRootClassName,
        className,
        {
          [commonStyles.clickable]: hasPlatformClickHandler,
        },
        customCssClasses(...customClassNames, getRootSemanticClassName()),
      )}
      onDoubleClick={onDblClick}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          styles['css-background'],
          styles.bg,
          customCssClasses(...customClassNames, getRootSemanticClassName()),
        )}
      />
      {
        children() // contains: (1) ResponsiveContainer with relative children, (2) DynamicStructureContainer with pinned children
      }
    </div>
  );
};

export default React.forwardRef(ResponsiveBox);
