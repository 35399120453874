import React from 'react';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import repeaterSemanticClassNames from '@wix/thunderbolt-elements/components/Repeater/repeaterSemanticClassNames';
import ResponsiveContainer from '@wix/thunderbolt-elements/src/thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import type { IResponsiveRepeaterProps } from '../../../ResponsiveRepeater.types';
import {
  useSlideshowContext,
  useUpdateSlidesLength,
} from '../../../../Slideshow/SlideshowContext';
import { useIntersectionObserver } from '../../../../../common/hooks/useIntersectionObserver';
import styles from './Slideshow.scss';

const SlideshowSkin: React.FC<IResponsiveRepeaterProps> = props => {
  const {
    id,
    responsiveContainerProps,
    items,
    children,
    className,
    customClassNames = [],
  } = props;

  useUpdateSlidesLength(items.length);
  const {
    currentIndex,
    previousIndex,
    onAnimationEnd,
    navigatingForward,
    isSlideshowInViewport,
    setIsSlideshowInViewport,
  } = useSlideshowContext();

  const repeaterRef = React.useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    ref: repeaterRef,
    callback: entries => {
      entries.forEach(entry => setIsSlideshowInViewport(entry.isIntersecting));
    },
  });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        styles.container,
        className,
        customCssClasses(repeaterSemanticClassNames.root, ...customClassNames),
        {
          [styles.slideToPrev]:
            !navigatingForward && currentIndex !== previousIndex,
          [styles.slideToNext]:
            navigatingForward && currentIndex !== previousIndex,
        },
      )}
      ref={repeaterRef}
      tabIndex={-1}
    >
      <ResponsiveContainer
        {...responsiveContainerProps}
        ariaLive={isSlideshowInViewport ? 'polite' : 'off'}
        tabIndex={-1}
      >
        {() =>
          items.map((itemId, index) => {
            const isCurrent = index === currentIndex;
            const isPrevious = index === previousIndex;

            return (
              <div
                key={itemId}
                {...(isCurrent && { onAnimationEnd })}
                className={classNames(styles.item, {
                  [styles.current]: isCurrent,
                  [styles.prev]: isPrevious,
                })}
              >
                {children({
                  parentType: 'Repeater',
                  scopeId: itemId,
                  itemIndex: index,
                })}
              </div>
            );
          })
        }
      </ResponsiveContainer>
    </div>
  );
};

export default SlideshowSkin;
