import * as React from 'react';
import classNames from 'clsx';
import { LinkProps } from '@wix/editor-elements-definitions';
import { customCssClasses, keyCodes } from '@wix/editor-elements-common-utils';

import Link from '../../Link/viewer/Link';
import { IGridProps, GridColumn, GridRow } from '../Grid.types';
import {
  getRowValue,
  getLinkPropsPath,
  getRichTextHtmlPath,
  getCellWidth,
} from '../utils';
import {
  ColumnType,
  UserSelectionType,
  HeaderColumn,
  PaginationType,
  DataSource,
  DEFAULT_ROWS_PER_PAGE,
  LOAD_MORE_SCROLL_THRESHOLD,
} from '../constants';
import semanticClassNames from '../Grid.semanticClassNames';
import ImageCell from './ImageCell';
import DateCell from './DateCell';
import styles from './styles/Grid.scss';

export interface ITableBody {
  isVerticalScrollVisible(): boolean;
}

type ITableBodyProps = Pick<
  IGridProps,
  | 'dataSource'
  | 'pagination'
  | 'userSelectionType'
  | 'headerColumn'
  | 'dateFormat'
  | 'linkTarget'
  | 'columns'
  | 'columnWidthUnitMap'
  | 'columnLayout'
  | 'rows'
  | 'totalRowsCount'
  | 'lastLoadedRowsCount'
  | 'onLoadRows'
  | 'isLoading'
  | 'staticMediaUrl'
  | 'rowHeight'
  | 'rowHeightUnit'
  | 'selectedCell'
  | 'selectedRow'
> & {
  containerId: string;
  tableWidth?: number;
  onScroll: React.UIEventHandler;
  onSelectRow(selectedRow: number): void;
  onSelectCell(selectedCell: [number, number]): void;
};

type CellRenderer = (
  value: any,
  props: ITableBodyProps,
  column: GridColumn,
  rowIndex: number,
  columnIndex: number,
) => React.ReactNode;

const renderTextCell: CellRenderer = value => (
  <div className={styles.cellContentWrapper}>
    {value !== undefined ? String(value) : ''}
  </div>
);

const renderBoolCell: CellRenderer = value => (value ? 'Yes' : 'No');

const renderRichTextCell: CellRenderer = value => (
  <div
    className={styles.cellContentWrapper}
    dangerouslySetInnerHTML={{ __html: value }}
  />
);

const renderDateCell: CellRenderer = (value, { dateFormat }) => (
  <DateCell value={value} dateFormat={dateFormat} />
);

const renderImageCell: CellRenderer = (
  value,
  {
    staticMediaUrl,
    rowHeight,
    containerId,
    rowHeightUnit,
    columnWidthUnitMap,
    columnLayout,
  },
  column,
  rowIndex,
  colIndex,
) => (
  <ImageCell
    value={value}
    id={`image-${rowIndex}-${colIndex}-${containerId}`}
    containerId={containerId}
    staticMediaUrl={staticMediaUrl}
    cellWidth={column.width}
    cellHeight={rowHeight}
    columnLayout={columnLayout}
    widthUnit={columnWidthUnitMap?.[column.id] || 'px'}
    autoHeight={rowHeightUnit === 'auto'}
  />
);

const customCellRendererByType: Record<string, CellRenderer | undefined> = {
  [ColumnType.Bool]: renderBoolCell,
  [ColumnType.RichText]: renderRichTextCell,
  [ColumnType.Date]: renderDateCell,
  [ColumnType.Image]: renderImageCell,
};

const TableBody: React.ForwardRefRenderFunction<ITableBody, ITableBodyProps> = (
  props,
  ref,
) => {
  const {
    columns,
    columnWidthUnitMap,
    rows,
    userSelectionType,
    headerColumn,
    columnLayout,
    tableWidth,
    dataSource,
    totalRowsCount,
    lastLoadedRowsCount,
    pagination,
    isLoading,
    selectedRow,
    selectedCell,
    onLoadRows,
    onScroll,
    onSelectCell,
    onSelectRow,
  } = props;

  const tbodyRef = React.useRef<HTMLTableSectionElement>(null);

  const isVerticalScrollVisible = () => {
    const tbodyElement = tbodyRef.current;
    return tbodyElement
      ? tbodyElement.scrollHeight > tbodyElement.clientHeight
      : false;
  };

  React.useImperativeHandle(ref, () => ({
    isVerticalScrollVisible,
  }));

  const hasMoreDynamicDataRows =
    dataSource === DataSource.Dynamic &&
    pagination.type === PaginationType.Scroll &&
    (lastLoadedRowsCount === undefined || lastLoadedRowsCount > 0) &&
    (totalRowsCount === undefined || rows.length < totalRowsCount);

  const loadMoreRows = React.useCallback(() => {
    const startRow = rows.length;
    let endRow = startRow + (pagination.rowsPerPage || DEFAULT_ROWS_PER_PAGE);
    if (totalRowsCount !== undefined) {
      endRow = Math.min(endRow, totalRowsCount);
    }

    onLoadRows?.({ startRow, endRow });
  }, [rows, pagination.rowsPerPage, totalRowsCount, onLoadRows]);

  const handleScroll: React.UIEventHandler = event => {
    if (hasMoreDynamicDataRows && !isLoading) {
      const { currentTarget } = event;
      const { scrollHeight, scrollTop, clientHeight } = currentTarget;
      const remainingScrollHeight = scrollHeight - scrollTop - clientHeight;

      if (remainingScrollHeight <= LOAD_MORE_SCROLL_THRESHOLD) {
        loadMoreRows();
      }
    }

    onScroll(event);
  };

  React.useEffect(() => {
    // Users can setup dynamic data source with dataFetcher and also specify
    // small pagination.rowsPerPage value. In that case we keep loading more
    // rows until a vertical scroll bar is visible.
    if (!isLoading && hasMoreDynamicDataRows && !isVerticalScrollVisible()) {
      loadMoreRows();
    }
  }, [isLoading, hasMoreDynamicDataRows, loadMoreRows]);

  const className = classNames(styles.body, {
    [styles.selectRows]: userSelectionType === UserSelectionType.Row,
    [styles.selectCells]: userSelectionType === UserSelectionType.Cell,
    [styles.firstHeaderColumn]: headerColumn === HeaderColumn.First,
    [styles.lastHeaderColumn]: headerColumn === HeaderColumn.Last,
  });

  const hasRowSelection = userSelectionType === UserSelectionType.Row;
  const hasCellSelection = userSelectionType === UserSelectionType.Cell;

  const [selectedCellRowIndex, selectedCellColumnIndex] =
    (hasCellSelection && selectedCell) || [];

  const getRowSelectionHandler = (rowIndex: number) => () => {
    onSelectRow(rowIndex);
  };

  const getCellSelectionHandler =
    (cellColumnIndex: number, cellRowIndex: number) => () => {
      onSelectCell([cellRowIndex, cellColumnIndex]);
    };

  const handleCellKeydown: React.KeyboardEventHandler<
    HTMLTableDataCellElement
  > = (event): void => {
    if (
      selectedCellRowIndex === undefined ||
      selectedCellColumnIndex === undefined
    ) {
      return;
    }
    const nextCellsByKey: Record<number, [number, number]> = {
      [keyCodes.arrowUp]: [selectedCellRowIndex - 1, selectedCellColumnIndex],
      [keyCodes.arrowDown]: [selectedCellRowIndex + 1, selectedCellColumnIndex],
      [keyCodes.arrowLeft]: [selectedCellRowIndex, selectedCellColumnIndex - 1],
      [keyCodes.arrowRight]: [
        selectedCellRowIndex,
        selectedCellColumnIndex + 1,
      ],
    };
    const [nextCellRowIndex, nextCellColumnIndex] =
      nextCellsByKey[event.keyCode] || [];
    if (
      nextCellRowIndex === undefined ||
      nextCellColumnIndex === undefined ||
      !rows[nextCellRowIndex] ||
      !columns[nextCellColumnIndex]
    ) {
      return;
    }
    event.preventDefault();
    focusCell(nextCellColumnIndex, nextCellRowIndex);
  };

  const focusCell = (columnIndex: number, rowIndex: number) => {
    const selectedCellNode = tbodyRef.current?.querySelector(
      `tr:nth-child(${rowIndex + 1}) td:nth-child(${columnIndex + 1})`,
    );
    (selectedCellNode as HTMLElement)?.focus();
  };

  const renderRow = (row: GridRow = {}, rowIndex?: number) => (
    <tr
      key={rowIndex}
      className={classNames(
        styles.bodyRow,
        customCssClasses(semanticClassNames.row),
        {
          [styles.selectedRow]: selectedRow === rowIndex,
        },
      )}
      style={{ width: tableWidth }}
      onClick={
        hasRowSelection && rowIndex !== undefined
          ? getRowSelectionHandler(rowIndex)
          : undefined
      }
    >
      {columns.map((column, columnIndex) => {
        const { type = ColumnType.String, dataPath, width, linkPath } = column;
        const valueDataPath =
          dataPath && type === ColumnType.RichText
            ? getRichTextHtmlPath(dataPath)
            : dataPath;
        const value = getRowValue(row, valueDataPath);
        const renderCell = customCellRendererByType[type] ?? renderTextCell;
        const cellContent =
          rowIndex !== undefined
            ? renderCell(value, props, column, rowIndex, columnIndex)
            : undefined;
        const linkProps: LinkProps | undefined = linkPath
          ? getRowValue(row, getLinkPropsPath(linkPath))
          : undefined;
        const isSelected =
          rowIndex === selectedCellRowIndex &&
          columnIndex === selectedCellColumnIndex;

        const widthUnit = columnWidthUnitMap?.[column.id] || 'px';

        return (
          <td
            key={columnIndex}
            className={classNames(
              styles.bodyCell,
              customCssClasses(semanticClassNames.cell),
              {
                [styles.selectedCell]: isSelected,
              },
            )}
            style={{
              width: getCellWidth({ width, widthUnit, columnLayout }),
            }}
            {...(hasCellSelection &&
              rowIndex !== undefined && {
                tabIndex: 0,
                onFocus: !isSelected
                  ? getCellSelectionHandler(columnIndex, rowIndex)
                  : undefined,
                // For compatibility reasons every click should trigger new onCellSelect event even
                // when the cell is already selected (works differently compared to onRowSelect).
                onMouseDown: isSelected
                  ? getCellSelectionHandler(columnIndex, rowIndex)
                  : undefined,
                onKeyDown: handleCellKeydown,
              })}
          >
            {linkProps ? (
              <Link {...linkProps} className={styles.cellContent}>
                {cellContent}
              </Link>
            ) : (
              <div className={styles.cellContent}>{cellContent}</div>
            )}
          </td>
        );
      })}
    </tr>
  );

  return (
    <tbody
      ref={tbodyRef}
      className={classNames(
        className,
        customCssClasses(semanticClassNames.body),
      )}
      onScroll={handleScroll}
    >
      {rows.map(renderRow)}
      {hasMoreDynamicDataRows && !!rows.length && renderRow()}
    </tbody>
  );
};

export default React.memo(React.forwardRef(TableBody));
