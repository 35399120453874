import classnames from 'clsx';
import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../../../Container.semanticClassNames';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './VerticalRibbonArea.scss';

const VerticalRibbonArea: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={classnames(styles.root, props.className)}
      renderSlot={({ containerChildren }) => (
        <>
          <div
            className={classnames(
              styles.bg,
              customCssClasses(
                ...(props.customClassNames || []),
                semanticClassNames.root,
              ),
            )}
          />
          <i className={styles.i}>
            <b className={styles.l} />
            <b className={styles.r} />
          </i>
          {containerChildren}
        </>
      )}
    />
  );
};

export default React.forwardRef(VerticalRibbonArea);
