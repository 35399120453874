import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import React from 'react';
import {
  ISelectableContainerImperativeActions,
  ISelectableContainerPreviewWrapperProps,
  ISelectableContainerProps,
  ISelectableContainerWithRefProps,
} from '../types';
import { selectionPreviewStateUtils } from '../utils/selectionPreviewStateUtils';

function withComponentPreview(
  WrappedComponent: React.ComponentType<ISelectableContainerWithRefProps>,
): IComponentPreviewWrapper<
  ISelectableContainerProps,
  ISelectableContainerPreviewWrapperProps
> {
  return React.forwardRef<
    ISelectableContainerImperativeActions,
    ISelectableContainerProps
  >(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        ISelectableContainerProps,
        ISelectableContainerPreviewWrapperProps
      >,
      ref,
    ) => {
      const { componentViewMode, compPreviewState = '' } = previewWrapperProps;
      const isEditor = componentViewMode === 'editor';

      return (
        <WrappedComponent
          {...viewerProps}
          isEditor={isEditor}
          ref={ref}
          selected={
            viewerProps.selected ||
            selectionPreviewStateUtils.isPreviewStateSelection(compPreviewState)
          }
        />
      );
    },
  );
}

export default (
  ViewerComponent: React.ComponentType<ISelectableContainerWithRefProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
