import {
  withCompInfo,
  withUiTypeCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  VectorImageDefinition,
  VectorImageProps,
} from '@wix/thunderbolt-components-native';
import {
  IVectorImageMapperProps,
  IVectorImageStateRefs,
  IVectorImageUiTypes,
} from '../VectorImage.types';

export const props = withCompInfo<
  IVectorImageMapperProps,
  VectorImageDefinition,
  VectorImageProps
>()(
  [
    'fullNameCompType',
    'language',
    'mainPageId',
    'compId',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

export const stateRefs = withStateRefs<IVectorImageStateRefs>()(
  ['refApi', 'compBehaviors'],
  ({ refApi, compBehaviors }) => {
    const menuContainerId = compBehaviors?.[0]?.behavior.targetId;

    return {
      reportBi: refApi.businessLogger.reportBi(),
      ...(menuContainerId && {
        toggle: refApi.menuContainer?.toggle(menuContainerId),
      }),
    };
  },
);

export const uiType = withUiTypeCompInfo<
  IVectorImageUiTypes,
  VectorImageDefinition
>()(['compBehaviors'], ({ compBehaviors }) => {
  const hasClickBehavior = compBehaviors[0]?.action.name === 'click';

  return hasClickBehavior ? 'VectorButton' : undefined;
});

export default createComponentMapperModel({ props, stateRefs, uiType });
