import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  SpotifyPlayerDefinition,
  SpotifyPlayerCSSVars,
  SpotifyPlayerSize,
  SpotifyPlayerMapperProps,
  SpotifyPlayerStateRefs,
} from '../SpotifyPlayer.types';
import {
  TRANSLATION_FEATURE,
  TRANSLATION_KEY,
  consetPolicyKeys,
} from '../constants';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const props = withCompInfo<
  SpotifyPlayerMapperProps,
  SpotifyPlayerDefinition
>()(
  ['compData', 'compProps', 'translate', 'uiType', 'deviceType'],
  ({ compData, compProps, translate, uiType, deviceType }) => {
    let iframeSrc = null;

    const { uri } = compData;
    const { color, style } = compProps;
    if (uri !== '') {
      iframeSrc = `https://embed.spotify.com/?${new URLSearchParams({
        uri,
        color,
        style,
      })}`;
    }

    return {
      iframeSrc,
      translations: {
        ariaLabel: translate(TRANSLATION_FEATURE, TRANSLATION_KEY),
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
    };
  },
);

export const css = withCompInfo<
  SpotifyPlayerCSSVars,
  SpotifyPlayerDefinition
>()(
  ['compData', 'compProps', 'compLayout'],
  ({ compData, compProps, compLayout }) => {
    let placeholderDisplay = 'none';
    const sizeLimits: Record<
      SpotifyPlayerSize,
      Record<'minW' | 'minH', number>
    > = {
      compact: { minW: 250, minH: 80 },
      large: { minW: 250, minH: 330 },
    };

    const width = compLayout.width || 0;
    let height = sizeLimits[compProps.size].minH;

    if (compProps.size === 'large') {
      height += width - sizeLimits[compProps.size].minW;
    }

    if (compData.uri === '') {
      placeholderDisplay = 'block';
    }

    return {
      '--placeholder-display': placeholderDisplay,
      width: `${width}px`,
      height: `${height + 2}px`,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof SpotifyPlayerStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
