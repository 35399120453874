import { getScaledFont } from '@wix/editor-elements-common-utils';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  CheckboxGroupProps as CheckboxGroupPlatformProps,
  CheckboxGroupDefinition,
} from '@wix/thunderbolt-components';

import { generateCheckboxCSSVars } from '../../Checkbox/viewer/Checkbox.mapper';
import {
  ICheckboxGroupStateValues,
  CheckboxGroupCSSVars,
} from '../CheckboxGroup.types';

export const props = withCompInfo<
  CheckboxGroupPlatformProps,
  CheckboxGroupDefinition,
  CheckboxGroupPlatformProps
>()(
  ['fullNameCompType', 'language', 'compId', 'trackClicksAnalytics'],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

export const css = withCompInfo<
  CheckboxGroupCSSVars,
  CheckboxGroupDefinition
>()(
  [
    'compId',
    'compLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'structure',
    'siteFontsSpxResolved',
  ],
  compInfo => {
    const {
      compProps,
      styleProperties: { txtlblrq },
      compData,
    } = compInfo;

    const { layout, alignment, buttonsMargin, labelMobileFontSize } = compProps;

    const labelMargin =
      (compData.label?.length > 0 && compProps.labelMargin) || 0;
    const notTransparentText = txtlblrq && txtlblrq !== 'transparent';

    const fnt2 = getScaledFont(compInfo, 'fnt2', labelMobileFontSize);
    const groupLabelMargin = `0 0 ${labelMargin}px 0`;

    const itemsMarginByAlignment = {
      right: `0 0 0 ${buttonsMargin}px`,
      left: `0 ${buttonsMargin}px 0 0`,
    };

    const varsByLayout = {
      horizontal: {
        '--itemsFlexDirection': 'row',
        '--itemsMargin': itemsMarginByAlignment[alignment],
      },
      vertical: {
        '--itemsFlexDirection': 'column',
        '--itemsMargin': `0 0 ${buttonsMargin}px 0`,
        '--itemsWidth': '100%',
      },
    } as const;

    return {
      '--fnt2': fnt2,
      '--groupLabelMargin': groupLabelMargin,
      '--textAlign': alignment,
      '--requiredIndicationDisplay': notTransparentText ? 'inline' : 'none',
      ...varsByLayout[layout],
      ...generateCheckboxCSSVars(compInfo),
    };
  },
);

const stateRefs = withStateRefsValues<keyof ICheckboxGroupStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
