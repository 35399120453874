import { customCssClasses } from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import * as React from 'react';

import { PaginationStyle, TestId } from '../constants';
import semanticClassNames from '../Grid.semanticClassNames';
import { GridPagination, IGridProps } from '../Grid.types';
import { PaginationArrow } from './PagginationArrow';
import styles from './styles/Grid.scss';

type IPaginationProps = {
  currentPage: number;
  maxPages?: number;
  onChangePage(page: number): void;
  paginationStyle: GridPagination['style'];
  forceNextEnabled: IGridProps['forceNextEnabled'];
  forcePreviousEnabled: IGridProps['forcePreviousEnabled'];
};

const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  maxPages,
  onChangePage,
  paginationStyle,
  forceNextEnabled,
  forcePreviousEnabled,
}) => {
  const isNextDisabled =
    typeof forceNextEnabled !== 'undefined'
      ? !forceNextEnabled
      : maxPages !== undefined && currentPage >= maxPages;
  const isPrevDisabled =
    typeof forcePreviousEnabled !== 'undefined'
      ? !forcePreviousEnabled
      : currentPage <= 1;
  const handleNextClick = () =>
    !isNextDisabled && onChangePage(currentPage + 1);
  const handlePrevClick = () =>
    !isPrevDisabled && onChangePage(currentPage - 1);
  const currentPageText = currentPage.toLocaleString();
  const maxPageText = maxPages ? maxPages.toLocaleString() : 'more';

  const PaginationStyleMap = {
    text: `Page ${currentPageText} of ${maxPageText}`,
    number: currentPageText,
  };

  return (
    <div
      className={classNames(
        styles.pagination,
        customCssClasses(semanticClassNames.pagination),
      )}
      data-testid={TestId.Pagination}
    >
      <button
        className={styles.paginationButton}
        onClick={handlePrevClick}
        disabled={isPrevDisabled}
        aria-label="Previous"
        data-testid={TestId.PagePrev}
      >
        <PaginationArrow />
      </button>
      {paginationStyle !== PaginationStyle.Simple && (
        <div className={styles.pages} data-testid={TestId.PaginationText}>
          {PaginationStyleMap[paginationStyle ?? PaginationStyle.Text]}
        </div>
      )}
      <button
        className={styles.paginationButton}
        onClick={handleNextClick}
        disabled={isNextDisabled}
        aria-label="Next"
        data-testid={TestId.PageNext}
      >
        <PaginationArrow isNext />
      </button>
    </div>
  );
};

export default Pagination;
