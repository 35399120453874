import React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IAdminLoginButtonProps } from '../AdminLoginButton.types';
import TextOnlyButtonSkin from '../../SiteButton/viewer/skinComps/BaseButton/TextOnlyButtonSkin.skin';

const AdminLoginButton: React.FC<IAdminLoginButtonProps> = props => {
  const { id, label, href, className, onMouseEnter, onMouseLeave } = props;

  return (
    <TextOnlyButtonSkin
      id={id}
      {...getDataAttributes(props)}
      className={className}
      label={label}
      isDisabled={false}
      link={{ href, target: '_self' }}
      hasPlatformClickHandler={false}
      onClick={undefined}
      onDblClick={undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default AdminLoginButton;
