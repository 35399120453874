import React from 'react';
import {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../StylableButton/StylableButton.types';
import StylableButton from '../../../StylableButton/viewer/StylableButton';
import { useHamburgerMenuContext } from '../../HamburgerMenuContext';
import semanticClassNames from '../HamburgerCloseButton.semanticClassNames';
import {
  st,
  classes as stylableButtonClasses,
} from '../../../StylableButton/viewer/StylableButton.component.st.css';

const HamburgerCloseButton: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps
> = ({ id, a11y = {}, className, ...props }, ref) => {
  const { setIsMenuOpen } = useHamburgerMenuContext();
  return (
    <StylableButton
      {...props}
      id={id}
      className={st(stylableButtonClasses.root, className)}
      ref={ref}
      a11y={a11y}
      onClick={() => setIsMenuOpen(false)}
      semanticClassNames={semanticClassNames}
    />
  );
};

export default React.forwardRef(HamburgerCloseButton);
