import * as React from 'react';
import classnames from 'clsx';
import { ILink, IPrivacyNoteType } from '../SignUpDialog.types';
import { IDirection } from '../../MemberLoginDialog/MemberLoginDialog.types';
import { SiteMembersTranslations } from '../../MemberLoginDialog/viewer/utils';
import style from './style/style.scss';
import { SignUpDialogTranslationKeys as keys, testIds } from './constants';
import { PolicyLink } from './PolicyLink';

interface CommunityProps extends SiteMembersTranslations {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isCodeOfConductNeeded: boolean;
  codeOfConductLink: ILink;
  directionByLanguage: IDirection;
  onCloseDialogCallback: () => void;
  privacyNoteType?: IPrivacyNoteType;
}

const CheckboxPrivacyNote: React.FC<
  Omit<CommunityProps, 'privacyNoteType'>
> = ({
  checked,
  setChecked,
  translations,
  directionByLanguage,
  isCodeOfConductNeeded,
  codeOfConductLink,
  onCloseDialogCallback,
}) => {
  const [isReadMoreOpen, setReadMoreOpen] = React.useState(false);

  const label = translations[keys.community.label];
  const readMore = translations[keys.community.read.more];
  const readLess = translations[keys.community.read.less];
  const content = translations[keys.community.content];
  const codeOfConductLinkLabel = translations[keys.community.codeOfConductLink];
  const toggleCommunity = () => setChecked(!checked);
  const toggleReadMore = () => setReadMoreOpen(!isReadMoreOpen);
  return (
    <div className={style.communityCheckboxWrapper}>
      <div
        className={classnames(style.communityCheckboxFirstRow, {
          [style.rtl]: directionByLanguage === 'rtl',
        })}
      >
        <label data-testid={testIds.community.label}>
          <input
            name="isGoing"
            type="checkbox"
            checked={checked}
            onChange={toggleCommunity}
          />
          {label}
        </label>
        <button
          className={style.dialogLink}
          onClick={toggleReadMore}
          data-testid={testIds.community.readMore}
          type="button"
        >
          {isReadMoreOpen ? readLess : readMore}
        </button>
      </div>
      {isReadMoreOpen && (
        <div>
          <span data-testid={testIds.community.content}>{content}</span>
          {isCodeOfConductNeeded && (
            <PolicyLink
              onCloseDialogCallback={onCloseDialogCallback}
              privacyLinkLabel={codeOfConductLinkLabel}
              testId={testIds.community.codeOfConductLink}
              link={codeOfConductLink}
              className={style.codeOfConductLink}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const Community: React.FC<CommunityProps> = ({
  checked,
  setChecked,
  directionByLanguage,
  isCodeOfConductNeeded,
  codeOfConductLink,
  onCloseDialogCallback,
  privacyNoteType,
  translations,
}) => {
  return privacyNoteType === IPrivacyNoteType.Note ? (
    <div className={style.communityCheckboxWrapper}>
      <span>{translations[keys.community.content]}</span>
    </div>
  ) : (
    <CheckboxPrivacyNote
      checked={checked}
      setChecked={setChecked}
      translations={translations}
      directionByLanguage={directionByLanguage}
      isCodeOfConductNeeded={isCodeOfConductNeeded}
      codeOfConductLink={codeOfConductLink}
      onCloseDialogCallback={onCloseDialogCallback}
    />
  );
};
