import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { VideoLegacyDefinition, VideoLegacyProps } from '../VideoLegacy.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { componentNameKey, componentNameDefault } from './constants';

export const props = withCompInfo<VideoLegacyProps, VideoLegacyDefinition>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'skin',
    'uiType',
    'deviceType',
    'translate',
  ],
  ({
    compData,
    compProps,
    compLayout,
    skin,
    uiType,
    deviceType,
    translate,
  }) => {
    return {
      skin: skin || 'VideoDefault',
      videoId: compData.videoId,
      videoType: compData.videoType,
      showControls: compProps.showControls,
      autoplay: compProps.autoplay,
      loop: compProps.loop,
      showInfo: compProps.showinfo,
      youtubeConfig: {
        enablejsapi: compProps.enablejsapi,
        lightTheme: compProps.lightTheme,
      },
      facebookConfig: {
        width: `${compLayout!.width!}px`,
        height: `${compLayout!.height!}px`,
      },
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          componentNameKey,
          componentNameDefault,
          translate,
        ),
      },
    };
  },
);
export const stateRefs = withStateRefsValues([...getConsentPolicyStateRefs()]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
