export const elementTypes = [
  'all',
  'geometry',
  'geometry.fill',
  'geometry.stroke',
  'labels',
  'labels.icon',
  'labels.text',
  'labels.text.fill',
  'labels.text.stroke',
];

export const featureTypes = [
  'administrative',
  'administrative.country',
  'administrative.land_parcel',
  'administrative.locality',
  'administrative.neighborhood',
  'administrative.province',
  'all',
  'landscape',
  'landscape.man_made',
  'landscape.natural',
  'landscape.natural.landcover',
  'landscape.natural.terrain',
  'poi',
  'poi.attraction',
  'poi.business',
  'poi.government',
  'poi.medical',
  'poi.park',
  'poi.place_of_worship',
  'poi.school',
  'poi.sports_complex',
  'road',
  'road.arterial',
  'road.highway',
  'road.highway.controlled_access',
  'road.local',
  'transit',
  'transit.line',
  'transit.station',
  'transit.station.airport',
  'transit.station.bus',
  'transit.station.rail',
  'water',
];

export const pinIcon =
  'https://static.parastorage.com/services/santa-resources/resources/editor/designPanel/panelTypes/default_pin_icon.png';

export const language = [
  'userLang',
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'zh',
  'th',
  'uk',
  'tr',
];

export const metaDescriptions = {
  elementType: {
    description:
      'Selects the element type to which a styler should be applied. An element type distinguishes between the different representations of a feature.',
  },
  featureType: {
    description:
      'Selects the feature, or group of features, to which a styler should be applied.',
  },
  stylers: {
    description:
      "The style rules to apply to the selectors. The rules are applied to the map's elements in the order they are listed in this array.",
    properties: {
      color:
        "Sets the color of the feature. Valid values: An RGB hex string, i.e. '#ff0000'.",
      gamma:
        'Modifies the gamma by raising the lightness to the given power.Valid values: Floating point numbers, [0.01, 10], with 1.0 representing no change.',
      hue: "Sets the hue of the feature to match the hue of the color supplied. Note that the saturation and lightness of the feature is conserved, which means that the feature will not match the color supplied exactly. Valid values: An RGB hex string, i.e. '#ff0000'.",
      invert_lightness:
        'A value of true will invert the lightness of the feature while preserving the hue and saturation.',
      lightness:
        'Shifts lightness of colors by a percentage of the original value if decreasing and a percentage of the remaining value if increasing. Valid values: [-100, 100].',
      saturation:
        'Shifts the saturation of colors by a percentage of the original value if decreasing and a percentage of the remaining value if increasing. Valid values: [-100, 100].',
      visibility:
        "Sets the visibility of the feature. Valid values: 'on', 'off' or 'simplified'.",
      weight:
        'Sets the weight of the feature, in pixels. Valid values: Integers greater than or equal to zero.',
    },
  },
  showZoom: {
    description: 'show zoom control',
  },
  fullscreenControl: {
    description: 'show fullscreen control',
  },
  showPosition: {
    description: 'show position control',
  },
  showStreetView: {
    description: 'show Street View control"',
  },
  showMapType: {
    description: 'show map type control',
  },
  zoom: {
    description: 'default map zoom level',
  },
  mapDragging: {
    description: 'show map type control',
  },
  showDirectionsLink: {
    description: 'show directions link in infoWindow',
  },
  mapType: {
    description: 'set map type',
  },
  language: {
    description:
      'Possible languages taken from https://developers.google.com/maps/faq?hl=en#languagesupport',
  },
};

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_GoogleMap',
  componentNameDefault: 'Google Maps',
  companyNameKey: 'CookieConsent_Dataset_Company_Google',
  companyNameDefault: 'Google',
};
