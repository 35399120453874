import { TextAlignment } from '@wix/editor-elements-definitions';
import { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import {
  getScaledFont,
  getInputHeight,
  getRequiredIndicationDisplay,
} from '@wix/editor-elements-common-utils';
import {
  ComboBoxInputNavigationBorderVars,
  ComboBoxInputNavigationCSSVars,
} from '../components/ComboBoxInput/ComboBoxInput.types';

export const getComboBoxInputNavigationCSSVars = (
  {
    compProps,
    compSingleLayout,
    styleProperties,
    compLayout,
    isMobileView,
    siteFonts,
  }: Pick<
    CompInfo,
    | 'compProps'
    | 'styleProperties'
    | 'compLayout'
    | 'isMobileView'
    | 'siteFonts'
  > &
    Partial<Pick<CompInfo, 'compSingleLayout'>>,
  alignText: TextAlignment,
  borders: ComboBoxInputNavigationBorderVars,
): ComboBoxInputNavigationCSSVars => {
  const isRightAligned = alignText === 'right';

  return {
    '--direction': isRightAligned ? 'rtl' : 'ltr',
    '--arrowInsetInlineStart': 'auto',
    '--arrowInsetInlineEnd': '0',
    '--align': alignText,
    '--textPadding_start': `20px`,
    '--textPadding_end': `45px`,
    '--fnt': getScaledFont(
      { styleProperties, compLayout, compProps, isMobileView, siteFonts },
      'fnt',
    ),
    '--inputHeight': getInputHeight({
      inputHeightProps: compProps,
      compLayout,
      compSingleLayout,
      isMobileView,
    }),
    '--requiredIndicationDisplay':
      getRequiredIndicationDisplay(styleProperties),
    '--borderColor': 'rgba(0, 0, 0, 0.2)',
    ...borders,
  };
};
