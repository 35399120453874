import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { WPhotoDefinition } from '@wix/thunderbolt-components';
import { IWPhotoMapperProps, IWPhotoStateValues } from '../WPhoto.types';

export const props = withCompInfo<
  IWPhotoMapperProps,
  WPhotoDefinition,
  IWPhotoMapperProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'trackClicksAnalytics',
  ],
  ({ experiments, ...compPropsRest }, carmiData) => {
    return {
      ...carmiData,
      ...compPropsRest,
      lazyLoadImgExperimentOpen:
        !!experiments?.['specs.thunderbolt.lazyLoadImages'],
    };
  },
);

const stateRefs = withStateRefsValues<keyof IWPhotoStateValues>(['reportBi']);

export default createComponentMapperModel({ props, stateRefs });
