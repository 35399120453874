import * as React from 'react';
import classNames from 'clsx';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import { FreemiumBannerResponsiveProps } from '../FreemiumBannerResponsive.types';
import style from './style/FreemiumBannerResponsiveCommon.scss';

const FreemiumBannerResponsiveCommon: React.FC<
  FreemiumBannerResponsiveProps
> = props => {
  const {
    className,
    id = 'WIX_ADS',
    textBeforeLogo,
    textAfterLogo,
    useOverlay = false,
    direction,
    href = '',
    children,
    styles,
  } = props;

  return (
    <div
      id={id}
      className={classNames(
        className,
        style.freemiumBannerResponsiveCommon,
        styles?.root,
        style[direction],
        useOverlay ? style.overlay : '',
      )}
    >
      {useOverlay ? (
        <div data-testid={TestIds.overlay} className={style.overlay} />
      ) : (
        <Link
          className={classNames(style.link, styles?.link)}
          href={href}
          target="_blank"
          rel="nofollow"
        >
          <span className={classNames(style.text, styles?.text)}>
            {textBeforeLogo}
            {children}
            {textAfterLogo}
          </span>
        </Link>
      )}
    </div>
  );
};

export default FreemiumBannerResponsiveCommon;
