import React from 'react';
import Page from '../../Page';
import { SkinPageProps } from '../SkinPage';
import skinsStyles from './styles/InnerShadowPageSkin.scss';
import BasePageSkin, { BasePageSkinProps } from './BasePageSkin';

const InnerShadowPageSkin: React.FC<
  Omit<BasePageSkinProps, 'skinsStyle'>
> = props => <BasePageSkin {...props} skinsStyle={skinsStyles} />;

const InnerShadowPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={InnerShadowPageSkin} />
);

export default InnerShadowPage;
