import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  IMegaMenuContainerItemDefinition,
  MegaMenuContainerItemProps as PlatformProps,
} from '../MegaMenuContainerItem.types';

export const props = withCompInfo<
  PlatformProps,
  IMegaMenuContainerItemDefinition,
  PlatformProps
>()([], (_, carmiData) => {
  return { ...carmiData };
});
export const css = withCompInfo()([], () => {
  return {
    '--rd': '0',
    '--brw': '0',
    '--shd': 'none',
    '--bg': 'transparent',
  };
});

export default createComponentMapperModel({ props, css });
