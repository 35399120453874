import type {
  Component,
  MapperArgs,
} from '@wix/editor-elements-types/thunderbolt';
import type { DeviceType } from '@wix/thunderbolt-ssr-api';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { DatePickerCarmiProps } from '@wix/thunderbolt-components-native';
import {
  getInputHeight,
  getLabelPadding,
  getRequiredIndicationDisplay,
  getScaledFont,
  isSupportSpExperimentOn,
} from '@wix/editor-elements-common-utils';
import { SingleLayoutData } from '@wix/thunderbolt-becky-types';
import type {
  IDatePickerMapperProps,
  DatePickerDefinition,
  DatePickerCSSVars,
  DatePickerCssCarmiData,
} from '../DatePicker.types';
import { calendarButtonPositionVars } from '../../DatePickerCalendar/viewer/DatePickerCalendar.mapper';
import { getAllTranslations } from '../utils';

export const isMobileViewOrDevice = (
  isMobileView: boolean,
  deviceType: DeviceType,
) => isMobileView || deviceType === 'Smartphone';

export const props = withCompInfo<
  IDatePickerMapperProps,
  DatePickerDefinition,
  DatePickerCarmiProps
>()(
  [
    'compData',
    'compProps',
    'skin',
    'isMobileView',
    'hasResponsiveLayout',
    'deviceType',
    'experiments',
    'compId',
    'translate',
  ],
  (
    {
      compData,
      compProps,
      skin,
      isMobileView,
      hasResponsiveLayout,
      deviceType,
      experiments,
      compId,
      translate,
    },
    carmiProps,
  ) => {
    const { templateId } = carmiProps;
    const {
      placeholder: dataPlaceholder,
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges,
      disabledDateRanges,
      disabledDaysOfWeek,
      label,
    } = compData;

    const {
      placeholder: propsPlaceholder,
      defaultTextType,
      isDisabled,
      required,
      readOnly,
      dateFormat,
      dayToStart,
    } = compProps;

    const withCalendarPortal =
      experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
      experiments['specs.thunderbolt.DatePickerPortal'] === true;

    const isMobile = isMobileViewOrDevice(isMobileView, deviceType);

    const normalizedSkin = skin || 'DatePickerDefaultSkin';

    const translations = getAllTranslations(translate);

    return {
      skin: normalizedSkin,
      label,
      placeholder:
        defaultTextType === 'today'
          ? ''
          : dataPlaceholder || propsPlaceholder || '',
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges:
        !enabledDateRanges || enabledDateRanges.length === 0
          ? null
          : enabledDateRanges,
      disabledDateRanges: disabledDateRanges || [],
      disabledDaysOfWeek,
      dateFormat,
      useTodayAsDefaultValue: defaultTextType === 'today',
      weekStartDay: dayToStart === 'Sunday' ? 0 : 1,
      readOnly,
      required,
      isDisabled,
      isCompactMode: isMobile,
      isResponsive: hasResponsiveLayout,
      timeZone: null,
      withCalendarPortal,
      templateId,
      compId,
      translations,
    };
  },
);

const inputWrapperAlignVar = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--inputWrapperAlign': 'right' as 'right',
      };
    default:
      return {
        '--inputWrapperAlign': 'left' as 'left',
      };
  }
};

const inputPaddingVar = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
  textPadding: DatePickerDefinition['property']['textPadding'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--inputPadding': `1px ${textPadding}px 1px 2px`,
      };
    default:
      return {
        '--inputPadding': `1px 2px 1px ${textPadding}px`,
      };
  }
};

const editorSpecificStyling = ({
  compProps,
  compLayout,
  compSingleLayout,
  isMobileView,
  hasResponsiveLayout,
}: {
  compProps: DatePickerDefinition['property'];
  compLayout: Component['layout'];
  compSingleLayout?: SingleLayoutData;
  isMobileView: boolean;
  hasResponsiveLayout: boolean;
}) => {
  if (!hasResponsiveLayout) {
    return {
      '--inputWrapperHeight': getInputHeight({
        inputHeightProps: compProps,
        compLayout,
        compSingleLayout,
        isMobileView,
      }),
      height: 'auto' as 'auto',
    };
  }
  return {};
};

export const getCss = (
  compInfo: MapperArgs<
    | 'siteFonts'
    | 'compData'
    | 'compProps'
    | 'formatCssValue'
    | 'hasResponsiveLayout'
    | 'compLayout'
    | 'compSingleLayout'
    | 'styleProperties'
    | 'isMobileView'
    | 'experiments'
    | 'siteFontsSpxResolved',
    DatePickerDefinition
  >,
  cssVars: DatePickerCssCarmiData,
): DatePickerCSSVars => {
  const {
    compProps,
    compLayout,
    compSingleLayout,
    styleProperties,
    isMobileView,
    hasResponsiveLayout,
    experiments,
  } = compInfo;

  const {
    textAlignment,
    textPadding,
    labelMargin,
    labelMobileFontSize,
    inputMobileFontSize,
  } = compProps as {
    textAlignment: 'left' | 'right';
    textPadding: number;
    labelMargin: number;
    labelMobileFontSize: number;
    inputMobileFontSize: number;
  };

  const fonts = isSupportSpExperimentOn(experiments)
    ? {
        ...cssVars,
        '--fntlbl': getScaledFont(compInfo, 'fntlbl', labelMobileFontSize, 16),
        '--fnt': getScaledFont(compInfo, 'fnt', inputMobileFontSize, 13),
      }
    : cssVars;

  return {
    ...fonts,
    '--textAlign': textAlignment,
    '--dir': (textAlignment === 'right' ? 'rtl' : 'ltr') as 'ltr' | 'rtl',
    '--labelMarginBottom': `${labelMargin}px`,
    '--labelPadding': getLabelPadding(compProps) || 'auto',
    '--requiredIndicationDisplay':
      getRequiredIndicationDisplay(styleProperties),
    ...inputWrapperAlignVar(textAlignment),
    ...inputPaddingVar(textAlignment, textPadding),
    '--inputWrapperAlignItems':
      textAlignment === 'right'
        ? ('flex-end' as const)
        : ('flex-start' as const),
    ...editorSpecificStyling({
      compProps,
      compLayout,
      compSingleLayout,
      isMobileView,
      hasResponsiveLayout,
    }),
    ...calendarButtonPositionVars(textAlignment),
  };
};

export const css = withCompInfo<
  DatePickerCSSVars,
  DatePickerDefinition,
  DatePickerCssCarmiData
>()(
  [
    'compProps',
    'compLayout',
    'compSingleLayout',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
  ],
  (compInfo, cssVars) => {
    return getCss(compInfo, cssVars);
  },
);

const stateRefs = withStateRefsValues([
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'setSiteScrollingBlocked',
  'scopedClassName',
]);

export default createComponentMapperModel({ props, css, stateRefs });
