import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getBrightnessCSSVars,
  isSupportSpExperimentOn,
} from '@wix/editor-elements-common-utils';
import { CompInfo } from '@wix/thunderbolt-components-native';
import {
  carmiCssMapperMigrationSpec,
  A11Y_SUBMENU_INDICATION_DEFAULT,
  A11Y_SUBMENU_INDICATION_KEY,
  A11Y_SUBMENU_INDICATION_NAMESPACE,
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../constants';
import {
  DropDownMenuDefinition,
  DropDownMenuPlatformProps,
  DropDownMenuCarmiData,
  DropDownMenuStateRefs,
  DropDownMenuMapperArgs,
  TextAlignment,
  DropDownMenuCSSVars,
  DropDownMenuBrightnessCSSVars,
} from '../DropDownMenu.types';
import { getComboBoxInputNavigationCSSVars } from '../../../utils/comboBox';

export const props = withCompInfo<
  DropDownMenuPlatformProps,
  DropDownMenuDefinition,
  DropDownMenuCarmiData
>()(
  ['isQaMode', 'fullNameCompType', 'translate'],
  ({ isQaMode, fullNameCompType, translate }, carmiData) => {
    return {
      ...carmiData,
      isQaMode,
      fullNameCompType,
      translations: {
        subMenuIndication:
          translate(
            A11Y_SUBMENU_INDICATION_NAMESPACE,
            A11Y_SUBMENU_INDICATION_KEY,
          ) || A11Y_SUBMENU_INDICATION_DEFAULT,
        ariaLabel:
          translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY) || ARIA_LABEL_DEFAULT,
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof DropDownMenuStateRefs>([
  'currentUrl',
]);

const calcArrowBorderWidth = (alignText: TextAlignment) => {
  const isRightAligned = alignText === 'right';
  const arrowLeftBorderWidth = isRightAligned ? '0' : '1px';
  const arrowRightBorderWidth = isRightAligned ? '1px' : '0';
  return `0 ${arrowRightBorderWidth} 0 ${arrowLeftBorderWidth}`;
};

const getComboBoxInputCss = (compInfo: DropDownMenuMapperArgs) => {
  const alignText = compInfo.compProps.alignText;

  const borders = {
    '--arrowBorderWidth': calcArrowBorderWidth(alignText),
  };

  return getComboBoxInputNavigationCSSVars(
    {
      compLayout: compInfo.compLayout,
      compSingleLayout: compInfo.compSingleLayout,
      compProps: compInfo.compProps,
      styleProperties: compInfo.styleProperties,
      isMobileView: compInfo.isMobileView,
      siteFonts: compInfo.siteFonts,
    },
    alignText,
    borders,
  );
};

const getMenuBorderX = (
  skin: DropDownMenuDefinition['skin'],
  styleProperties: DropDownMenuMapperArgs['styleProperties'],
  formatCssValue: CompInfo['formatCssValue'],
  isSupportSpOn: boolean,
): string => {
  const { menuTotalBordersX, brw, spx } = styleProperties;
  const doubleBorder = brw ? parseInt(brw, 10) * 2 : 0;

  if (skin === 'SolidColorMenuButtonSkin') {
    return isSupportSpOn && formatCssValue
      ? formatCssValue(String(doubleBorder), spx)
      : `${doubleBorder}px`;
  }

  if (menuTotalBordersX) {
    return isSupportSpOn && formatCssValue
      ? formatCssValue(String(menuTotalBordersX), spx)
      : `${parseInt(menuTotalBordersX, 10)}px`;
  }

  return '0px';
};

const getMenuBorderY = (
  skin: DropDownMenuDefinition['skin'],
  styleProperties: DropDownMenuMapperArgs['styleProperties'],
  fallbackToZero: boolean,
  formatCssValue: CompInfo['formatCssValue'],
  isSupportSpOn: boolean,
): string => {
  const { menuTotalBordersY, brw, spx } = styleProperties;
  const doubleBorder = brw ? parseInt(brw, 10) * 2 : 0;

  if (
    skin === 'LinesMenuButtonSkin' ||
    skin === 'LinesMenuButtonBorderRadiusFixSkin' ||
    skin === 'SolidColorMenuButtonSkin'
  ) {
    return isSupportSpOn && formatCssValue
      ? formatCssValue(String(doubleBorder), spx)
      : `${doubleBorder}px`;
  }

  if (fallbackToZero) {
    return '0px';
  }

  if (menuTotalBordersY) {
    return isSupportSpOn && formatCssValue
      ? formatCssValue(String(menuTotalBordersY), spx)
      : `${parseInt(menuTotalBordersY, 10)}px`;
  }

  return '0px';
};

const getDefaultCss = (
  compInfo: DropDownMenuMapperArgs,
  isSupportSpOn: boolean,
): DropDownMenuCSSVars => {
  const { skin, styleProperties, siteColors, formatCssValue } = compInfo;
  const bghBrightnessCSSVars = getBrightnessCSSVars<
    Pick<DropDownMenuBrightnessCSSVars, '--bgh-mutated-brightness'>
  >({
    paramName: 'elmOver',
    mutatedParamName: 'bgh',
    alpha: 0.7,
    styleProperties,
    siteColors,
  });
  const bgsBrightnessCSSVars = getBrightnessCSSVars<
    Pick<DropDownMenuBrightnessCSSVars, '--bgs-mutated-brightness'>
  >({
    paramName: 'elmSelected',
    mutatedParamName: 'bgs',
    alpha: 0.7,
    styleProperties,
    siteColors,
  });
  const brightnessParams =
    skin === 'VerticalRibbonsMenuButtonSkin'
      ? { ...bghBrightnessCSSVars, ...bgsBrightnessCSSVars }
      : {};

  return {
    '--menuTotalBordersY': getMenuBorderY(
      skin,
      styleProperties,
      false,
      formatCssValue,
      isSupportSpOn,
    ),
    '--menuTotalBordersX': getMenuBorderX(
      skin,
      styleProperties,
      formatCssValue,
      isSupportSpOn,
    ),
    ...brightnessParams,
  };
};

export const css = withCompInfo<DropDownMenuCSSVars, DropDownMenuDefinition>()(
  [
    'compLayout',
    'compSingleLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'uiType',
    'skin',
    'siteColors',
    'siteFonts',
    'formatCssValue',
    'experiments',
  ],
  (compInfo, carmiCss) => {
    const { experiments } = compInfo;

    const isSupportSpOn = isSupportSpExperimentOn(experiments);

    const isMapperMigrationSpecOn =
      experiments[carmiCssMapperMigrationSpec] === 'new' ||
      experiments[carmiCssMapperMigrationSpec] === true;

    if (!isMapperMigrationSpecOn && carmiCss) {
      return carmiCss;
    }

    const isComboBoxInput =
      compInfo.uiType === 'ComboBoxInputNavigation' || compInfo.isMobileView;

    return isComboBoxInput
      ? getComboBoxInputCss(compInfo)
      : getDefaultCss(compInfo, isSupportSpOn);
  },
);

export default createComponentMapperModel({ props, css, stateRefs });
