import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { labelText } from '../constants';

export const props = withCompInfo()(
  ['compStylableClass'],
  ({ compStylableClass }) => {
    return {
      label: labelText.mapper,
      stylableClassName: compStylableClass,
    };
  },
);

export default createComponentMapperModel({
  props,
});
