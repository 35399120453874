import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  LineShareButtonDefinition,
  LineShareButtonMapperProps,
  LineShareButtonCSSVars,
  ButtonTypesFromSchema,
  ButtonTypesForLineSDK,
  LanguagesForLineSDK,
  LineShareStateRefs,
} from '../LineShareButton.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  shouldShowConsentPolicyWrapper,
  isMobile,
  translateConsentWrapperKeys,
} from '../../ConsentPolicyWrapper/viewer/utils';

const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_LineShareButton',
  componentNameDefault: 'LINE Share',
};

const buttonTypeSchemaToSDK: {
  [key in ButtonTypesFromSchema]: ButtonTypesForLineSDK;
} = {
  button: 'share-a',
  classicWithButton: 'share-e',
  classicBig: 'share-d',
  classicMedium: 'share-c',
  classicSmall: 'share-b',
  bubbleBig: 'share-h',
  bubbleMedium: 'share-g',
  bubbleSmall: 'share-f',
} as const;

const LangugeSchemaToSDK: Record<string, LanguagesForLineSDK> = {
  en: 'en',
  ko: 'ko',
  ja: 'ja',
  zh: 'zh_Hant',
  th: 'th',
  // id: 'id', TODO: What is ID? Why isn't it part of Language?
};

export const props = withCompInfo<
  LineShareButtonMapperProps,
  LineShareButtonDefinition
>()(
  ['compProps', 'language', 'uiType', 'deviceType', 'translate'],
  ({ compProps, language, uiType, deviceType, translate }) => {
    return {
      buttonType: buttonTypeSchemaToSDK[compProps.buttonType] || 'share-a',
      language: LangugeSchemaToSDK[language] || 'en',
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          consetPolicyKeys.componentNameKey,
          consetPolicyKeys.componentNameDefault,
          translate,
        ),
      },
    };
  },
);

type WidgetSizeValue = { width: number; height: number };
type SizeFromLanguageFn = (lang: string) => WidgetSizeValue;
type ButtonTypeSizes = Record<ButtonTypesFromSchema, SizeFromLanguageFn>;

const buttonSizeByLanguage: { [key: string]: WidgetSizeValue } = {
  ko: { width: 63, height: 20 },
  ja: { width: 80, height: 20 },
  zh: { width: 46, height: 20 },
  th: { width: 47, height: 20 },
};

const sizeMapping: ButtonTypeSizes = {
  classicWithButton: () => ({ width: 35, height: 60 }),
  classicSmall: () => ({ width: 20, height: 20 }),
  classicMedium: () => ({ width: 30, height: 30 }),
  classicBig: () => ({ width: 40, height: 40 }),
  bubbleSmall: () => ({ width: 20, height: 19 }),
  bubbleMedium: () => ({ width: 30, height: 29 }),
  bubbleBig: () => ({ width: 40, height: 40 }),
  button: lang => buttonSizeByLanguage[lang] || { width: 53, height: 20 },
};

export const css = withCompInfo<
  LineShareButtonCSSVars,
  LineShareButtonDefinition
>()(['compProps', 'language'], ({ compProps: { buttonType }, language }) => {
  const { height, width } = sizeMapping[buttonType](language);
  return { height: `${height}px`, width: `${width}px` };
});

export const stateRefs = withStateRefsValues<keyof LineShareStateRefs>([
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
