import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
// TODO - fix this import when we can import thunderbolt-commmons properly
import { getImpliedLinks } from '@wix/thunderbolt-commons/dist/links';
import {
  WRichTextDefinition,
  WRichTextProps,
} from '@wix/thunderbolt-components';
// TODO - import this directly from '@wix/thunderbolt-components'
import { WRichTextCSSVars as OriginalWRichTextCSSVars } from '@wix/thunderbolt-components/dist/components/WRichText/WRichText';
import {
  customCssClasses,
  sanitizeHTML,
} from '@wix/editor-elements-common-utils';
// @ts-expect-error Untyped module
import { transformHTMLString } from '@wix/santa-core-utils/dist/cjs/coreUtils/core/htmlTransformer';

import { flow } from '../corvid/utils';
import semanticClassNames from '../WRichText.semanticClassNames';

export const injectTextSemanticClassName = (html: string) => {
  const textSemanticClassNames = customCssClasses(semanticClassNames.text);
  try {
    return transformHTMLString(html, {
      start: (tag: string, attributes: Array<any>, ...args: Array<any>) => {
        const classAttr = attributes.find(attr => attr.name === 'class');
        if (classAttr) {
          classAttr.value += ` ${textSemanticClassNames}`;
          classAttr.escaped += ` ${textSemanticClassNames}`;
        } else {
          attributes.push({
            name: 'class',
            value: textSemanticClassNames,
            escaped: textSemanticClassNames,
          });
        }
        return { tag, attributes, ...args };
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(
      `Failed to inject semanticClass of ${semanticClassNames.text}`,
      error,
    );
    return html;
  }
};

export const props = withCompInfo<
  WRichTextProps,
  WRichTextDefinition,
  WRichTextProps
>()(
  ['isMobileView', 'isQaMode', 'fullNameCompType'],
  ({ isMobileView, isQaMode, fullNameCompType }, carmiData) => {
    const htmlWithLinks = flow(
      html => getImpliedLinks(html, isMobileView),
      sanitizeHTML,
    )(carmiData.html);
    const htmlWithSemanticClasses = injectTextSemanticClassName(htmlWithLinks);
    return {
      ...carmiData,
      html: htmlWithSemanticClasses,
      isQaMode,
      fullNameCompType,
    };
  },
);

export type WRichTextCSSVars = Omit<OriginalWRichTextCSSVars, 'min-width'> & {
  '--min-width'?: string;
  '--text-direction'?: string;
};

export const css = withCompInfo<
  WRichTextCSSVars,
  WRichTextDefinition,
  WRichTextCSSVars
>()([], (_deps, carmiCss) => {
  const { 'min-width': minWidth, direction, ...cssVars } = carmiCss;

  return {
    ...cssVars,
    ...(minWidth && { '--min-width': minWidth }),
    ...(direction && { '--text-direction': direction }),
  };
});

export default createComponentMapperModel({ props });
