import { useMemo } from 'react';
import { getClasses_column as getClasses } from '../../StylableHorizontalMenu/viewer/components/MenuItem/styles/getClasses_column';
import { createInjectCssVars_column } from '../../StylableHorizontalMenu/viewer/components/MenuItem/styles/injectCssVars_column';

export const useSHMStyles = (shmClassName: string) =>
  useMemo(() => {
    const classes = getClasses({ depth: 0, containsChildren: true });
    const cssVars = createInjectCssVars_column(shmClassName)(0);
    return {
      cssVars: cssVars.megaMenuComp,
      className: classes.megaMenuComp,
    };
  }, [shmClassName]);
