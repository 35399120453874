import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import type { ITabsCarmiData, ITabsMapperProps } from '../Tabs.types';
import { props as viewerPropsMapper } from './Tabs.mapper';

const props = composeMapper<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  // the component mapper
  viewerPropsMapper,
  // additional dependencies to inject to the preview mapper function
  { add: ['compPreviewState'] },
  // preview mapper function
  (resolver, deps, carmiData) => {
    // calculate the props of the mapper
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
