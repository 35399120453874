import { ThumbnailMapping } from '@wix/lean-components-infra/dist/buildtime/viewerComponents/panels/types';

export enum IndicatorStyleEnum {
  Icon = 'icon',
  Pagination = 'pagination',
}

export const IndicatorStyleThumbnailMappingsEnum = {
  icon: {
    symbolName: 'sliderThumbShapeCircle',
    labelKey: '_label_thumbnail_icon',
    tooltipKey: '_tooltip_thumbnail_icon',
  },
  pagination: {
    symbolName: 'sliderThumbShapeCircle',
    labelKey: '_label_thumbnail_pagination',
    tooltipKey: '_tooltip_thumbnail_pagination',
  },
} as ThumbnailMapping;

export const DEFAULT_ICON = {
  svgId: `<svg data-bbox="20 20 160 160" viewBox="0 0 200 200" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape">
    <g>
        <path d="M180 100c0 44.183-35.817 80-80 80s-80-35.817-80-80 35.817-80 80-80 80 35.817 80 80z"/>
    </g>
</svg>`,
};

export const slideshowIndicatorBaseId = 'wixui_SlideshowIndicator';

export const selectors = {
  root: '.root',
  icon: '.root::icon',
};

export const stateSelectors = {
  designItem: {
    icons: selectors.icon,
    background: selectors.root,
  },
  icon: {
    regular: selectors.icon,
    hover: `${selectors.icon}:hover`,
    selected: `${selectors.icon}:isSelected`,
  },
};

export const TranslationKeys = {
  icons: 'SlideshowIndicator_design_icons_dropdown_label',
  background: 'SlideshowIndicator_design_background_dropdown_label',
  regularState: 'StylablePanel_Design_State_Dropdown_Regular',
  hoverState: 'StylablePanel_Design_State_Dropdown_Hover',
  selectedState: 'SlideshowIndicator_design_state_selected',
  iconState: 'SlideshowIndicator_design_state_title',
  backgroundFill: 'StylablePanel_Design_Fills_Tab_Title',
  borders: 'StylablePanel_Design_Borders_Tab_Title',
  corners: 'StylablePanel_Design_Corners_Tab_Title',
  shadows: 'StylablePanel_Design_Shadows_Tab_Title',
  layout: 'StylablePanel_Design_Layout_Tab_Title',
  text: 'StylablePanel_Design_Text_Tab_Title',
  size: 'SlideshowIndicator_design_size_tab_title',
};

export const designPanelIds = {
  fill: `${slideshowIndicatorBaseId}_fill`,
  borders: `${slideshowIndicatorBaseId}_borders`,
  corners: `${slideshowIndicatorBaseId}_corners`,
  shadow: `${slideshowIndicatorBaseId}_shadow`,
  text: `${slideshowIndicatorBaseId}_text`,
  layout: `${slideshowIndicatorBaseId}_layout`,
  icon: `${slideshowIndicatorBaseId}_icon`,
  size: `${slideshowIndicatorBaseId}_size`,
};
