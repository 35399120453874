import {
  Direction,
  FlexAlignment,
  LogicalAlignment,
  PhysicalAlignment,
  WithInherit,
} from './types';

const physicalToLogicalAlignmentMap: Record<
  PhysicalAlignment,
  LogicalAlignment
> = {
  left: 'start',
  center: 'center',
  right: 'end',
};

const logicalToFlexAlignmentMap: Record<LogicalAlignment, FlexAlignment> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

type IValue = Direction | LogicalAlignment;

export const castToInheritIfNeeded = <V extends IValue>(
  value: V,
  parentValue: V,
): WithInherit<V> => (value === parentValue ? 'inherit' : value);

export const castFromInheritIfNeeded = <V extends IValue>(
  value: WithInherit<V> | undefined,
  parentValue: V,
): V => (!value || value === 'inherit' ? parentValue : value);

export const convertPhysicalAlignmentToLogical = (
  physicalAlignment: PhysicalAlignment,
): LogicalAlignment => physicalToLogicalAlignmentMap[physicalAlignment];

export const convertLogicalAlignmentToFlex = (
  logicalAlignment: LogicalAlignment,
): FlexAlignment => logicalToFlexAlignmentMap[logicalAlignment];

export const getOppositeAlign = (align: LogicalAlignment) =>
  align === 'start' ? 'end' : 'start';
