import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  ScrollableContainerTypes,
  withScrollAPIForResponsiveContainer,
} from '@wix/editor-elements-preview-utils';

const innerScrollId = 'inlineContentParent';

const getOverflowWrapper = (props: ScrollableContainerTypes) => {
  const comp = document.getElementById(props.id);
  const overflowWrapper = comp?.querySelector(`[id^=${innerScrollId}]`);

  return overflowWrapper;
};

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withScrollAPIForResponsiveContainer(ViewerComponent, {
      alwaysHasOverflow: true,
      overflowWrapperGetter: getOverflowWrapper,
    }),
  );
