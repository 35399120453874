import {
  FileType,
  fileTypeToBaseFileType,
} from '@wix/editor-elements-corvid-utils';
import { FileMetaData } from '../FileUploader.types';
import { getFileExtension, getMaxFileSize } from '../corvid/utils';

export const removePoppersWrapperFromDOM = (el: HTMLElement) => el?.remove();

export const translationKeyToPlaceholderData: {
  [key: string]: {
    getValueToReplace: (file: FileMetaData, fileType?: FileType) => string;
    placeholder: string;
  };
} = {
  'upload-button.wpm_error.unsupported_file_extension': {
    getValueToReplace: file => getFileExtension(file.name),
    placeholder: '{extension}',
  },
  'upload-button.wpm_error.mime_type_mismatch': {
    getValueToReplace: file => getFileExtension(file.name),
    placeholder: '{extension}',
  },
  'upload-button.wpm_error.file_size_exceeded_limit': {
    getValueToReplace: (file, fileType) =>
      getMaxFileSize(fileTypeToBaseFileType(fileType as FileType, file.name)),
    placeholder: '{sizeLimit}',
  },
  '': {
    getValueToReplace: () => '',
    placeholder: '',
  },
};

export const invalidKeyToTranslationKey: {
  [key: string]:
    | 'wpm_error.file_size_exceeded_limit'
    | 'wpm_error.unsupported_file_extension';
} = {
  fileSizeExceedsLimit: 'wpm_error.file_size_exceeded_limit',
  fileTypeNotAllowed: 'wpm_error.unsupported_file_extension',
};

export const parseErrorMessage = (
  translation: string,
  placeholder: string,
  value: string,
) => translation.replace(placeholder, value);

export const hasPlaceholder = (translation: string) => {
  const placeholders = ['{extension}', '{sizeLimit}'];

  return !!placeholders.find(placeholder => translation.includes(placeholder));
};

export const getNumberOfFilesLinkId = (parentCompId: string) =>
  `number-of-files-link_${parentCompId}`;

export const getFileNameId = ({
  parentCompId,
  fileIndex,
}: {
  parentCompId: string;
  fileIndex: number;
}) => `file-name_${fileIndex}_${parentCompId}`;

export const getFileNameErrorTooltipId = (fileNameId: string) =>
  `${fileNameId}_tooltip`;
