import * as React from 'react';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../SlideshowIndicator.semanticClassNames';

import type { ISlideshowIndicatorProps } from '../SlideshowIndicator.props';
import { DEFAULT_ICON, IndicatorStyleEnum } from '../constants';
import { useSlideshowContext } from '../../Slideshow/SlideshowContext';
import { classes, st } from './style/SlideshowIndicator.component.st.css';
import SlideshowIndicatorIcons from './SlideshowIndicatorIcon';
import SlideshowIndicatorPagination from './SlideshowIndicatorPagination';

const SlideshowIndicator: React.FC<
  ISlideshowIndicatorProps & { stylableClassName: string }
> = props => {
  const {
    id,
    customClassNames = [],
    indicatorStyle = IndicatorStyleEnum.Icon,
    icon = DEFAULT_ICON,
    className,
    stylableClassName,
  } = props;

  const { currentIndex, slidesLength, onIndicatorClick } =
    useSlideshowContext();

  return (
    <div id={id} className={className}>
      <div
        className={st(
          classes.root,
          {
            indicatorStyle,
          },
          stylableClassName,
          customCssClasses(semanticClassNames.root, ...customClassNames),
        )}
      >
        {indicatorStyle === IndicatorStyleEnum.Icon ? (
          <SlideshowIndicatorIcons
            slidesToDisplay={slidesLength}
            currentSlide={currentIndex}
            icon={icon}
            updateIndex={index => onIndicatorClick(index)}
          />
        ) : (
          <SlideshowIndicatorPagination
            slidesToDisplay={slidesLength}
            currentSlide={currentIndex}
          />
        )}
      </div>
    </div>
  );
};

export default SlideshowIndicator;
