import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

import {
  CollapsibleTextDefinition,
  ICollapsibleTextMapperProps,
} from '../CollapsibleText.types';
import { props as viewerPropsMapper } from './CollapsibleText.mapper';

const props = composeMapper<
  PreviewWrapperProps<ICollapsibleTextMapperProps>,
  CollapsibleTextDefinition
>()(
  viewerPropsMapper,
  {
    remove: [
      'compId',
      'fullNameCompType',
      'language',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
