import React, { useContext, useRef } from 'react';
import classnames from 'clsx';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import { customCssClasses } from '@wix/editor-elements-common-utils';
import LinkModal from '../../../../../../RichTextBoxLinkModal/viewer/RichTextBoxLinkModal';
import styles from '../../button.scss';
import LinkIcon from '../LinkIcon';
import { hasEntity } from '../../../../utils/draftUtils';
import StylesContext from '../../../../StylesContext';
import semanticClassNames from '../../../../../RichTextBox.semanticClassNames';

export default (props: ToolbarChildrenProps) => {
  const { setEditorState, getEditorState, onOverrideContent } = props;
  const stylesContext = useContext(StylesContext);
  const linkButtonRef = useRef<HTMLDivElement>(null);

  const onClose = () => {
    // @ts-expect-error
    onOverrideContent(undefined);
  };

  const openLinkModal = () => {
    const content = () => (
      <LinkModal
        getEditorState={getEditorState}
        setEditorState={setEditorState}
        onClose={onClose}
        linkButtonRef={linkButtonRef}
      />
    );
    onOverrideContent(content);
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected = hasEntity(getEditorState(), 'LINK');
  return (
    <div className={styles.buttonContainer} ref={linkButtonRef}>
      <button
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onMouseDown={preventBubblingUp}
        onClick={openLinkModal}
        type="button"
      >
        <div
          className={classnames(
            styles.buttonContent,
            customCssClasses(semanticClassNames.icons),
            selected && styles.buttonContentSelected,
          )}
        >
          <LinkIcon />
        </div>
      </button>
    </div>
  );
};
